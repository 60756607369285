import axios from 'api/helpers/axios';

import { IMedia, IMediaData } from 'types/types';

interface IResponse<T> {
  data: T;
  status?: number;
  statusText?: string;
  message?: string;
  name?: string;
}

export default {
  async postMedia(formData: FormData): Promise<IResponse<Array<IMedia>>> {
    const response = await axios.post(`/media/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return response;
  },

  async sortMedia(id: number, sort: number): Promise<IResponse<unknown>> {
    const response = await axios.post(`/media/${id}/sort`, {
      sort
    });

    return response;
  },

  async getMedia(
    resource:
      | 'categories'
      | 'products'
      | 'variations'
      | 'tags'
      | 'info_sections'
      | 'info_pages',
    resourceId: number // id товара, тега или категории
  ): Promise<IResponse<IMediaData>> {
    const response = await axios.get(`/${resource}/${resourceId}/media`);

    return response;
  },

  async assignMedia(
    resource:
      | 'categories'
      | 'products'
      | 'variations'
      | 'tags'
      | 'info_sections'
      | 'info_pages',
    resourceId: number, // id товара, тега или категории
    slot: 'main' | string,
    ids: Array<number>
  ): Promise<IResponse<unknown>> {
    const response = await axios.post(
      `/${resource}/${resourceId}/media/assign/${slot}`,
      ids
    );

    return response;
  },

  async deleteMedia(id: number): Promise<IResponse<unknown>> {
    const response = await axios.post(`/media/${id}/delete`);

    return response;
  },

  async replaceMedia(
    resource:
      | 'categories'
      | 'products'
      | 'variations'
      | 'tags'
      | 'info_sections'
      | 'info_pages',
    resourceId: number, // id товара, тега или категории
    slot: 'main' | string,
    newId: number
  ): Promise<IResponse<unknown>> {
    const response = await axios.post(
      `/${resource}/${resourceId}/media/replace/${slot}`,
      {
        new_id: newId
      }
    );

    return response;
  }
};

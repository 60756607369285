import React, { FC, useState, ReactNode } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import StepContent from 'components/stepContent';

interface IProps {
  modalOpen: boolean;
  onModalClose(): void;
}

const steps = [
  'Выберите тип характеристики',
  'Выберите значение',
  'Отредактируйте атрибут'
];

const AttributesModal: FC<IProps> = ({ modalOpen, onModalClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        onModalClose();
        handleReset();
      }}
      sx={{ overflowY: 'scroll', borderRadius: '8px' }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 1000,
          bgcolor: 'background.paper',
          boxShadow: 24,
          color: 'text.primary',
          borderRadius: '8px',
          p: 2
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <Stepper activeStep={activeStep} sx={{ width: '95%' }}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: ReactNode;
                } = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <IconButton
              onClick={() => {
                onModalClose();
                handleReset();
              }}
            >
              <Close
                sx={(theme) => ({
                  '&:hover': {
                    color: theme.palette.error.main
                  }
                })}
              />
            </IconButton>
          </Box>

          {/* {activeStep === steps.length ? (
            <>
              <Typography sx={{ mt: 2, mb: 1 }}>
                Изменения успешно сохранены
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Заново</Button>
              </Box>
            </>
          ) : (
            <>
              <Typography sx={{ mt: 3, mb: 1, px: 1 }}>
                Шаг {activeStep + 1}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Назад
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Сохранить' : 'Далее'}
                </Button>
              </Box>
            </>
          )} */}
          <StepContent
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            handleReset={handleReset}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default AttributesModal;

import axios from 'api/helpers/axios';
import { IAddProduct } from 'types/types';

interface IResponse<T> {
  data: T;
  status?: number;
  statusText?: string;
  message?: string;
  name?: string;
}

interface IFilesResponse<T> {
  data: Array<T>;
  status?: number;
  statusText?: string;
  message?: string;
  name?: string;
}

interface IFile {
  name: string;
  url: string;
  size: number;
  created_at: string;
}

export default {
  async generatePinterestFeed(data: IAddProduct[]): Promise<IResponse<string>> {
    const response = await axios.post('/generate-pinterest', {
      data
    });

    return response;
  },

  async getFeedsList(): Promise<IFilesResponse<IFile>> {
    const response = await axios.get('/get-feeds-list');

    return response;
  },

  async downloadFile(name: string): Promise<IResponse<Blob>> {
    const response = await axios.get('/download-feed', {
      params: {
        name
      },
      responseType: 'blob'
    });

    return response;
  },

  async deleteFile(name: string): Promise<IResponse<Blob>> {
    const response = await axios.get('/delete-feed', {
      params: {
        name
      },
      responseType: 'blob'
    });

    return response;
  }
};

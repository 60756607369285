// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { observable, action, makeObservable, runInAction } from 'mobx';

// API
import api from 'api';
import axios from 'axios';
import { IVariation } from 'types/types';

interface IError {
  isOk: boolean;
  msg: string;
}

class VariationsStore {
  @observable
  loading: boolean = false;

  @observable
  variation: IVariation = {
    characteristicValues: [],
    id: 0,
    sku: null,
    name: '',
    product_variation_container_id: 0
  };

  constructor() {
    makeObservable(this);
  }

  @action
  getVariation = async (id: number): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const dataFromAPI = await api.variations.getVariation(id);

      runInAction(() => {
        if (dataFromAPI.status === 200) {
          res.isOk = true;
          res.msg = 'Данные о вариации загружены';

          this.variation = dataFromAPI.data;
        } else {
          console.error(
            `Error\nCode: ${dataFromAPI.status}\nStatus: ${dataFromAPI.statusText}`
          );

          res.isOk = false;
          res.msg = dataFromAPI.name
            ? `Ошибка ${dataFromAPI.name}: ${dataFromAPI.message}`
            : `Ошибка ${dataFromAPI.status}: ${dataFromAPI.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      res.isOk = false;
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  addVariation = async (
    id: number,
    data: Partial<IVariation>
  ): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const dataFromAPI = await api.variations.addVariation(id, data);

      runInAction(() => {
        if (dataFromAPI.status === 201) {
          res.isOk = true;
          res.msg = 'Вариация добавлена';
        } else {
          console.error(
            `Error\nCode: ${dataFromAPI.status}\nStatus: ${dataFromAPI.statusText}`
          );

          res.isOk = false;
          res.msg = dataFromAPI.name
            ? `Ошибка ${dataFromAPI.name}: ${dataFromAPI.message}`
            : `Ошибка ${dataFromAPI.status}: ${dataFromAPI.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      res.isOk = false;
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  updateVariation = async (id: number, data: IVariation): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const dataFromAPI = await api.variations.updateVariation(id, data);

      runInAction(() => {
        if (dataFromAPI.status === 200) {
          res.isOk = true;
          res.msg = 'Вариация обновлена';
        } else {
          console.error(
            `Error\nCode: ${dataFromAPI.status}\nStatus: ${dataFromAPI.statusText}`
          );

          res.isOk = false;
          res.msg = dataFromAPI.name
            ? `Ошибка ${dataFromAPI.name}: ${dataFromAPI.message}`
            : `Ошибка ${dataFromAPI.status}: ${dataFromAPI.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      res.isOk = false;
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  applyPrice = async (base_id: number, price: number): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const dataFromAPI = await api.variations.applyPriceForVariation(
        base_id,
        price
      );

      runInAction(() => {
        if (
          dataFromAPI.status &&
          dataFromAPI.status >= 200 &&
          dataFromAPI.status < 300
        ) {
          res.isOk = true;
          res.msg = 'Цена применена ко всем вариациям';
        } else {
          console.error(
            `Error\nCode: ${dataFromAPI.status}\nStatus: ${dataFromAPI.statusText}`
          );

          res.isOk = false;
          res.msg = dataFromAPI.name
            ? `Ошибка ${dataFromAPI.name}: ${dataFromAPI.message}`
            : `Ошибка ${dataFromAPI.status}: ${dataFromAPI.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      res.isOk = false;
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  applyDiscountPrice = async (
    base_id: number,
    price: number
  ): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const dataFromAPI = await api.variations.applyDiscountPriceForVariation(
        base_id,
        price
      );

      runInAction(() => {
        if (
          dataFromAPI.status &&
          dataFromAPI.status >= 200 &&
          dataFromAPI.status < 300
        ) {
          res.isOk = true;
          res.msg = 'Перечёркнутая цена применена ко всем вариациям';
        } else {
          console.error(
            `Error\nCode: ${dataFromAPI.status}\nStatus: ${dataFromAPI.statusText}`
          );

          res.isOk = false;
          res.msg = dataFromAPI.name
            ? `Ошибка ${dataFromAPI.name}: ${dataFromAPI.message}`
            : `Ошибка ${dataFromAPI.status}: ${dataFromAPI.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      res.isOk = false;
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };
}

const variationsStore = new VariationsStore();
export default variationsStore;

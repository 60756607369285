/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { observable, action, makeObservable } from 'mobx';
import { IProps } from 'types/types';
import gitInfo from '../autogenerated/gitInfo.json';
import buildInfo from '../autogenerated/buildInfo.json';

interface IGitInfo {
  gitBranch: string | undefined;
  gitCommitHash: string | undefined;
}

interface IBuildInfo {
  date: string | undefined;
}

class GlobalState {
  @observable
  pathnameRu: string = '';

  @observable
  parameters: IProps | number = {};

  @observable
  gitInfo: IGitInfo = gitInfo;

  @observable
  buildInfo: IBuildInfo = buildInfo;

  @observable
  refreshFunction: ((params: any) => any) | undefined = undefined;

  constructor() {
    makeObservable(this);
  }

  @action
  setRefreshFunction = (newFunc: (e: any) => any, params?: IProps | number) => {
    if (params) {
      this.refreshFunction = newFunc;
      this.parameters = params;
    } else {
      this.refreshFunction = undefined;
    }
  };

  @action
  setNewPathname = (pathRu: string) => {
    this.pathnameRu = pathRu;
  };
}

const globalState = new GlobalState();
export default globalState;

import React, {
  FC,
  useCallback,
  useState,
  ChangeEvent,
  useEffect
} from 'react';
import {
  Box,
  Switch,
  Paper,
  Typography,
  IconButton,
  Modal,
  Stack,
  TextField,
  Button,
  List,
  ListItem,
  Divider,
  ListItemText,
  Autocomplete,
  Tabs,
  Tab,
  Checkbox
} from '@mui/material';
import { AddCircleOutline, Close, Delete } from '@mui/icons-material';

import { observer } from 'mobx-react-lite';

import {
  ICharacteristicTypeShort,
  ICharacteristicValue,
  IProductVariation
} from 'types/types';

import variationsStore from 'store/variationsStore';
import characteristicStore from 'store/characteristicsStore';

import { v4 } from 'uuid';
import Editor from 'components/editor/editor';
import VariationMedia from 'components/variationMedia';

interface IProps {
  isOpen: boolean;
  setIsOpen(val: boolean): void;
  variations: Array<IProductVariation>;
  variationInd: number;
  notifyUser(
    message: string,
    status: 'error' | 'success' | 'info' | 'warning'
  ): void;
  onSave(val: string): void;
}

type Characteristic = {
  id: number | undefined;
  title: string;
  value: string;
  characteristic_type_id: number | undefined;
  key: string;
};

interface IValidateResult {
  valid: boolean;
  msg: string;
}

const EditVariationModal: FC<IProps> = ({
  isOpen,
  setIsOpen,
  variations,
  variationInd,
  notifyUser,
  onSave
}) => {
  const { characteristicType, characteristicTypes, characteristicsLoading } =
    characteristicStore;
  const { variation, loading } = variationsStore;
  const [name, setName] = useState<string>('');
  const [newProduct, setNewProduct] = useState<boolean>(false);
  const [bestseller, setBestseller] = useState<boolean>(false);
  const [productOfTheDay, setProductOfTheDay] = useState<boolean>(false);
  const [price, setPrice] = useState<number>(0);
  const [discountPrice, setDiscountPrice] = useState<number>(0);
  const [sku, setSku] = useState<string>('');
  const [markup, setMarkup] = useState<string>('');
  const [characteristics, setCharacteristics] = useState<Array<Characteristic>>(
    []
  );

  const [tabValue, setTabValue] = useState<number>(0);

  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
    },
    [setTabValue]
  );

  const [addTypeValue, setAddTypeValue] =
    useState<ICharacteristicTypeShort | null>(null);
  const [switchChecked, setSwitchChecked] = useState<boolean>(false);
  const [addTypeInputValue, setAddTypeInputValue] = useState<string>('');
  const [addValue, setAddValue] = useState<ICharacteristicValue | null>(null);
  const [addValueInput, setAddValueInput] = useState<string>('');
  const [seoTitle, setSeoTitle] = useState<string | null | undefined>('');
  const [seoDescription, setSeoDescription] = useState<
    string | null | undefined
  >('');
  const [seoSlug, setSeoSlug] = useState<string | null | undefined>('');
  const [typeError, setTypeError] = useState<IValidateResult>({
    valid: true,
    msg: ''
  });
  const [valueError, setValueError] = useState<IValidateResult>({
    valid: true,
    msg: ''
  });

  const [valuesOptions, setValuesOptions] = useState<
    Array<ICharacteristicValue>
  >([]);

  const reload = async () => {
    await variationsStore.getVariation(variations[variationInd]?.id || 0);
    await characteristicStore.getCharacteristicTypes();
  };

  useEffect(() => {
    setName(variations[variationInd]?.name || '');
    setNewProduct(variations[variationInd]?.is_new || false);
    setProductOfTheDay(
      variations[variationInd]?.is_product_of_the_day || false
    );
    setBestseller(variations[variationInd]?.is_bestseller || false);
    setPrice(variations[variationInd]?.price || 0);
    setDiscountPrice(variations[variationInd]?.discount_price || 0);
    setSku(variations[variationInd]?.sku || '');
    setMarkup(variations[variationInd]?.text_content || '');
    setSeoTitle(variations[variationInd]?.seo_title || '');
    setSeoDescription(variations[variationInd]?.seo_description || '');
    setSeoSlug(variations[variationInd]?.slug || '');

    setSwitchChecked(false);

    if (!variations[variationInd]?.id && isOpen) {
      notifyUser(
        'Для редактирования характеристик необходимо сохранить созданную вариацию',
        'info'
      );
    } else if (variations[variationInd]?.id && isOpen) {
      reload();
    }
  }, [variations, variationInd, isOpen]);

  useEffect(() => {
    const newCharacteristicTypes = characteristicStore.getCharacteristicTypes();

    newCharacteristicTypes.then((val) => {
      if (val.isOk) {
        const pairs = variation.characteristicValues.map(
          ({ characteristic_type_id, value, id }) => {
            const charateristicIndex = val.data.findIndex(
              (el) => el.id === characteristic_type_id
            );
            return {
              id,
              title:
                charateristicIndex !== -1
                  ? characteristicTypes[charateristicIndex].name
                  : 'Неизвестный тип характеристики',
              characteristic_type_id,
              value,
              key: `${charateristicIndex}${characteristic_type_id}`
            };
          }
        );

        setCharacteristics(pairs);
      }
    });
  }, [variation]);

  useEffect(() => {
    if (addTypeValue?.id) {
      characteristicStore.getCharacteristicType(addTypeValue.id);
    }
  }, [addTypeValue]);

  const validateValues = useCallback((): boolean => {
    console.log(addValueInput);
    switch (true) {
      case addTypeValue === null || addTypeValue?.name === '': {
        setTypeError({
          valid: false,
          msg: 'Поле не должно быть пустым'
        });
        return false;
      }
      default: {
        setTypeError({
          valid: true,
          msg: 'Поле соответствует требованиям'
        });
        break;
      }
    }

    switch (true) {
      case addValueInput === null || addValueInput === '': {
        setValueError({
          valid: false,
          msg: 'Поле не должно быть пустым'
        });
        return false;
      }
      default: {
        setValueError({
          valid: true,
          msg: 'Поле соответствует требованиям'
        });
        break;
      }
    }

    return true;
  }, [addTypeValue, setValueError, addValueInput, setTypeError]);

  const handleModalClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleSave = () => {
    const id = variations[variationInd]?.id;

    if (id) {
      const response = variationsStore.updateVariation(id, {
        ...variation,
        name,
        price,
        discount_price: discountPrice,
        is_new: newProduct,
        is_product_of_the_day: productOfTheDay,
        is_bestseller: bestseller,
        sku,
        text_content: markup,
        seo_title: seoTitle,
        seo_description: seoDescription,
        slug: seoSlug,
        characteristicValues: characteristics.map((el) => {
          return {
            characteristic_type_id: el.characteristic_type_id || 0,
            id: el.id,
            value: el.value
          };
        })
      });

      response.then((val) => {
        if (val.isOk) {
          notifyUser('Вариация обновлена', 'success');
        } else {
          notifyUser('Произошёл сбой во время обновления вариации', 'error');
          reload();
        }
      });
    }

    onSave(name);
  };

  const onNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setName(event.currentTarget.value);
    },
    []
  );

  const onPriceChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPrice(parseFloat(event.currentTarget.value || '0'));
    },
    []
  );

  const onDiscountPriceChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setDiscountPrice(parseFloat(event.currentTarget.value || '0'));
    },
    []
  );

  const onSkuChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSku(event.currentTarget.value);
    },
    []
  );

  const onEditorChange = useCallback((val: string) => {
    setMarkup(val);
  }, []);

  const onTitleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSeoTitle(event.currentTarget.value);
    },
    []
  );

  const onDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSeoDescription(event.currentTarget.value);
    },
    []
  );

  const onSlugChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSeoSlug(event.currentTarget.value);
    },
    []
  );

  const onCharacteristicAdd = useCallback(() => {
    if (validateValues()) {
      const existingTypeId = characteristicTypes.find(
        (el) => el.name === addTypeInputValue
      )?.id;

      if (!addTypeValue?.id && !existingTypeId) {
        const response = characteristicStore.addCharacteristicType({
          name: addTypeInputValue || '',
          values: []
        });

        response.then((val) => {
          if (val.isOk) {
            notifyUser(
              `Новый тип характеристики "${addTypeInputValue}" создан`,
              'info'
            );

            const newResp = characteristicStore.postCharacteristic({
              characteristic_type_id: val.data?.id,
              value: addValueInput || ''
            });

            newResp.then((newVal) => {
              if (newVal.isOk) {
                notifyUser(
                  `Новое значение характеристики "${addValueInput}" создано`,
                  'info'
                );

                setCharacteristics((oldArr) => {
                  const newArr = [...oldArr];
                  newArr.push({
                    id: addValue?.id,
                    title: addTypeInputValue || '',
                    characteristic_type_id: val.data?.id,
                    value: addValue?.value || addValueInput || '',
                    key: v4()
                  });
                  return newArr;
                });

                setAddTypeValue(null);
                setAddTypeInputValue('');
                setAddValue(null);
                setAddValueInput('');

                setValueError({
                  valid: true,
                  msg: ''
                });
                setTypeError({
                  valid: true,
                  msg: ''
                });

                handleSave();
              } else {
                notifyUser('Ошибка при доавлении типа характеристики', 'error');
              }
            });
          } else {
            notifyUser('Ошибка при доавлении типа характеристики', 'error');
          }
        });
      } else if (addTypeValue?.id === undefined) {
        notifyUser(
          `Вы попытались создать существующий тип характеристики.\nДля использования типа "${addTypeInputValue}" выберите его из предложенного списка существующих характеристик`,
          'warning'
        );
      } else if (
        valuesOptions.find((el) => el.value === addValueInput) &&
        !addValue
      ) {
        // notifyUser(
        //   `Вы попытались создать существующее значение характеристики.\nДля использования значения "${addValueInput}" выберите его из предложенного списка существующих значений`,
        //   'warning'
        // );
        const foundValueOption = valuesOptions.find(
          (el) => el.value === addValueInput
        );

        if (foundValueOption) {
          setAddValue(foundValueOption);

          setCharacteristics((oldArr) => {
            const newArr = [...oldArr];
            newArr.push({
              id: foundValueOption?.id,
              title: addTypeValue?.name || '',
              characteristic_type_id: addTypeValue?.id,
              value: foundValueOption?.value || addValueInput || '',
              key: v4()
            });
            return newArr;
          });

          setAddTypeValue(null);
          setAddTypeInputValue('');
          setAddValue(null);
          setAddValueInput('');

          setValueError({
            valid: true,
            msg: ''
          });
          setTypeError({
            valid: true,
            msg: ''
          });
        }
      } else {
        if (!addValue) {
          notifyUser(
            `Новое значение характеристики "${addValueInput}" для типа "${addTypeInputValue}" будет создано после сохранения.`,
            'info'
          );
        }

        if (characteristics.find((el) => el.title === addTypeValue.name)) {
          notifyUser(
            `Тип характеристики "${addTypeValue.name}" уже использовался. Попробуйте ввести другой.`,
            'error'
          );

          return;
        }

        setCharacteristics((oldArr) => {
          const newArr = [...oldArr];
          newArr.push({
            id: addValue?.id,
            title: addTypeValue?.name || '',
            characteristic_type_id: addTypeValue?.id,
            value: addValue?.value || addValueInput || '',
            key: v4()
          });
          return newArr;
        });

        setAddTypeValue(null);
        setAddTypeInputValue('');
        setAddValue(null);
        setAddValueInput('');

        setValueError({
          valid: true,
          msg: ''
        });
        setTypeError({
          valid: true,
          msg: ''
        });
      }
    }
  }, [
    characteristicTypes,
    addTypeInputValue,
    addTypeValue,
    addValue,
    addValueInput,
    valuesOptions,
    characteristics
  ]);

  const onCharacteristicRemove = useCallback(
    (ind: number) => () => {
      setCharacteristics((oldArr) => {
        const newArr = [...oldArr];
        return newArr.filter((el, index) => index !== ind);
      });
    },
    [setCharacteristics]
  );

  const onApplyPriceClick = useCallback(() => {
    if (variations[variationInd]?.id || variations[variationInd]?.id === 0) {
      const resp = variationsStore.applyPrice(
        variations[variationInd]?.id || 0,
        price
      );

      resp.then((val) => {
        if (!val.isOk) {
          notifyUser(
            'Произошла ошибка при попытке применить цену ко всем характеристикам',
            'error'
          );
        } else {
          notifyUser(val.msg, 'success');
        }
      });
    }
  }, [price]);

  const onApplyDiscountPriceClick = useCallback(() => {
    if (variations[variationInd]?.id || variations[variationInd]?.id === 0) {
      const resp = variationsStore.applyDiscountPrice(
        variations[variationInd]?.id || 0,
        discountPrice
      );

      resp.then((val) => {
        if (!val.isOk) {
          notifyUser(
            'Произошла ошибка при попытке применить скидочную цену ко всем характеристикам',
            'error'
          );
        } else {
          notifyUser(val.msg, 'success');
        }
      });
    }
  }, [discountPrice]);

  useEffect(() => {
    if (addTypeValue?.id) {
      const resp = characteristicStore.getCharacteristicType(addTypeValue.id);

      resp.then((val) => {
        if (!val.isOk) {
          notifyUser(
            'Произошла ошибка при попытке загрузить характеристику',
            'error'
          );
        }
      });
    } else {
      setValuesOptions([]);
    }
  }, [addTypeValue]);

  useEffect(() => {
    setValuesOptions(characteristicType.values);
  }, [characteristicType]);

  const printCharacteristics = () => {
    return (
      <Paper sx={{ maxHeight: 500, overflow: 'auto', mt: 2 }}>
        <List dense>
          <ListItem
            secondaryAction={<Typography variant="h6">Значение</Typography>}
          >
            <Typography variant="h6">Тип</Typography>
          </ListItem>
          <Divider />
          {characteristics.map(({ title, value, key }, ind) => (
            // eslint-disable-next-line react/no-array-index-key
            <Stack key={`${key}${ind}`}>
              <ListItem
                sx={{
                  minHeight: '50px'
                }}
                secondaryAction={
                  <Stack
                    display="flex"
                    gap="8px"
                    flexDirection="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Typography>{value}</Typography>
                    <IconButton
                      size="small"
                      onClick={onCharacteristicRemove(ind)}
                    >
                      <Delete />
                    </IconButton>
                  </Stack>
                }
              >
                <ListItemText sx={{ textOverflow: 'ellipsis' }}>
                  <Typography sx={{ textOverflow: 'ellipsis' }}>
                    {title}
                  </Typography>
                </ListItemText>
              </ListItem>
              <Divider />
            </Stack>
          ))}
          <ListItem
            secondaryAction={
              <Autocomplete
                value={addValue}
                disabled={loading}
                onChange={(_event, newValue) => {
                  console.log(newValue);
                  if (typeof newValue === 'string') {
                    // setAddValue({
                    //   characteristic_type_id: -1,
                    //   value: newValue
                    // });
                  } else {
                    setAddValue(newValue);
                  }
                }}
                isOptionEqualToValue={() => true}
                inputValue={addValueInput}
                onInputChange={(_event, newInputValue) => {
                  console.log(newInputValue);
                  setAddValueInput(newInputValue);
                  setValueError({
                    valid: true,
                    msg: ''
                  });
                }}
                autoComplete
                loading={characteristicsLoading}
                freeSolo
                size="small"
                id="controllable-states"
                options={valuesOptions}
                getOptionLabel={(option) => {
                  if (typeof option === 'object') {
                    return option.value;
                  }
                  return JSON.stringify(option);
                }}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!valueError.valid}
                    helperText={!valueError.valid && valueError.msg}
                    label="Значение"
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option.id}>
                    {option.value}
                  </Box>
                )}
              />
            }
            sx={{
              minHeight: '72px'
            }}
          >
            <IconButton onClick={onCharacteristicAdd}>
              <AddCircleOutline />
            </IconButton>

            <Autocomplete
              value={addTypeValue}
              disabled={characteristicsLoading}
              onChange={(_event, newValue) => {
                if (typeof newValue === 'string') {
                  setAddTypeValue({
                    name: newValue
                  });
                } else {
                  setAddTypeValue(newValue);
                }

                setValuesOptions([]);
                setAddValue(null);
                setAddValueInput('');
              }}
              inputValue={addTypeInputValue}
              autoComplete
              freeSolo
              onInputChange={(_event, newInputValue) => {
                setAddTypeInputValue(newInputValue);
                setAddTypeValue({
                  name: newInputValue
                });
                setTypeError({
                  valid: true,
                  msg: ''
                });
                setValuesOptions([]);
                setAddValue(null);
                setAddValueInput('');
              }}
              size="small"
              id="controllable-states2"
              options={characteristicTypes}
              loading={characteristicsLoading}
              getOptionLabel={(option) => {
                if (typeof option === 'object') {
                  return option.name;
                }
                return JSON.stringify(option);
              }}
              sx={{ width: 300, pl: 2 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!typeError.valid}
                  helperText={!typeError.valid && typeError.msg}
                  label="Характеристика"
                />
              )}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.id}>
                  {option.name}
                </Box>
              )}
            />
          </ListItem>
        </List>
      </Paper>
    );
  };

  const customTab = useCallback(() => {
    switch (tabValue) {
      case 0: {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              pt: 1,
              alignItems: 'flex-start'
            }}
          >
            <TextField
              fullWidth
              multiline
              error={!name}
              value={name}
              onChange={onNameChange}
              label="Название"
              placeholder="Название"
              helperText={!name && 'Введите название вариации'}
              variant="outlined"
              sx={{ mt: '15px' }}
            />

            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-end"
              sx={{ width: '100%' }}
            >
              <TextField
                value={price}
                onChange={onPriceChange}
                label="Цена"
                placeholder="Цена"
                variant="outlined"
                sx={{ width: '78%', mt: '15px' }}
              />
              <Button
                onClick={onApplyPriceClick}
                sx={{ width: '20%' }}
                variant="text"
                size="small"
                color="success"
                type="button"
              >
                Применить ко всем вариациям
              </Button>
            </Stack>

            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-end"
              sx={{ width: '100%' }}
            >
              <TextField
                value={discountPrice}
                onChange={onDiscountPriceChange}
                label="Перечёркнутая цена"
                placeholder="Перечёркнутая цена"
                variant="outlined"
                sx={{ width: '78%', mt: '15px' }}
              />
              <Button
                onClick={onApplyDiscountPriceClick}
                sx={{ width: '20%' }}
                variant="text"
                size="small"
                color="success"
                type="button"
              >
                Применить ко всем вариациям
              </Button>
            </Stack>

            <TextField
              fullWidth
              value={sku}
              onChange={onSkuChange}
              label="Артикул"
              placeholder="Артикул"
              variant="outlined"
              sx={{ mt: '15px' }}
            />

            <Stack
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ pt: 1 }}
            >
              <Checkbox
                checked={newProduct}
                onChange={(e) => setNewProduct(e.target.checked)}
                aria-label="Новинка"
              />
              <Typography>Новинка</Typography>
            </Stack>

            <Stack display="flex" flexDirection="row" alignItems="center">
              <Checkbox
                checked={productOfTheDay}
                onChange={(e) => setProductOfTheDay(e.target.checked)}
                aria-label="Товар дня"
              />
              <Typography>Товар дня</Typography>
            </Stack>

            <Stack display="flex" flexDirection="row" alignItems="center">
              <Checkbox
                checked={bestseller}
                onChange={(e) => setBestseller(e.target.checked)}
                aria-label="Хит продаж"
              />
              <Typography>Хит продаж</Typography>
            </Stack>
          </Box>
        );
      }
      case 1: {
        return (
          <div style={{ paddingTop: '20px' }}>
            <Stack
              display="flex"
              flexDirection="row"
              gap="10px"
              alignItems="center"
            >
              <Switch
                checked={switchChecked}
                onChange={() => setSwitchChecked((val) => !val)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography>
                {!switchChecked ? 'Режим кода' : 'Визуальный режим'}
              </Typography>
            </Stack>

            {switchChecked ? (
              <Editor
                id="variation-editor-id"
                value={!markup ? undefined : markup}
                notifyUser={notifyUser}
                onEditorChange={onEditorChange}
              />
            ) : (
              <TextField
                fullWidth
                multiline
                minRows={10}
                value={markup || ''}
                onChange={(val) => {
                  setMarkup(val.target.value);
                }}
              />
            )}
          </div>
        );
      }
      case 2: {
        return variations[variationInd]?.id ? printCharacteristics() : <></>;
      }
      case 3: {
        return (
          <Box sx={{ pt: 1 }}>
            <TextField
              fullWidth
              multiline
              value={seoTitle}
              onChange={onTitleChange}
              label="Заголовок"
              placeholder="Заголовок"
              variant="outlined"
              sx={{ mt: '15px' }}
            />
            <TextField
              fullWidth
              multiline
              value={seoDescription}
              onChange={onDescriptionChange}
              label="Описание"
              placeholder="Описание"
              variant="outlined"
              sx={{ mt: '15px' }}
            />
            <TextField
              fullWidth
              multiline
              value={seoSlug}
              onChange={onSlugChange}
              label="Идентификатор страницы (слаг)"
              placeholder="Идентификатор страницы (слаг)"
              variant="outlined"
              sx={{ mt: '15px' }}
            />
          </Box>
        );
      }
      case 4: {
        return (
          <Box sx={{ pt: 1 }}>
            <VariationMedia
              id={variations[variationInd]?.id || 0}
              notifyUser={notifyUser}
            />
          </Box>
        );
      }
      default: {
        return <></>;
      }
    }
  }, [
    name,
    onNameChange,
    sku,
    onSkuChange,
    variations,
    printCharacteristics,
    seoTitle,
    onTitleChange,
    seoDescription,
    onDescriptionChange,
    seoSlug,
    onSlugChange,
    tabValue
  ]);

  return (
    <Modal
      open={isOpen}
      sx={{ transition: 'all .3s ease-in-out' }}
      onClose={handleModalClose}
      closeAfterTransition
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '6px',
          color: 'text.primary',
          transition: 'all .3s ease-in-out',
          p: 1
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={handleModalClose}>
            <Close />
          </IconButton>
        </Box>
        <Box sx={{ width: '100%', p: 3, pt: 0 }}>
          <Typography variant="h6">{`Редактирование ${
            variations[variationInd]?.is_base ?? 'базовой'
          } вариации ${variations[variationInd]?.name || ''}`}</Typography>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              sx={{ pt: 2 }}
            >
              <Tab label="Свойства" id="modal-tab-0" />
              <Tab label="Контент" id="modal-tab-1" />
              <Tab label="Характеристики" id="modal-tab-2" />
              <Tab label="SEO" id="modal-tab-3" />
              <Tab label="Изображения" id="modal-tab-4" />
            </Tabs>
          </Box>

          {customTab()}

          <Stack
            flexDirection="row"
            gap={2}
            sx={{ pt: 5, justifyContent: 'flex-end' }}
          >
            <Button
              size="small"
              variant="outlined"
              color="success"
              disabled={!variations[variationInd]?.id}
              onClick={handleSave}
            >
              Сохранить
            </Button>
            <Button size="small" variant="outlined" onClick={handleModalClose}>
              Отменить
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default observer(EditVariationModal);

import React, { FC, useEffect, useState, useCallback } from 'react';
import { Editor as TinyMCEEditor } from '@tinymce/tinymce-react';
import { Box } from '@mui/material';

import { DropzoneDialog, AlertType } from 'mui-file-dropzone';

import mediaStore from 'store/mediaStore';

import environment from 'config/environments/environment';

import logo from '../../assets/img/logoRound.svg';

import './editor.scss';

const defaults = {
  init: {
    width: '100%',
    height: '700px',
    menubar: true,
    branding: false,
    plugins: [
      'link',
      'lists',
      'advlist',
      'anchor',
      'code',
      'autolink',
      'autoresize',
      'autosave',
      'codesample',
      'emoticons',
      'fullscreen',
      'help',
      'importcss',
      'insertdatetime',
      'link',
      'lists',
      'pagebreak',
      'save',
      'paste',
      'searchreplace',
      'table',
      'visualblocks',
      'visualchars',
      'wordcount',
      'autosave',
      'charmap',
      'anchor',
      'quickbars'
    ],
    toolbar:
      'undo redo fullscreen | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | InsertMediaButton link insertdatetime table | visualblocks visualchars | help | searchreplace wordcount preview',
    language: 'ru',
    skin: 'oxide-dark',
    toolbar_sticky: true,
    autosave_ask_before_unload: true,
    autosave_interval: '10s',
    autosave_prefix: '{path}{query}-{id}-',
    autosave_restore_when_empty: false,
    autosave_retention: '2m',
    image_advtab: true,
    importcss_append: true,
    image_caption: true,
    quickbars_selection_toolbar:
      'bold italic | quicklink h1 h2 h3 | blockquote quicktable',
    quickbars_insert_toolbar: false,
    noneditable_noneditable_class: 'mceNonEditable',
    contextmenu: 'link imagetools table'
  }
};

interface IInit {
  width: string;
  height: string;
  menubar: boolean;
  branding: boolean;
  plugins: Array<string>;
  toolbar: string;
}

interface IProps {
  notifyUser(
    message: string,
    status: 'error' | 'success' | 'info' | 'warning'
  ): void;
  onEditorChange(value: string): void;
  value: string | undefined;
  init?: IInit;
  id: string;
}

const Editor: FC<IProps> = ({
  onEditorChange,
  notifyUser,
  value,
  init,
  id
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [configHasChanged, setRerenderEditor] = useState(false);
  const [internalInit, setInternalInit] = useState(init);
  const [galleryImagesToUpload, setGalleryImagesToUpload] = useState<File[]>(
    []
  );
  const [editorObj, setEditorObject] = useState<any>(null);

  const uploadGalleryImages = useCallback(
    (editor: any) => (files: File[]) => {
      if (!files) return;

      const formData = new FormData();
      files.forEach((image) => {
        formData.append('files[]', image);
      });

      const response = mediaStore.uploadToMedia(formData);

      response.then((val) => {
        if (val.isOk) {
          val.data.forEach((el) => {
            editor.insertContent(
              `<img src="${environment.baseUrl}/files/${el.path}" alt="${el.id}" />`
            );
          });
          setModalOpen(false);
        } else {
          notifyUser(val.msg, 'error');
        }
      });
    },
    [mediaStore]
  );

  const forceEditorRerender = () => {
    setRerenderEditor(true);
    requestAnimationFrame(() => setRerenderEditor(false));
  };

  useEffect(() => {
    if (JSON.stringify(init) !== JSON.stringify(internalInit)) {
      setInternalInit(init);
      forceEditorRerender();
    }
  }, [init]);

  return configHasChanged ? null : (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <TinyMCEEditor
        id={id}
        init={{
          ...defaults.init,
          ...internalInit,
          setup: (editor) => {
            setEditorObject(editor);
            editor.ui.registry.addButton('InsertMediaButton', {
              text: 'Вставить изображение',
              icon: logo,
              onAction: () => setModalOpen(true)
            });
            editor.ui.show();
          },
          id
        }}
        value={value}
        onEditorChange={onEditorChange}
        apiKey="954i4o0e10xr61wld4btxti59s17eiowez3i5kbj0j44067p"
      />
      <DropzoneDialog
        open={modalOpen}
        onSave={uploadGalleryImages(editorObj)}
        onChange={(files) => {
          setGalleryImagesToUpload(files);
        }}
        filesLimit={10}
        dialogTitle="Загрузка изображений"
        previewText="Выбранные файлы"
        dropzoneText="Нажмите сюда или перенесите файлы для загрузки"
        cancelButtonText="Отменить"
        submitButtonText="Загрузить"
        getFileLimitExceedMessage={(num: number) => {
          return `Превышен лимит по файлам. Максимальное количество - ${num}`;
        }}
        getFileAddedMessage={(name: string) => {
          return `Файл ${name} успешно добавлен`;
        }}
        getFileRemovedMessage={(name: string) => {
          return `Файл ${name} успешно удалён`;
        }}
        onAlert={(mes: string, mesType: AlertType) => {
          notifyUser(mes, mesType);
        }}
        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        fileObjects={galleryImagesToUpload}
        showPreviews
        maxFileSize={20000000}
        onClose={() => setModalOpen(false)}
      />
    </Box>
  );
};

export default Editor;

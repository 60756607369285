// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { observable, action, makeObservable, runInAction } from 'mobx';

// API
import api from 'api';

// Types
import {
  IProps,
  ITag,
  IPaginationResponse,
  ListType,
  ITagItem,
  IPartialTagItem
} from 'types/types';
import axios from 'axios';

const emptyTag = (): ITagItem => ({
  id: 0,
  name: '',
  main_category: null,
  containers: [],
  seo_description: null,
  seo_title: null,
  slug: null,
  text_content: null,
  created_at: new Date(),
  updated_at: new Date()
});

interface IError {
  isOk: boolean;
  msg: string;
}

interface IAddTag {
  name: string;
}

class TagsStore {
  @observable
  tags: ListType<ITag> | IPaginationResponse<ITag> = [];

  @observable
  tag: ITagItem = emptyTag();

  @observable
  loading: boolean = true;

  constructor() {
    makeObservable(this);
  }

  @action
  getTags = async ({ page, paginate, perpage }: IProps): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const tagsFromApi = await api.tags.getTagsList(page, paginate, perpage);

      runInAction(() => {
        if (tagsFromApi.status === 200) {
          this.tags = tagsFromApi.data;

          res.isOk = true;
          res.msg = 'Теги получены';
        } else {
          console.error(
            `Error\nCode: ${tagsFromApi.status}\nStatus: ${tagsFromApi.statusText}`
          );

          res.isOk = false;
          res.msg = tagsFromApi.statusText;
          this.tags = [];
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  getAllTags = async (): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const tagsFromApi = await api.tags.getTagsList();

      runInAction(() => {
        if (tagsFromApi.status === 200) {
          this.tags = tagsFromApi.data;

          res.isOk = true;
          res.msg = 'Теги получены';
        } else {
          console.error(
            `Error\nCode: ${tagsFromApi.status}\nStatus: ${tagsFromApi.statusText}`
          );

          res.isOk = false;
          res.msg = tagsFromApi.statusText;
          this.tags = [];
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  getTag = async (id: number): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const tagFromApi = await api.tags.getTag(id);

      runInAction(() => {
        if (tagFromApi.status === 200) {
          this.tag = tagFromApi.data;

          res.isOk = true;
          res.msg = 'Тег получен';
        } else {
          console.error(
            `Error\nCode: ${tagFromApi.status}\nStatus: ${tagFromApi.statusText}`
          );
          this.tag = emptyTag();
          res.isOk = false;
          res.msg = tagFromApi.statusText || '';
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  deleteTag = async (id: number): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const tagFromApi = await api.tags.deleteTag(id);

      runInAction(() => {
        if (tagFromApi.status === 200) {
          res.isOk = true;
          res.msg = 'Тег удалён';
        } else {
          console.error(
            `Error\nCode: ${tagFromApi.status}\nStatus: ${tagFromApi.statusText}`
          );

          res.isOk = false;
          res.msg = tagFromApi.name
            ? `Ошибка ${tagFromApi.name}: ${tagFromApi.message}`
            : `Ошибка ${tagFromApi.status}: ${tagFromApi.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  addTag = async (params: IAddTag): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const tagFromApi = await api.tags.addTag(params);

      runInAction(() => {
        if (tagFromApi.status === 201) {
          res.isOk = true;
          res.msg = 'Тег добавлен';
        } else {
          console.error(
            `Error\nCode: ${tagFromApi.status}\nStatus: ${tagFromApi.statusText}`
          );

          res.isOk = false;
          res.msg = tagFromApi.name
            ? `Ошибка ${tagFromApi.name}: ${tagFromApi.message}`
            : `Ошибка ${tagFromApi.status}: ${tagFromApi.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  editTag = async (id: number, params: IPartialTagItem): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const tagFromApi = await api.tags.patchTag(id, params);

      runInAction(() => {
        if (tagFromApi.status === 200) {
          res.isOk = true;
          res.msg = 'Тег добавлен';
        } else {
          console.error(
            `Error\nCode: ${tagFromApi.status}\nStatus: ${tagFromApi.statusText}`
          );

          res.isOk = false;
          res.msg = tagFromApi.name
            ? `Ошибка ${tagFromApi.name}: ${tagFromApi.message}`
            : `Ошибка ${tagFromApi.status}: ${tagFromApi.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  changeMainCategory = async (
    id: number,
    categoryId: number
  ): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const tagFromApi = await api.tags.changeMainCategory(id, {
        main_category_id: categoryId
      });

      runInAction(() => {
        if (tagFromApi.status === 200) {
          res.isOk = true;
          res.msg = 'Основная категория изменена';
        } else {
          console.error(
            `Error\nCode: ${tagFromApi.status}\nStatus: ${tagFromApi.statusText}`
          );

          res.isOk = false;
          res.msg = tagFromApi.name
            ? `Ошибка ${tagFromApi.name}: ${tagFromApi.message}`
            : `Ошибка ${tagFromApi.status}: ${tagFromApi.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  clearAll = () => {
    this.loading = true;
    this.tags = [];
    this.tag = emptyTag();
  };
}

const tagsStore = new TagsStore();
export default tagsStore;

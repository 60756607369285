import axios from 'api/helpers/axios';

import {
  ICategory,
  ICategoryItem,
  IPaginationResponse,
  IPartialCategoryItem,
  ListType
} from 'types/types';
import { toSlug } from 'utilities/functions';

interface ICategoriesListResponse {
  data: IPaginationResponse<ICategory> | ListType<ICategory>;
  status: number;
  statusText: string;
}

interface ICategoryResponse<P> {
  data: P;
  status?: number;
  statusText?: string;
  message?: string;
  name?: string;
}

interface IAddCategory {
  name: string;
  parent_id?: number;
}

export default {
  async getCategoriesList(
    page?: number,
    paginate?: boolean,
    perpage?: number,
    wherein?: string
  ): Promise<ICategoriesListResponse> {
    const response = await axios.get('/categories', {
      params: wherein ? { wherein } : { page, paginate, perpage }
    });

    return response;
  },

  async getCategory(
    categoryId: number
  ): Promise<ICategoryResponse<ICategoryItem>> {
    const response = await axios.get(`/categories/${categoryId}`);

    return response;
  },

  async addCategory(data: IAddCategory): Promise<ICategoryResponse<unknown>> {
    const response = await axios.post('/categories', {
      ...data,
      slug: toSlug(data)
    });

    return response;
  },

  async editCategory(
    id: number,
    data: ICategory | ICategoryItem
  ): Promise<ICategoryResponse<unknown>> {
    const response = await axios.patch(`/categories/${id}`, {
      ...data,
      slug: data.slug || toSlug(data)
    });

    return response;
  },

  async patchCategory(
    id: number,
    data: IPartialCategoryItem
  ): Promise<ICategoryResponse<unknown>> {
    const response = await axios.patch(`/categories/${id}`, {
      ...data,
      slug: data.slug || toSlug(data)
    });

    return response;
  },

  async deleteCategory(id: number): Promise<ICategoryResponse<unknown>> {
    const response = await axios.delete(`/categories/${id}`);

    return response;
  }
};

export default [
  {
    headerName: 'Каталог',
    key: '#19287e31jhhgbmn923345t2',
    children: [
      {
        name: 'Контейнеры товаров',
        key: '#-010xk9c8xj130xc0x',
        to: '/products'
      },
      {
        name: 'Категории',
        key: '#09,v34058cm239xdc',
        to: '/categories'
      },
      {
        name: 'Теги',
        key: '#98mb56m98v93n4c78n',
        to: '/tags'
      },
      {
        name: 'Инструменты',
        key: '#09jh9485gh934gf9',
        to: '/tools'
      }
    ]
  }
  // {
  //   headerName: 'Контент',
  //   key: '#9sd8405kf104923adf94',
  //   children: infoSectionStore.infoSections.map((el) => ({
  //     name: el.name,
  //     key: el.id,
  //     to: `/${el.id}`
  //   }))
  // },
  // {
  //   headerName: 'Система',
  //   key: '#210asd192374a10jy293j4',
  //   children: [
  //     {
  //       name: 'Импорт / Экспорт',
  //       key: '#,m,n,bnb8v7ncv47c3b',
  //       to: '/import-export'
  //     }
  //   ]
  // }
];

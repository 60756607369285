import React, { FC, useState, useEffect, useCallback } from 'react';
import {
  Button,
  Typography,
  Box,
  Card,
  CardMedia,
  IconButton
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { DropzoneDialog, AlertType } from 'mui-file-dropzone';
import SortableList, { SortableItem } from 'react-easy-sort';

import { observer } from 'mobx-react-lite';

import FileUpload from 'react-mui-fileuploader';
import { ExtendedFileProps } from 'react-mui-fileuploader/dist/types/index.types';

import mediaStore from 'store/mediaStore';
import Loader from 'components/loader';

import environment from 'config/environments/environment';

import logoImage from '../../assets/img/logoRound.svg';

interface IProps {
  id: number;
  notifyUser(
    message: string,
    status: 'error' | 'success' | 'info' | 'warning'
  ): void;
}

const InfoSectionMedia: FC<IProps> = ({ id, notifyUser }) => {
  const { main, gallery, mainLoading, galleryLoading } = mediaStore;
  const [coverToUpload, setCoverToUpload] = useState<Blob | null>(null);
  const [dropZoneOpen, setDropZoneOpen] = useState<boolean>(false);
  const [galleryImagesToUpload, setGalleryImagesToUpload] = useState<File[]>(
    []
  );

  const reload = useCallback(() => {
    const response = mediaStore.getMedia('info_sections', id);

    response.then((val) => {
      if (!val.isOk) {
        notifyUser(val.msg, 'error');
      }
    });
  }, [mediaStore, notifyUser, id]);

  useEffect(() => {
    if (id) {
      const response = mediaStore.getMedia('info_sections', id);

      response.then((val) => {
        if (!val.isOk) {
          notifyUser(val.msg, 'error');
        }
      });
    }
  }, []);

  const uploadCover = useCallback(() => {
    if (!coverToUpload) return;

    const formData = new FormData();
    formData.append('files[]', coverToUpload);

    const response = mediaStore.uploadCover(formData);

    response.then((val) => {
      if (val.isOk) {
        const newResp = mediaStore.replaceCover(
          'info_sections',
          id,
          'main',
          main.id
        );

        setCoverToUpload(null);

        newResp.then((newVal) => {
          notifyUser(newVal.msg, newVal.isOk ? 'success' : 'error');
        });
      } else {
        notifyUser(val.msg, 'error');
      }
    });
  }, [coverToUpload, mediaStore, main]);

  const uploadGalleryImages = useCallback(
    (files: File[]) => {
      if (!files) return;

      const formData = new FormData();
      files.forEach((image) => {
        formData.append('files[]', image);
      });

      const response = mediaStore.uploadToGallery(formData);

      response.then((val) => {
        if (val.isOk) {
          const newResp = mediaStore.assignGallery('info_sections', id);

          newResp.then((newVal) => {
            notifyUser(newVal.msg, newVal.isOk ? 'success' : 'error');

            if (newVal.isOk) {
              reload();
              setDropZoneOpen(false);
            }
          });
        } else {
          notifyUser(val.msg, 'error');
        }
      });
    },
    [coverToUpload, mediaStore, main, gallery]
  );

  const deleteImage = useCallback(
    (mediaId: number, isFromMain: boolean) => () => {
      const response = mediaStore.deleteImage(mediaId, isFromMain);

      response.then((val) => {
        notifyUser(val.msg, !val.isOk ? 'error' : 'success');

        mediaStore.getMedia('info_sections', id);
      });
    },
    [mediaStore, id]
  );

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    const oldSort = gallery[oldIndex].sort;
    const newSort = gallery[newIndex].sort;

    if (
      oldSort !== null &&
      oldSort !== undefined &&
      newSort !== null &&
      newSort !== undefined
    ) {
      const resp = mediaStore.changeSort(gallery[oldIndex].id, newSort);

      resp.then((val) => {
        if (val.isOk) {
          const response = mediaStore.changeSort(gallery[newIndex].id, oldSort);

          response.then((newVal) => {
            notifyUser(newVal.msg, newVal.isOk ? 'success' : 'error');
            if (newVal.isOk) {
              reload();
            }
          });
        }
      });
    } else {
      notifyUser('Невозможно изменить порядок', 'error');
    }
  };

  const printCover = () => {
    return main.path ? (
      <Card sx={{ position: 'relative', height: 200, width: 200 }}>
        <CardMedia
          component="img"
          sx={{ height: 200, width: 200 }}
          image={`${environment.baseUrl}/files/${main.path}`}
          alt="Обложка"
        />
        <IconButton
          sx={{ position: 'absolute', top: '10px', right: '10px' }}
          onClick={deleteImage(main.id, true)}
        >
          <DeleteOutlineIcon color="error" />
        </IconButton>
      </Card>
    ) : (
      <Box>
        <FileUpload
          onFilesChange={(files: ExtendedFileProps[]) => {
            setCoverToUpload(files[0]);
          }}
          title="Загрузка обложки"
          header="Переместите"
          leftLabel="или"
          rightLabel="чтобы загрузить"
          buttonLabel="НАЖМИТЕ"
          buttonRemoveLabel="Удалить выбранное"
          maxFileSize={21}
          maxUploadFiles={1}
          maxFilesContainerHeight={400}
          acceptedType={'image/*'}
          errorSizeMessage="Недопустимый размер изображений"
          allowedExtensions={['jpg', 'jpeg', 'png', 'gif', 'webp']}
          onError={(e) => {
            notifyUser(e, 'error');
          }}
          imageSrc={logoImage}
          BannerProps={{ elevation: 0, variant: 'outlined' }}
          showPlaceholderImage={false}
          PlaceholderGridProps={{ md: 4 }}
          LabelsGridProps={{ md: 8 }}
          ContainerProps={{
            elevation: 0,
            variant: 'outlined',
            sx: { p: 2 }
          }}
          PlaceholderImageDimension={{
            xs: { width: 512, height: 512 },
            sm: { width: 512, height: 512 },
            md: { width: 512, height: 512 },
            lg: { width: 512, height: 512 }
          }}
        />
        <Button onClick={uploadCover} disabled={!coverToUpload}>
          Отправить
        </Button>
      </Box>
    );
  };

  const printGallery = () => {
    return gallery ? (
      <SortableList
        onSortEnd={onSortEnd}
        style={{
          display: 'flex',
          paddingLeft: '40px',
          paddingRight: '40px',
          flexWrap: 'wrap',
          gap: '20px',
          userSelect: 'none',
          maxWidth: '150px',
          maxHeight: '150px'
        }}
      >
        {gallery.map((item) => (
          <SortableItem key={item.id}>
            <Box
              sx={{
                position: 'relative',
                flexShrink: 0,
                cursor: 'grab',
                userSelect: 'none',
                borderRadius: '100%',
                maxWidth: '150px',
                maxHeight: '150px'
              }}
            >
              <Card
                sx={{
                  cursor: '-webkit-grab',
                  position: 'relative'
                }}
              >
                <CardMedia
                  component="img"
                  height="150"
                  sx={{
                    pointerEvents: 'none'
                  }}
                  image={`${environment.baseUrl}/files/${item.path}`}
                  alt="Обложка"
                />
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px'
                  }}
                  onClick={deleteImage(item.id, false)}
                >
                  <DeleteOutlineIcon color="error" />
                </IconButton>
              </Card>
            </Box>
          </SortableItem>
        ))}
      </SortableList>
    ) : null;
  };

  return (
    <>
      <Box sx={{ alignSelf: 'flex-start', px: 5, width: '100%' }}>
        <Typography variant="h6" sx={{ pb: 3 }}>
          Обложка
        </Typography>

        <Box sx={{ maxWidth: '250px' }}>
          {!mainLoading ? printCover() : <Loader />}
        </Box>
      </Box>

      <Typography variant="h6" sx={{ pt: 5, pl: 5, alignSelf: 'flex-start' }}>
        Галерея
      </Typography>

      {!galleryLoading ? printGallery() : <Loader />}

      <DropzoneDialog
        open={dropZoneOpen}
        onSave={uploadGalleryImages}
        onChange={(files) => {
          setGalleryImagesToUpload(files);
        }}
        filesLimit={10}
        dialogTitle="Загрузка изображений"
        previewText="Выбранные файлы"
        dropzoneText="Нажмите сюда или перенесите файлы для загрузки"
        cancelButtonText="Отменить"
        submitButtonText="Загрузить"
        getFileLimitExceedMessage={(num: number) => {
          return `Превышен лимит по файлам. Максимальное количество - ${num}`;
        }}
        getFileAddedMessage={(name: string) => {
          return `Файл ${name} успешно добавлен`;
        }}
        getFileRemovedMessage={(name: string) => {
          return `Файл ${name} успешно удалён`;
        }}
        onAlert={(mes: string, mesType: AlertType) => {
          notifyUser(mes, mesType);
        }}
        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        fileObjects={galleryImagesToUpload}
        showPreviews
        maxFileSize={20000000}
        onClose={() => {
          setDropZoneOpen(false);
        }}
      />
      <Button onClick={() => setDropZoneOpen(true)}>Добавить</Button>
    </>
  );
};

export default observer(InfoSectionMedia);

import axios from 'axios';
import environment from 'config/environments/environment';

const instance = axios.create({
  baseURL: environment.baseApiUrl,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    // eslint-disable-next-line no-useless-computed-key
    ['X-Requested-With']: 'XMLHttpRequest'
  }
});

export default instance;

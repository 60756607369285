import React, { FC, useEffect, useState, useCallback } from 'react';
import {
  Box,
  Typography,
  Grid,
  Pagination,
  Stack,
  IconButton,
  Button
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { observer } from 'mobx-react-lite';

import Loader from 'components/loader';
import DraggableList from 'components/draggableList';
import Notification from 'components/notification';
import AddTagModal from 'components/modals/addTag';

import { isPaginated } from 'utilities/functions';

import tagsStore from 'store/tagsStore';
import globalState from 'store/globalState';

import { EnumPath, ITag } from 'types/types';

interface IParams {
  page: number;
  paginate: boolean;
  perpage: number;
}

const Tags: FC = () => {
  const { loading, tags } = tagsStore;

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const [notificationStatus, setNotificationStatus] = useState<
    'error' | 'success' | 'warning'
  >('error');
  const [notificationMsg, setNotificationMsg] = useState<string>('');

  const [params, setParams] = useState<IParams>({
    page: 1,
    perpage: 20,
    paginate: true
  });

  const notifyUser = useCallback(
    (message: string, status: 'error' | 'success') => {
      setNotificationMsg(message);
      setNotificationStatus(status);
      setIsNotificationOpen(true);
    },
    [setNotificationMsg, setNotificationStatus, setIsNotificationOpen]
  );

  const getTags = () => {
    const response = tagsStore.getTags(params);

    response.then((val) => {
      if (!val.isOk) {
        notifyUser(val.msg, 'error');
      }
    });
  };

  const onTagAdd = (value: string) => {
    const response = tagsStore.addTag({
      name: value
    });

    response.then((val) => {
      notifyUser(val.msg, val.isOk ? 'success' : 'error');

      getTags();
    });
  };

  useEffect(() => {
    const response = tagsStore.getTags(params);

    response.then((val) => {
      if (!val.isOk) {
        notifyUser(val.msg, 'error');
      }
    });

    globalState.setNewPathname('теги');
    globalState.setRefreshFunction(tagsStore.getTags, params);
  }, []);

  useEffect(() => {
    const response = tagsStore.getTags(params);

    response.then((val) => {
      if (!val.isOk) {
        notifyUser(val.msg, 'error');
      }
    });

    globalState.setRefreshFunction(tagsStore.getTags, params);
  }, [params]);

  return (
    <Grid
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'scroll'
      }}
      item
      xs={12}
      md={12}
    >
      <Notification
        open={isNotificationOpen}
        message={notificationMsg}
        onClose={() => {
          setIsNotificationOpen(false);
          setNotificationMsg('');
        }}
        severity={notificationStatus}
      />
      <AddTagModal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        onTagAdd={onTagAdd}
      />
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'primary',
              width: 'auto',
              height: '48px'
            }}
          >
            <Typography variant="h6" sx={{ pl: 2 }}>
              Название
            </Typography>

            <Stack flexDirection="row">
              <IconButton onClick={() => setModalIsOpen(true)}>
                <AddCircleOutlineIcon color="primary" />
              </IconButton>
              <Button variant="text" onClick={() => setModalIsOpen(true)}>
                Создать
              </Button>
            </Stack>
          </Box>
          <DraggableList
            list={
              isPaginated<ITag>(tags)
                ? tags.data.slice(0, 20)
                : tags.slice(0, 20)
            }
            notifyUser={notifyUser}
            onReload={getTags}
            path={EnumPath.tags}
          />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              p: 1
            }}
          >
            <Pagination
              count={isPaginated<ITag>(tags) ? tags.last_page : 1}
              onChange={(
                event: React.ChangeEvent<unknown>,
                pageNum: number
              ) => {
                setParams((value) => {
                  return {
                    ...value,
                    page: pageNum
                  };
                });
              }}
              page={params.page}
              shape="rounded"
            />
          </Box>
        </Box>
      )}
    </Grid>
  );
};

export default observer(Tags);

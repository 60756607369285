import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  ChangeEvent
} from 'react';
import {
  Box,
  Typography,
  TextField,
  Tabs,
  Tab,
  Button,
  Stack,
  Switch
} from '@mui/material';

import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Editor from 'components/editor/editor';
import Loader from 'components/loader';
import Notification from 'components/notification';
import InfoPagesMedia from 'components/infoPagesMedia';

import infoPagesStore from 'store/infoPagesStore';
import globalState from 'store/globalState';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexDirection: 'column',
            pt: 3
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const InfoPage: FC = () => {
  const { id } = useParams();
  const { infoPage, loading } = infoPagesStore;
  const [tabPanel, setTabPanel] = useState<number>(0);
  const [fieldsChanged, setFieldsChanged] = useState<boolean>(false);

  const [switchChecked, setSwitchChecked] = useState<boolean>(
    !infoPage.text_content?.includes('<!-- widget -->')
  );

  const [markup, setMarkup] = useState<string>(infoPage.text_content || '');

  const [seoTitle, setSeoTitle] = useState<string>(infoPage.seo_title || '');
  const [seoDescription, setSeoDescription] = useState<string>(
    infoPage.seo_description || ''
  );
  const [seoSlug, setSeoSlug] = useState<string>(infoPage.slug || '');

  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const [notificationMsg, setNotificationMsg] = useState<string>('');
  const [notificationStatus, setNotificationStatus] = useState<
    'error' | 'success' | 'info' | 'warning'
  >('error');

  const [name, setName] = useState<string>(infoPage.name);
  const [description, setDescription] = useState<string>(infoPage.description);

  const notifyUser = useCallback(
    (message: string, status: 'error' | 'success' | 'info' | 'warning') => {
      setNotificationMsg(message);
      setNotificationStatus(status);
      setIsNotificationOpen(true);
    },
    [setNotificationMsg, setNotificationStatus, setIsNotificationOpen]
  );

  useEffect(() => {
    globalState.setNewPathname('Инфостраницы');

    if (id) {
      const response = infoPagesStore.getInfoPage(parseInt(id, 10));

      response.then((val) => {
        if (!val.isOk) {
          notifyUser(val.msg, 'error');
        }
      });

      globalState.setRefreshFunction(
        infoPagesStore.getInfoPage,
        parseInt(id, 10)
      );
    } else {
      globalState.setRefreshFunction(infoPagesStore.getInfoPage, 0);
    }
  }, [id]);

  useEffect(() => {
    setName(infoPage.name);
    setSwitchChecked(!infoPage.text_content?.includes('<!-- widget -->'));
    setDescription(infoPage.description);
    setMarkup(infoPage.text_content || '');
    setSeoTitle(infoPage.seo_title || '');
    setSeoDescription(infoPage.seo_description || '');
    setSeoSlug(infoPage.slug || '');
  }, [infoPage]);

  const onNameChange = useCallback(
    (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFieldsChanged(true);
      setName(value.currentTarget.value);
    },
    [setFieldsChanged, setName]
  );

  const onDescriptionChange = useCallback(
    (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFieldsChanged(true);
      setDescription(value.currentTarget.value);
    },
    [setFieldsChanged, setDescription]
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabPanel(newValue);
  };

  const onEditorChange = useCallback(
    (val: string) => {
      setFieldsChanged(true);
      setMarkup(val);
    },
    [setMarkup, setFieldsChanged]
  );

  const onSeoTitleChange = useCallback(
    (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFieldsChanged(true);
      setSeoTitle(value.currentTarget.value);
    },
    [setFieldsChanged, setSeoTitle]
  );

  const onSeoDescriptionChange = useCallback(
    (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFieldsChanged(true);
      setSeoDescription(value.currentTarget.value);
    },
    [setFieldsChanged, setSeoDescription]
  );

  const onSeoSlugChange = useCallback(
    (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFieldsChanged(true);
      setSeoSlug(value.currentTarget.value);
    },
    [setFieldsChanged, setSeoSlug]
  );

  const onProductSaveClick = () => {
    const response = infoPagesStore.editInfoPage(infoPage.id, {
      ...infoPage,
      name,
      description,
      seo_title: seoTitle,
      seo_description: seoDescription,
      slug: seoSlug,
      text_content: markup
    });

    response.then((val) => {
      if (val.isOk) {
        const reloadResp = infoPagesStore.getInfoPage(parseInt(id || '-1', 10));

        reloadResp.then((newVal) => {
          if (!newVal.isOk) {
            notifyUser(newVal.msg, 'error');
          } else {
            notifyUser('Изменения сохранены', 'success');
            setFieldsChanged(false);
          }
        });
      } else {
        notifyUser('Не удалось сохранить изменения', 'error');
      }
    });
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'auto'
      }}
    >
      <Notification
        open={isNotificationOpen}
        message={notificationMsg}
        onClose={() => {
          setIsNotificationOpen(false);
          setNotificationMsg('');
        }}
        severity={notificationStatus}
      />
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Tabs
              value={tabPanel}
              onChange={handleChange}
              aria-label="Product Tabs"
            >
              <Tab
                label="Редактирование"
                id="tab-0"
                aria-controls="tabpanel-0"
              />
              <Tab label="СЕО контент" id="tab-1" aria-controls="tabpanel-1" />
              <Tab label="Изображения" id="tab-2" aria-controls="tabpanel-2" />
            </Tabs>

            {fieldsChanged && (
              <Button
                size="small"
                variant="outlined"
                color="success"
                sx={{ alignSelf: 'center', mr: 2 }}
                onClick={onProductSaveClick}
              >
                Сохранить изменения
              </Button>
            )}
          </Box>

          <TabPanel value={tabPanel} index={0}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Название:
              </Typography>
              <TextField
                value={name}
                onChange={onNameChange}
                InputProps={{
                  sx: { fontSize: '16px', marginBottom: '4px' }
                }}
                sx={{ width: '55%' }}
                margin="dense"
                size="small"
              />
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Описание:
              </Typography>
              <TextField
                multiline
                value={description}
                onChange={onDescriptionChange}
                InputProps={{
                  sx: { fontSize: '16px', marginBottom: '4px' }
                }}
                sx={{ width: '55%' }}
                margin="dense"
                size="small"
              />
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Stack
                display="flex"
                flexDirection="row"
                gap="10px"
                alignItems="center"
                sx={{ paddingTop: '50px', width: '80%' }}
              >
                <Switch
                  checked={switchChecked}
                  onChange={() => setSwitchChecked((val) => !val)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography>
                  {!switchChecked ? 'Режим кода' : 'Визуальный режим'}
                </Typography>
              </Stack>
            </Box>

            {switchChecked ? (
              <Editor
                id="id"
                value={!markup ? undefined : markup}
                notifyUser={notifyUser}
                onEditorChange={onEditorChange}
              />
            ) : (
              <TextField
                fullWidth
                multiline
                minRows={10}
                value={markup || ''}
                onChange={(val) => {
                  setMarkup(val.target.value);
                  setFieldsChanged(true);
                }}
              />
            )}
          </TabPanel>
          <TabPanel value={tabPanel} index={1}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Мета-тег Заголовок:
              </Typography>
              <TextField
                value={seoTitle || ''}
                onChange={onSeoTitleChange}
                InputProps={{
                  sx: { fontSize: '16px', marginBottom: '4px' }
                }}
                multiline
                sx={{ width: '55%' }}
                margin="dense"
                size="small"
              />
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Мета-тег Описание:
              </Typography>
              <TextField
                value={seoDescription || ''}
                onChange={onSeoDescriptionChange}
                InputProps={{
                  sx: { fontSize: '16px', marginBottom: '4px' }
                }}
                multiline
                sx={{ width: '55%' }}
                margin="dense"
                size="small"
              />
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Название в ссылке:
              </Typography>
              <TextField
                value={seoSlug || ''}
                onChange={onSeoSlugChange}
                InputProps={{
                  sx: { fontSize: '16px', marginBottom: '4px' }
                }}
                multiline
                sx={{ width: '55%' }}
                margin="dense"
                size="small"
              />
            </Box>
          </TabPanel>
          <TabPanel value={tabPanel} index={2}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                userSelect: 'none',
                gap: 3
              }}
            >
              <InfoPagesMedia
                id={parseInt(id || '0', 10)}
                notifyUser={notifyUser}
              />
            </Box>
          </TabPanel>
        </Box>
      )}
    </Box>
  );
};

export default observer(InfoPage);

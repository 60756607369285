import React, { FC, useState, useEffect, useCallback } from 'react';
import {
  Button,
  Typography,
  Box,
  Card,
  CardMedia,
  IconButton
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { observer } from 'mobx-react-lite';

import FileUpload from 'react-mui-fileuploader';
import { ExtendedFileProps } from 'react-mui-fileuploader/dist/types/index.types';

import mediaStore from 'store/mediaStore';
import Loader from 'components/loader';

import environment from 'config/environments/environment';

import logoImage from '../../assets/img/logoRound.svg';

interface IProps {
  id: number;
  notifyUser(
    message: string,
    status: 'error' | 'success' | 'info' | 'warning'
  ): void;
}

const InfoSectionMedia: FC<IProps> = ({ id, notifyUser }) => {
  const { main, mainLoading } = mediaStore;
  const [coverToUpload, setCoverToUpload] = useState<Blob | null>(null);

  useEffect(() => {
    if (id) {
      const response = mediaStore.getMedia('categories', id);

      response.then((val) => {
        if (!val.isOk) {
          notifyUser(val.msg, 'error');
        }
      });
    }
  }, [id]);

  const uploadCover = useCallback(() => {
    if (!coverToUpload) return;

    const formData = new FormData();
    formData.append('files[]', coverToUpload);

    const response = mediaStore.uploadCover(formData);

    response.then((val) => {
      if (val.isOk) {
        const newResp = mediaStore.replaceCover(
          'categories',
          id,
          'main',
          main.id
        );

        setCoverToUpload(null);

        newResp.then((newVal) => {
          notifyUser(newVal.msg, newVal.isOk ? 'success' : 'error');
        });
      } else {
        notifyUser(val.msg, 'error');
      }
    });
  }, [coverToUpload, mediaStore, main]);

  const deleteImage = useCallback(
    (mediaId: number, isFromMain: boolean) => () => {
      const response = mediaStore.deleteImage(mediaId, isFromMain);

      response.then((val) => {
        notifyUser(val.msg, !val.isOk ? 'error' : 'success');

        mediaStore.getMedia('categories', id);
      });
    },
    [mediaStore, id]
  );

  const printCover = () => {
    return main.path ? (
      <Card sx={{ position: 'relative', height: 200, width: 200 }}>
        <CardMedia
          component="img"
          sx={{ height: 200, width: 200 }}
          image={`${environment.baseUrl}/files/${main.path}`}
          alt="Обложка"
        />
        <IconButton
          sx={{ position: 'absolute', top: '10px', right: '10px' }}
          onClick={deleteImage(main.id, true)}
        >
          <DeleteOutlineIcon color="error" />
        </IconButton>
      </Card>
    ) : (
      <Box>
        <FileUpload
          onFilesChange={(files: ExtendedFileProps[]) => {
            setCoverToUpload(files[0]);
          }}
          title="Загрузка обложки"
          header="Переместите"
          leftLabel="или"
          rightLabel="чтобы загрузить"
          buttonLabel="НАЖМИТЕ"
          buttonRemoveLabel="Удалить выбранное"
          maxFileSize={21}
          maxUploadFiles={1}
          maxFilesContainerHeight={400}
          acceptedType={'image/*'}
          errorSizeMessage="Недопустимый размер изображений"
          allowedExtensions={['jpg', 'jpeg', 'png', 'gif', 'webp']}
          onError={(e) => {
            notifyUser(e, 'error');
          }}
          imageSrc={logoImage}
          BannerProps={{ elevation: 0, variant: 'outlined' }}
          showPlaceholderImage={false}
          PlaceholderGridProps={{ md: 4 }}
          LabelsGridProps={{ md: 8 }}
          ContainerProps={{
            elevation: 0,
            variant: 'outlined',
            sx: { p: 2 }
          }}
          PlaceholderImageDimension={{
            xs: { width: 512, height: 512 },
            sm: { width: 512, height: 512 },
            md: { width: 512, height: 512 },
            lg: { width: 512, height: 512 }
          }}
        />
        <Button onClick={uploadCover} disabled={!coverToUpload}>
          Отправить
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ alignSelf: 'flex-start', px: 5, width: '100%' }}>
        <Typography variant="h6" sx={{ pb: 3 }}>
          Обложка
        </Typography>

        <Box sx={{ maxWidth: '250px' }}>
          {!mainLoading ? printCover() : <Loader />}
        </Box>
      </Box>
    </>
  );
};

export default observer(InfoSectionMedia);

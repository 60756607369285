import React, { FC } from 'react';
import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardActions,
  Button,
  Tooltip,
  IconButton
} from '@mui/material';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { IProduct, IProductVariation, IAddProduct } from 'types/types';

interface IProps {
  product: IProduct | IProductVariation | IAddProduct;
  isDraft?: boolean;
  isBase?: boolean | null;
  onCopy?(): void;
  onClick?(): void;
  onEdit?(): void;
  onDelete?(): void;
}

const ProductCard: FC<IProps> = ({
  product,
  isDraft,
  isBase,
  onClick,
  onEdit,
  onCopy,
  onDelete
}) => {
  return (
    <Card
      sx={{
        width: 250,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      {isBase && (
        <Typography sx={{ pl: 2, py: 1, color: 'error.main' }}>
          Базовая вариация
        </Typography>
      )}
      <CardActionArea onClick={onClick || onEdit}>
        {isDraft && (
          <Tooltip
            title="Нажмите 'Сохранить Изменения', чтобы всё сохранилось"
            placement="left-start"
            sx={{ position: 'absolute', top: 0, left: 0 }}
          >
            <AnnouncementIcon color="warning" />
          </Tooltip>
        )}
        <CardContent sx={{ pb: 0 }}>
          <Typography gutterBottom variant="h5" component="div">
            {product.name}
          </Typography>
          {'price' in product ? (
            <Typography variant="body2" color="text.secondary">
              Стоимость: {product.price} р.
            </Typography>
          ) : null}
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        {onEdit && (
          <Button size="small" color="primary" onClick={onEdit}>
            Изменить
          </Button>
        )}
        {onCopy && (
          <IconButton size="small" onClick={onCopy} aria-label="Копировать">
            <FileCopyIcon color="action" />
          </IconButton>
        )}
        <Button size="small" color="error" onClick={onDelete}>
          Удалить
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProductCard;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { observable, action, makeObservable, runInAction } from 'mobx';

// API
import api from 'api';

// Types
import {
  IProps,
  ICategory,
  ICategoryItem,
  IPaginationResponse,
  ListType,
  IPartialCategoryItem
} from 'types/types';
import axios from 'axios';

const emptyCategory = (): ICategoryItem => ({
  id: 0,
  sort: 0,
  name: '',
  alternative_name: null,
  seo_description_template: null,
  seo_title_template: null,
  description_template: null,
  parent_id: null,
  seo_description: null,
  seo_title: null,
  slug: null,
  text_content: null,
  created_at: new Date(),
  updated_at: new Date(),
  children: [],
  containers: [],
  applicable_characteristic_types: [],
  applicable_characteristic_types_for_filter: [],
  applicable_characteristic_types_for_list: [],
  applicable_tags: []
});

interface IError {
  isOk: boolean;
  msg: string;
}

interface IAddCategory {
  name: string;
  parent_id?: number;
}

class CategoriesStore {
  @observable
  categories: ListType<ICategory> | IPaginationResponse<ICategory> = [];

  @observable
  childrenCategories: ListType<ICategory> | IPaginationResponse<ICategory> = [];

  @observable
  category: ICategoryItem = emptyCategory();

  @observable
  loading: boolean = true;

  constructor() {
    makeObservable(this);
  }

  @action
  getCategories = async ({
    page,
    paginate,
    perpage
  }: IProps): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const categoriesFromApi = await api.categories.getCategoriesList(
        page,
        paginate,
        perpage
      );

      runInAction(() => {
        if (categoriesFromApi.status === 200) {
          this.categories = categoriesFromApi.data;

          res.isOk = true;
          res.msg = 'Категории получены';
        } else {
          console.error(
            `Error\nCode: ${categoriesFromApi.status}\nStatus: ${categoriesFromApi.statusText}`
          );
          res.isOk = false;
          res.msg = categoriesFromApi.statusText;
          this.categories = [];
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  clearAll = () => {
    this.loading = true;
    this.categories = [];
    this.category = emptyCategory();
  };

  @action
  getCategory = async (id: number): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const categoryFromApi = await api.categories.getCategory(id);

      runInAction(() => {
        if (categoryFromApi.status === 200) {
          this.category = categoryFromApi.data;

          res.isOk = true;
          res.msg = 'Категория получена';
        } else {
          console.error(
            `Error\nCode: ${categoryFromApi.status}\nStatus: ${categoryFromApi.statusText}`
          );
          this.category = emptyCategory();
          res.isOk = false;
          res.msg = categoryFromApi.statusText || '';
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  addCategory = async (params: IAddCategory): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const categoryFromApi = await api.categories.addCategory(params);

      runInAction(() => {
        if (categoryFromApi.status === 201) {
          res.isOk = true;
          res.msg = 'Категория добавлена';
        } else {
          console.error(
            `Error\nCode: ${categoryFromApi.status}\nStatus: ${categoryFromApi.statusText}`
          );

          res.isOk = false;
          res.msg = categoryFromApi.name
            ? `Ошибка ${categoryFromApi.name}: ${categoryFromApi.message}`
            : `Ошибка ${categoryFromApi.status}: ${categoryFromApi.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  changePositions = async (
    id1: number,
    id2: number,
    category1: ICategory,
    category2: ICategory
  ): Promise<IError> => {
    const res = {
      isOk: false,
      msg: ''
    };

    try {
      await api.categories
        .editCategory(id1, {
          ...category1
        })
        .then(async () => {
          await api.categories
            .editCategory(id2, {
              ...category2
            })
            .then(() => {
              res.isOk = true;
              res.msg = 'Порядок изменён';
            })
            .catch((error) => {
              if (axios.isAxiosError(error)) {
                res.msg = `${error.message}:\n ${
                  error.response?.data.message || error.name
                }`;
              }
              res.isOk = false;

              console.error(error);
            });
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            res.msg = `${error.message}:\n ${
              error.response?.data.message || error.name
            }`;
          }

          this.loading = false;
          res.isOk = false;

          console.error(error);
        });
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  editCategory = async (id: number, category: ICategory) => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const productFromApi = await api.categories.editCategory(id, category);

      runInAction(() => {
        if (productFromApi.status === 200) {
          res.isOk = true;
          res.msg = 'Категория изменена';
        } else {
          console.error(
            `Error\nCode: ${productFromApi.status}\nStatus: ${productFromApi.statusText}`
          );

          res.isOk = false;
          res.msg = productFromApi.name
            ? `Ошибка ${productFromApi.name}: ${productFromApi.message}`
            : `Ошибка ${productFromApi.status}: ${productFromApi.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  updateCategory = async (
    id: number,
    params: IPartialCategoryItem
  ): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const categoryFromApi = await api.categories.patchCategory(id, params);

      runInAction(() => {
        if (categoryFromApi.status === 200) {
          res.isOk = true;
          res.msg = 'Категория изменена';
        } else {
          console.error(
            `Error\nCode: ${categoryFromApi.status}\nStatus: ${categoryFromApi.statusText}`
          );

          res.isOk = false;
          res.msg = categoryFromApi.name
            ? `Ошибка ${categoryFromApi.name}: ${categoryFromApi.message}`
            : `Ошибка ${categoryFromApi.status}: ${categoryFromApi.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  deleteCategory = async (id: number): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const categoryFromApi = await api.categories.deleteCategory(id);

      runInAction(() => {
        if (categoryFromApi.status === 200) {
          res.isOk = true;
          res.msg = 'Категория удалена';
        } else {
          console.error(
            `Error\nCode: ${categoryFromApi.status}\nStatus: ${categoryFromApi.statusText}`
          );

          res.isOk = false;
          res.msg = categoryFromApi.name
            ? `Ошибка ${categoryFromApi.name}: ${categoryFromApi.message}`
            : `Ошибка ${categoryFromApi.status}: ${categoryFromApi.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };
}

const categoriesStore = new CategoriesStore();
export default categoriesStore;

import { createTheme, responsiveFontSizes } from '@mui/material';

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#0283fd'
      },
      secondary: {
        main: '#f50057'
      },
      background: {
        default: '#071220',
        paper: '#222F3E'
      },
      text: {
        primary: '#eaeaea'
      }
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Oxygen Sans"',
        'Ubuntu',
        'Cantarell',
        '"Helvetica Neue"',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        'sans-serif'
      ].join(',')
    }
  })
);

export default theme;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { observable, action, makeObservable, runInAction } from 'mobx';

// API
import api from 'api';
import axios from 'axios';
import {
  ICharacteristicType,
  ICharacteristicTypeShort,
  ICharacteristicValue
} from 'types/types';

interface IError {
  isOk: boolean;
  msg: string;
  data?: ICharacteristicTypeShort;
}

interface IErrorWithTypes {
  isOk: boolean;
  msg: string;
  data: Array<ICharacteristicTypeShort>;
}

interface IErrorWithValues {
  isOk: boolean;
  msg: string;
  data: Array<ICharacteristicValue>;
}

class CharacteristicStore {
  @observable
  characteristicsLoading: boolean = false;

  @observable
  characteristic: unknown = {};

  @observable
  characteristicType: ICharacteristicType = {
    name: '',
    values: []
  };

  @observable
  characteristicTypes: Array<ICharacteristicTypeShort> = [];

  constructor() {
    makeObservable(this);
  }

  @action
  getCharacteristic = async (id: number): Promise<IError> => {
    this.characteristicsLoading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const dataFromAPI = await api.characteristics.getCharacteristic(id);

      runInAction(() => {
        if (dataFromAPI.status === 200) {
          res.isOk = true;
          res.msg = 'Характеристика загружена';

          this.characteristic = dataFromAPI.data;
        } else {
          console.error(
            `Error\nCode: ${dataFromAPI.status}\nStatus: ${dataFromAPI.statusText}`
          );

          res.isOk = false;
          res.msg = dataFromAPI.name
            ? `Ошибка ${dataFromAPI.name}: ${dataFromAPI.message}`
            : `Ошибка ${dataFromAPI.status}: ${dataFromAPI.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      res.isOk = false;
      console.error(error);
    } finally {
      runInAction(() => {
        this.characteristicsLoading = false;
      });
    }

    return res;
  };

  @action
  updateCharacteristic = async (id: number, data: any): Promise<IError> => {
    this.characteristicsLoading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const dataFromAPI = await api.characteristics.patchCharacteristic(
        id,
        data
      );

      runInAction(() => {
        if (dataFromAPI.status === 200) {
          res.isOk = true;
          res.msg = 'Характеристика обновлена';

          this.characteristic = dataFromAPI.data;
        } else {
          console.error(
            `Error\nCode: ${dataFromAPI.status}\nStatus: ${dataFromAPI.statusText}`
          );

          res.isOk = false;
          res.msg = dataFromAPI.name
            ? `Ошибка ${dataFromAPI.name}: ${dataFromAPI.message}`
            : `Ошибка ${dataFromAPI.status}: ${dataFromAPI.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      res.isOk = false;
      console.error(error);
    } finally {
      runInAction(() => {
        this.characteristicsLoading = false;
      });
    }

    return res;
  };

  @action
  postCharacteristic = async (data: any): Promise<IError> => {
    this.characteristicsLoading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const dataFromAPI = await api.characteristics.postCharacteristic(data);

      runInAction(() => {
        if (dataFromAPI.status === 201) {
          res.isOk = true;
          res.msg = 'Новое значение характеристики создано';

          this.characteristic = dataFromAPI.data;
        } else {
          console.error(
            `Error\nCode: ${dataFromAPI.status}\nStatus: ${dataFromAPI.statusText}`
          );

          res.isOk = false;
          res.msg = dataFromAPI.name
            ? `Ошибка ${dataFromAPI.name}: ${dataFromAPI.message}`
            : `Ошибка ${dataFromAPI.status}: ${dataFromAPI.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      res.isOk = false;
      console.error(error);
    } finally {
      runInAction(() => {
        this.characteristicsLoading = false;
      });
    }

    return res;
  };

  @action
  addCharacteristicType = async (
    data: ICharacteristicType
  ): Promise<IError> => {
    this.characteristicsLoading = true;

    const res = {
      isOk: false,
      msg: '',
      data: {
        name: ''
      }
    };

    try {
      const dataFromAPI = await api.characteristics.addCharacteristicType(data);

      runInAction(() => {
        if (dataFromAPI.status === 201) {
          res.isOk = true;
          res.msg = 'Тип характеристики добавлен';

          const newArr = [...this.characteristicTypes];
          newArr.push(dataFromAPI.data);

          this.characteristicTypes = [...newArr];

          res.data = dataFromAPI.data;
        } else {
          console.error(
            `Error\nCode: ${dataFromAPI.status}\nStatus: ${dataFromAPI.statusText}`
          );

          res.isOk = false;
          res.msg = dataFromAPI.name
            ? `Ошибка ${dataFromAPI.name}: ${dataFromAPI.message}`
            : `Ошибка ${dataFromAPI.status}: ${dataFromAPI.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      res.isOk = false;
      console.error(error);
    } finally {
      runInAction(() => {
        this.characteristicsLoading = false;
      });
    }

    return res;
  };

  @action
  getCharacteristicType = async (id: number): Promise<IErrorWithValues> => {
    this.characteristicsLoading = true;

    const res = {
      isOk: false,
      msg: '',
      data: [] as Array<ICharacteristicValue>
    };

    try {
      const dataFromAPI = await api.characteristics.getCharacteristicType(id);

      runInAction(() => {
        if (dataFromAPI.status === 200) {
          res.isOk = true;
          res.msg = 'Тип характеристики загружен';

          this.characteristicType = dataFromAPI.data;
          res.data = dataFromAPI.data.values;
        } else {
          console.error(
            `Error\nCode: ${dataFromAPI.status}\nStatus: ${dataFromAPI.statusText}`
          );

          res.isOk = false;
          res.msg = dataFromAPI.name
            ? `Ошибка ${dataFromAPI.name}: ${dataFromAPI.message}`
            : `Ошибка ${dataFromAPI.status}: ${dataFromAPI.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      res.isOk = false;
      console.error(error);
    } finally {
      runInAction(() => {
        this.characteristicsLoading = false;
      });
    }

    return res;
  };

  @action
  getCharacteristicTypes = async (): Promise<IErrorWithTypes> => {
    this.characteristicsLoading = true;

    const res = {
      isOk: false,
      msg: '',
      data: [] as Array<ICharacteristicTypeShort>
    };

    try {
      const dataFromAPI = await api.characteristics.getCharacteristicTypes();

      runInAction(() => {
        if (dataFromAPI.status === 200) {
          res.isOk = true;
          res.msg = 'Типы характеристик загружены';

          this.characteristicTypes = dataFromAPI.data;
          res.data = dataFromAPI.data;
        } else {
          console.error(
            `Error\nCode: ${dataFromAPI.status}\nStatus: ${dataFromAPI.statusText}`
          );

          res.isOk = false;
          res.msg = dataFromAPI.name
            ? `Ошибка ${dataFromAPI.name}: ${dataFromAPI.message}`
            : `Ошибка ${dataFromAPI.status}: ${dataFromAPI.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      res.isOk = false;
      console.error(error);
    } finally {
      runInAction(() => {
        this.characteristicsLoading = false;
      });
    }

    return res;
  };
}

const characteristicStore = new CharacteristicStore();
export default characteristicStore;

import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const MainPage: FC = () => {
  const navigate = useNavigate();
  navigate('/products');
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'primary'
      }}
    >
      Главная
    </Box>
  );
};

export default MainPage;

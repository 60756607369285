import React, { FC } from 'react';
import { CircularProgress, Box } from '@mui/material';

interface IProps {
  size?: number;
  width?: string;
}

const Loader: FC<IProps> = ({ size = 40, width = '100%' }) => {
  return (
    <Box
      sx={{
        width,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress size={size} />
    </Box>
  );
};

export default Loader;

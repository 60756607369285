import React, { FC, useEffect, useState, useCallback, MouseEvent } from 'react';
import {
  Box,
  Menu,
  Typography,
  Grid,
  Pagination,
  Stack,
  IconButton,
  Button,
  MenuItem
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FilterListIcon from '@mui/icons-material/FilterList';

import { observer } from 'mobx-react-lite';

import Loader from 'components/loader';
import Notification from 'components/notification';
import DraggableList from 'components/draggableList';
import AddProductModal from 'components/modals/addProduct';

import productsStore from 'store/productsStore';
import globalState from 'store/globalState';
import variationsStore from 'store/variationsStore';

import { EnumPath, IProduct } from 'types/types';

import { isPaginated } from 'utilities/functions';

interface IParams {
  page: number;
  paginate: boolean;
  perpage: number;
  orderBy?: string;
}

const Products: FC = () => {
  const { loading, products } = productsStore;

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const [notificationStatus, setNotificationStatus] = useState<
    'error' | 'success' | 'warning'
  >('error');
  const [notificationMsg, setNotificationMsg] = useState<string>('');

  const [params, setParams] = useState<IParams>({
    page: 1,
    perpage: 20,
    paginate: true,
    orderBy: 'sort_desc'
  });

  const [anchorMenuEl, setAnchorMenuEl] = useState<null | HTMLElement>(null);
  const orderingMenuOpen = Boolean(anchorMenuEl);
  const handleOrderButtonClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorMenuEl(event.currentTarget);
  };
  const handleOrderMenuClose = () => {
    setAnchorMenuEl(null);
  };

  const notifyUser = useCallback(
    (message: string, status: 'error' | 'success') => {
      setNotificationMsg(message);
      setNotificationStatus(status);
      setIsNotificationOpen(true);
    },
    [setNotificationMsg, setNotificationStatus, setIsNotificationOpen]
  );

  const getProducts = () => {
    const response = productsStore.getProducts(params);

    response.then((val) => {
      if (!val.isOk) {
        notifyUser(val.msg, 'error');
      }
    });
  };

  const onProductAdd = (name: string, price: number) => {
    const response = productsStore.addProduct({
      name,
      price
    });

    response.then((val) => {
      if (val.isOk) {
        const newProductId = val.data.id;
        const newProductName = val.data.name;
        const newProductPrice = val.data.price;

        const newResponse = variationsStore.addVariation(newProductId, {
          name: 'Базовая вариация',
          price: newProductPrice,
          is_base: true
        });

        newResponse.then((nVal) => {
          if (nVal.isOk) {
            notifyUser(val.msg, 'success');
          } else {
            notifyUser(nVal.msg, 'error');
          }
        });
      } else {
        notifyUser(val.msg, 'error');
      }

      getProducts();
    });
  };

  useEffect(() => {
    const response = productsStore.getProducts(params);

    response.then((val) => {
      if (!val.isOk) {
        notifyUser(val.msg, 'error');
      }
    });

    globalState.setNewPathname('Товары');
    globalState.setRefreshFunction(productsStore.getProducts, params);
  }, []);

  useEffect(() => {
    const response = productsStore.getProducts(params);
    response.then((val) => {
      if (!val.isOk) {
        notifyUser(val.msg, 'error');
      }
    });

    globalState.setRefreshFunction(productsStore.getProducts, params);
  }, [params]);

  return (
    <Grid
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'scroll'
      }}
      item
      xs={12}
      md={12}
    >
      <Notification
        open={isNotificationOpen}
        message={notificationMsg}
        onClose={() => {
          setIsNotificationOpen(false);
          setNotificationMsg('');
        }}
        severity={notificationStatus}
      />
      <AddProductModal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        onProductAdd={onProductAdd}
      />
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'primary',
              width: 'auto',
              height: '48px'
            }}
          >
            <Typography variant="h6" sx={{ pl: 2 }}>
              Контейнеры
            </Typography>

            <Stack flexDirection="row">
              <IconButton onClick={() => setModalIsOpen(true)}>
                <AddCircleOutlineIcon color="primary" />
              </IconButton>
              <Button variant="text" onClick={() => setModalIsOpen(true)}>
                Создать
              </Button>
            </Stack>
          </Box>
          <DraggableList
            list={isPaginated<IProduct>(products) ? products.data : products}
            isSortable
            pagesQuantity={
              isPaginated<IProduct>(products) ? products.last_page : 1
            }
            notifyUser={notifyUser}
            onReload={getProducts}
            path={EnumPath.products}
          />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              p: 1
            }}
          >
            <Pagination
              count={isPaginated<IProduct>(products) ? products.last_page : 1}
              onChange={(
                event: React.ChangeEvent<unknown>,
                pageNum: number
              ) => {
                setParams((value) => {
                  return {
                    ...value,
                    page: pageNum
                  };
                });
              }}
              page={params.page}
              shape="rounded"
            />
            <IconButton id="filter-button-id" onClick={handleOrderButtonClick}>
              <FilterListIcon />
            </IconButton>
            <Menu
              id="orderingMenuId"
              aria-labelledby="filter-button-id"
              anchorEl={anchorMenuEl}
              open={orderingMenuOpen}
              onClose={handleOrderMenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
            >
              <MenuItem
                onClick={() => {
                  setParams((val) => ({ ...val, orderBy: 'sort_asc' }));
                  setAnchorMenuEl(null);
                }}
              >
                По возрастанию
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setParams((val) => ({ ...val, orderBy: 'sort_desc' }));
                  setAnchorMenuEl(null);
                }}
              >
                По убыванию
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      )}
    </Grid>
  );
};

export default observer(Products);

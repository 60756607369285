import React, { FC, useState, useCallback, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  Stack,
  IconButton,
  Button
} from '@mui/material';
import { AddCircleOutlineOutlined } from '@mui/icons-material';

import Notification from 'components/notification';
import DraggableList from 'components/draggableList';
import Loader from 'components/loader';
import AddInfoSection from 'components/modals/addInfoSection';

import infoSectionStore from 'store/infoSectionsStore';
import { EnumPath } from 'types/types';
import { observer } from 'mobx-react-lite';
import globalState from 'store/globalState';

const InfoSections: FC = () => {
  const { infoSections, loading } = infoSectionStore;

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const [notificationStatus, setNotificationStatus] = useState<
    'error' | 'success' | 'warning'
  >('error');
  const [notificationMsg, setNotificationMsg] = useState<string>('');

  const notifyUser = useCallback(
    (message: string, status: 'error' | 'success') => {
      setNotificationMsg(message);
      setNotificationStatus(status);
      setIsNotificationOpen(true);
    },
    [setNotificationMsg, setNotificationStatus, setIsNotificationOpen]
  );

  const reload = useCallback(() => {
    const response = infoSectionStore.getAllInfoSections();

    response.then((val) => {
      if (!val.isOk) {
        notifyUser(val.msg, 'error');
      }
    });
  }, []);

  useEffect(() => {
    reload();

    globalState.setNewPathname('Инфосекции');
    globalState.setRefreshFunction(infoSectionStore.getAllInfoSections, {});
  }, []);

  const onInfoSectionAdd = (
    name: string,
    description: string,
    sectionTemplate: string,
    elementTemplate: string,
    slug: string
  ) => {
    const response = infoSectionStore.addInfoSection({
      name,
      description,
      slug,
      section_template: sectionTemplate,
      element_template: elementTemplate
    });

    response.then((val) => {
      notifyUser(val.msg, val.isOk ? 'success' : 'error');

      reload();
    });
  };

  return (
    <Grid
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'scroll'
      }}
      item
      xs={12}
      md={12}
    >
      <Notification
        open={isNotificationOpen}
        message={notificationMsg}
        onClose={() => {
          setIsNotificationOpen(false);
          setNotificationMsg('');
        }}
        severity={notificationStatus}
      />
      <AddInfoSection
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        onProductAdd={onInfoSectionAdd}
      />
      {!loading ? (
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'primary',
              width: 'auto',
              height: '48px'
            }}
          >
            <Typography variant="h6" sx={{ pl: 2 }}>
              Название
            </Typography>

            <Stack flexDirection="row">
              <IconButton onClick={() => setModalIsOpen(true)}>
                <AddCircleOutlineOutlined color="primary" />
              </IconButton>
              <Button variant="text" onClick={() => setModalIsOpen(true)}>
                Создать
              </Button>
            </Stack>
          </Box>
          <DraggableList
            list={infoSections}
            pagesQuantity={1}
            notifyUser={notifyUser}
            onReload={reload}
            path={EnumPath.infosections}
          />
        </Box>
      ) : (
        <Loader />
      )}
    </Grid>
  );
};

export default observer(InfoSections);

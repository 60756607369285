import React, { FC, useCallback, useState, ChangeEvent } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Modal,
  Stack,
  TextField,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
  isOpen: boolean;
  setIsOpen(val: boolean): void;
  onVariationAdd(val: string): void;
}

const AddVariation: FC<IProps> = ({ isOpen, setIsOpen, onVariationAdd }) => {
  const [name, setName] = useState<string>('');

  const handleModalClose = useCallback(() => {
    setName('');
    setIsOpen(false);
  }, []);

  const handleVariationAdd = useCallback(() => {
    onVariationAdd(name);
    setName('');
    setIsOpen(false);
  }, [name, onVariationAdd]);

  const onNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setName(event.currentTarget.value);
    },
    []
  );

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          color: 'text.primary',
          p: 1
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={handleModalClose}>
            <CloseIcon
              sx={(theme) => ({
                '&:hover': {
                  color: theme.palette.error.main
                }
              })}
            />
          </IconButton>
        </Box>
        <Box sx={{ p: 3, pt: 0 }}>
          <Typography variant="h6">Добавление вариации</Typography>
          <TextField
            fullWidth
            multiline
            error={!name}
            value={name}
            onChange={onNameChange}
            label="Название"
            placeholder="Название"
            helperText={!name && 'Введите название вариации'}
            variant="filled"
            sx={{ mt: '15px' }}
          />
          <Stack
            flexDirection="row"
            gap={2}
            sx={{ pt: 5, justifyContent: 'flex-end' }}
          >
            <Button
              size="small"
              variant="outlined"
              color="success"
              disabled={!name}
              onClick={handleVariationAdd}
            >
              Добавить
            </Button>
            <Button size="small" variant="outlined" onClick={handleModalClose}>
              Отменить
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddVariation;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { observable, action, makeObservable, runInAction } from 'mobx';

// API
import api from 'api';

// Types
import {
  IProps,
  IProduct,
  IPaginationResponse,
  ListType,
  IProductItem
} from 'types/types';
import axios from 'axios';

const emptyProduct = (): IProductItem => ({
  id: 0,
  name: '',
  priceInvalid: false,
  price: 0,
  sort: 0,
  categories: [],
  tags: [],
  seo_description: null,
  seo_title: null,
  slug: null,
  text_content: null,
  variations: [],
  created_at: new Date(),
  updated_at: new Date()
});

interface IError {
  isOk: boolean;
  msg: string;
}

interface IErrorData {
  isOk: boolean;
  msg: string;
  data: IProductItem;
}

interface IAddProduct {
  name: string;
  price: number;
}

class ProductsStore {
  @observable
  products: ListType<IProduct> | IPaginationResponse<IProduct> = [];

  @observable
  searchResults: ListType<IProduct> = [];

  @observable
  product: IProductItem = emptyProduct();

  @observable
  loading: boolean = true;

  @observable
  searchLoading: boolean = true;

  constructor() {
    makeObservable(this);
  }

  @action
  getProducts = async ({
    page,
    paginate,
    perpage,
    orderBy
  }: IProps): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const productsFromApi = await api.products.getProductsList(
        page,
        paginate,
        perpage,
        orderBy
      );

      runInAction(() => {
        if (productsFromApi.status === 200) {
          this.products = productsFromApi.data;

          res.isOk = true;
          res.msg = 'Товары получены';
        } else {
          console.error(
            `Error\nCode: ${productsFromApi.status}\nStatus: ${productsFromApi.statusText}`
          );

          res.isOk = false;
          res.msg = productsFromApi.statusText;
          this.products = [];
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  getProduct = async (id: number): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const productFromApi = await api.products.getProduct(id);

      runInAction(() => {
        if (productFromApi.status === 200) {
          this.product = productFromApi.data;

          res.isOk = true;
          res.msg = 'Товар получен';
        } else {
          console.error(
            `Error\nCode: ${productFromApi.status}\nStatus: ${productFromApi.statusText}`
          );
          this.product = emptyProduct();
          res.isOk = false;
          res.msg = productFromApi.statusText || '';
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  addProduct = async (params: IAddProduct): Promise<IErrorData> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: '',
      data: emptyProduct()
    };

    try {
      const productFromApi = await api.products.addProduct(params);

      runInAction(() => {
        if (productFromApi.status === 201) {
          res.isOk = true;
          res.msg = 'Товар добавлен';
          res.data = productFromApi.data;
        } else {
          console.error(
            `Error\nCode: ${productFromApi.status}\nStatus: ${productFromApi.statusText}`
          );

          res.isOk = false;
          res.msg = productFromApi.name
            ? `Ошибка ${productFromApi.name}: ${productFromApi.message}`
            : `Ошибка ${productFromApi.status}: ${productFromApi.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  getAllProducts = async (): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const productFromApi = await api.products.getAllProductsList();

      runInAction(() => {
        if (productFromApi.status === 200) {
          res.isOk = true;
          res.msg = 'Товары загружена';

          this.products = productFromApi.data;
        } else {
          console.error(
            `Error\nCode: ${productFromApi.status}\nStatus: ${productFromApi.statusText}`
          );

          res.isOk = false;
          res.msg = productFromApi.name
            ? `Ошибка ${productFromApi.name}: ${productFromApi.message}`
            : `Ошибка ${productFromApi.status}: ${productFromApi.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  searchProducts = async (query: string): Promise<IError> => {
    this.searchLoading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const productFromApi = await api.products.searchProducts(query);

      runInAction(() => {
        if (productFromApi.status === 200) {
          res.isOk = true;
          res.msg = 'Товары найдены';

          this.searchResults = productFromApi.data;
        } else {
          console.error(
            `Error\nCode: ${productFromApi.status}\nStatus: ${productFromApi.statusText}`
          );

          res.isOk = false;
          res.msg = productFromApi.name
            ? `Ошибка ${productFromApi.name}: ${productFromApi.message}`
            : `Ошибка ${productFromApi.status}: ${productFromApi.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.searchLoading = false;
      });
    }

    return res;
  };

  @action
  editProduct = async (id: number, product: IProductItem) => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const productFromApi = await api.products.editProduct(id, product);

      runInAction(() => {
        if (productFromApi.status === 200) {
          res.isOk = true;
          res.msg = 'Товар изменён';
        } else {
          console.error(
            `Error\nCode: ${productFromApi.status}\nStatus: ${productFromApi.statusText}`
          );

          res.isOk = false;
          res.msg = productFromApi.name
            ? `Ошибка ${productFromApi.name}: ${productFromApi.message}`
            : `Ошибка ${productFromApi.status}: ${productFromApi.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  deleteProduct = async (id: number): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const productFromApi = await api.products.deleteProduct(id);

      runInAction(() => {
        if (productFromApi.status === 200) {
          res.isOk = true;
          res.msg = 'Товар удалён';
        } else {
          console.error(
            `Error\nCode: ${productFromApi.status}\nStatus: ${productFromApi.statusText}`
          );

          res.isOk = false;
          res.msg = productFromApi.name
            ? `Ошибка ${productFromApi.name}: ${productFromApi.message}`
            : `Ошибка ${productFromApi.status}: ${productFromApi.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  changePositions = async (
    id1: number,
    id2: number,
    product1: IProduct,
    product2: IProduct
  ): Promise<IError> => {
    const res = {
      isOk: false,
      msg: ''
    };

    try {
      await api.products
        .editProduct(id1, {
          ...product1
        })
        .then(async () => {
          await api.products
            .editProduct(id2, {
              ...product2
            })
            .then(() => {
              res.isOk = true;
              res.msg = 'Порядок изменён';
            })
            .catch((error) => {
              if (axios.isAxiosError(error)) {
                res.msg = `${error.message}:\n ${
                  error.response?.data.message || error.name
                }`;
              }
              res.isOk = false;

              console.error(error);
            });
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            res.msg = `${error.message}:\n ${
              error.response?.data.message || error.name
            }`;
          }

          this.loading = false;
          res.isOk = false;

          console.error(error);
        });
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  deleteVariations = async (id: number) => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const productFromApi = await api.variations.deleteVariation(id);

      runInAction(() => {
        if (productFromApi.status === 200) {
          res.isOk = true;
          res.msg = 'Вариация удалена';
        } else {
          console.error(
            `Error\nCode: ${productFromApi.status}\nStatus: ${productFromApi.statusText}`
          );

          res.isOk = false;
          res.msg = productFromApi.name
            ? `Ошибка ${productFromApi.name}: ${productFromApi.message}`
            : `Ошибка ${productFromApi.status}: ${productFromApi.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  clearAll = () => {
    this.loading = true;
    this.products = [];
    this.product = emptyProduct();
  };
}

const productsStore = new ProductsStore();
export default productsStore;

import axios from 'api/helpers/axios';

import {
  IProduct,
  IProductItem,
  IPaginationResponse,
  ListType
} from 'types/types';
import { toSlug } from 'utilities/functions';

interface IProductsListResponse {
  data: IPaginationResponse<IProduct> | ListType<IProduct>;
  status: number;
  statusText: string;
}

interface IProductsListResponseWithoutPagination {
  data: ListType<IProduct>;
  status?: number;
  statusText?: string;
  message?: string;
  name?: string;
}

interface IProductResponse {
  data: IProductItem;
  status?: number;
  statusText?: string;
  message?: string;
  name?: string;
}

interface IAddProduct {
  name: string;
  price: number;
}

export default {
  async getProductsList(
    page?: number,
    paginate?: boolean,
    perpage?: number,
    orderBy = 'sort_desc'
  ): Promise<IProductsListResponse> {
    const response = await axios.get(`/products`, {
      params: { page, paginate, perpage, orderBy }
    });

    return response;
  },

  async getAllProductsList(): Promise<IProductsListResponseWithoutPagination> {
    const response = await axios.get(`/products`);

    return response;
  },

  async getProduct(productId: number): Promise<IProductResponse> {
    const response = await axios.get(`/products/${productId}`);

    return response;
  },

  async addProduct(data: IAddProduct): Promise<IProductResponse> {
    const response = await axios.post(`/products`, {
      ...data,
      slug: toSlug(data)
    });

    return response;
  },

  async editProduct(
    id: number,
    data: IProduct | IProductItem
  ): Promise<IProductResponse> {
    const variations = [...data.variations];

    for (let i = 0; i < variations.length; i += 1) {
      variations[i].slug = variations[i].slug || toSlug(variations[i]);
    }
    const response = await axios.patch(`/products/${id}`, {
      ...data,
      variations
    });

    return response;
  },

  async deleteProduct(id: number): Promise<IProductResponse> {
    const response = await axios.delete(`/products/${id}`);

    return response;
  },

  async searchProducts(
    query: string
  ): Promise<IProductsListResponseWithoutPagination> {
    const response = await axios.get(`/json_search_hints/${query}`);

    return response;
  }
};

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { Sanctum } from 'react-sanctum';

import environment from 'config/environments/environment';

import App from './App';

const sanctumConfig = {
  apiUrl: environment.baseUrl,
  csrfCookieRoute: 'sanctum/csrf-cookie',
  signInRoute: 'login',
  signOutRoute: 'logout',
  userObjectRoute: 'user'
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <Sanctum config={sanctumConfig}>
          <App />
        </Sanctum>
      </StyledEngineProvider>
    </BrowserRouter>
  </React.StrictMode>
);

import axios from 'api/helpers/axios';
import {
  ICharacteristicType,
  ICharacteristicTypeShort,
  ICharacteristicFull
} from 'types/types';

interface IVariationResponse<T> {
  data: T;
  status?: number;
  statusText?: string;
  message?: string;
  name?: string;
}

export default {
  async getCharacteristic(
    id: number
  ): Promise<IVariationResponse<Array<ICharacteristicType>>> {
    const response = await axios.get(`/characteristics/${id}`);

    return response;
  },

  async postCharacteristic(
    data: any
  ): Promise<IVariationResponse<ICharacteristicFull>> {
    const response = await axios.post(`/characteristics`, {
      ...data
    });

    return response;
  },

  async patchCharacteristic(
    id: number,
    data: any
  ): Promise<IVariationResponse<ICharacteristicFull>> {
    const response = await axios.patch(`/characteristics/${id}`, {
      ...data
    });

    return response;
  },

  async getCharacteristics(): Promise<IVariationResponse<Array<unknown>>> {
    const response = await axios.get('/characteristics');

    return response;
  },

  async getCharacteristicType(
    id: number
  ): Promise<IVariationResponse<ICharacteristicType>> {
    const response = await axios.get(`/characteristic_types/${id}`);

    return response;
  },

  async addCharacteristicType(
    data: ICharacteristicType
  ): Promise<IVariationResponse<ICharacteristicTypeShort>> {
    const response = await axios.post('/characteristic_types', {
      ...data
    });

    return response;
  },

  async getCharacteristicTypes(): Promise<
    IVariationResponse<Array<ICharacteristicTypeShort>>
  > {
    const response = await axios.get('/characteristic_types');

    return response;
  }
};

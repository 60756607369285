import axios from 'api/helpers/axios';

import { IProductItem, IVariation } from 'types/types';
import { toSlug } from 'utilities/functions';

interface IVariationResponse<T> {
  data: T;
  status?: number;
  statusText?: string;
  message?: string;
  name?: string;
}

export default {
  async deleteVariation(id: number): Promise<IVariationResponse<IProductItem>> {
    const response = await axios.delete(`/variations/${id}`);

    return response;
  },

  async addVariation(
    id: number,
    data: Partial<IVariation>
  ): Promise<IVariationResponse<IVariation>> {
    const response = await axios.post(`/variations`, {
      product_variation_container_id: id,
      ...data,
      slug: data.slug || toSlug(data)
    });

    return response;
  },

  async getVariation(id: number): Promise<IVariationResponse<IVariation>> {
    const response = await axios.get(`/variations/${id}`);

    return response;
  },

  async updateVariation(
    id: number,
    data: IVariation
  ): Promise<IVariationResponse<IVariation>> {
    const response = await axios.patch(`/variations/${id}`, {
      ...data,
      slug: data.slug || toSlug(data)
    });

    return response;
  },

  async applyPriceForVariation(
    id: number,
    price: number
  ): Promise<IVariationResponse<IVariation>> {
    const response = await axios.post(`/apply-price`, {
      base_variation_id: id,
      price
    });

    return response;
  },

  async applyDiscountPriceForVariation(
    id: number,
    discount_price: number
  ): Promise<IVariationResponse<IVariation>> {
    const response = await axios.post(`/apply-discount-price`, {
      base_variation_id: id,
      discount_price
    });

    return response;
  }
};

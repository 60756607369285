// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { observable, action, makeObservable, runInAction } from 'mobx';

// API
import api from 'api';
import axios from 'axios';
import { IInfoSectionAdd, IInfoSection } from 'types/types';

interface IError {
  isOk: boolean;
  msg: string;
}

const voidInfoSection = () => ({
  id: 0,
  name: '',
  description: '',
  section_template: '',
  element_template: '',
  seo_description: null,
  seo_title: null,
  slug: null,
  text_content: null
});

class InfoSectionStore {
  @observable
  loading: boolean = false;

  @observable
  infoSections: IInfoSection[] = [];

  @observable
  infoSection: IInfoSection = voidInfoSection();

  constructor() {
    makeObservable(this);
  }

  @action
  getAllInfoSections = async (): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const infoSections = await api.infoSections.getInfoSections();

      runInAction(() => {
        if (infoSections.status === 200) {
          this.infoSections = infoSections.data;

          res.isOk = true;
          res.msg = 'Инфосекции получены';
        } else {
          console.error(
            `Error\nCode: ${infoSections.status}\nStatus: ${infoSections.statusText}`
          );

          res.isOk = false;
          res.msg = infoSections.statusText || '';
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  getInfoSection = async (id: number): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const infoSection = await api.infoSections.getInfoSection(id);

      runInAction(() => {
        if (infoSection.status === 200) {
          this.infoSection = infoSection.data;

          res.isOk = true;
          res.msg = 'Инфосекция получена';
        } else {
          console.error(
            `Error\nCode: ${infoSection.status}\nStatus: ${infoSection.statusText}`
          );

          res.isOk = false;
          res.msg = infoSection.statusText || '';
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  addInfoSection = async (data: IInfoSectionAdd): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const infoSection = await api.infoSections.addInfoSection(data);

      runInAction(() => {
        if (infoSection.status === 201) {
          res.isOk = true;
          res.msg = 'Инфосекция добавлена';
        } else {
          console.error(
            `Error\nCode: ${infoSection.status}\nStatus: ${infoSection.statusText}`
          );

          res.isOk = false;
          res.msg = infoSection.statusText || '';
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  deleteInfoSection = async (id: number): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const infoSection = await api.infoSections.deleteInfoSection(id);

      runInAction(() => {
        if (infoSection.status === 200 || infoSection.status === 204) {
          res.isOk = true;
          res.msg = 'Инфосекция удалена';
        } else {
          console.error(
            `Error\nCode: ${infoSection.status}\nStatus: ${infoSection.statusText}`
          );
          res.isOk = false;
          res.msg = infoSection.statusText || '';
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  editInfoSection = async (
    id: number,
    data: IInfoSectionAdd
  ): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const infoSection = await api.infoSections.editInfoSection(id, data);

      runInAction(() => {
        if (infoSection.status === 200) {
          res.isOk = true;
          res.msg = 'Инфосекция изменена';

          const newSectionId = this.infoSections.findIndex(
            (el) => el.id === id
          );

          if (newSectionId !== -1) {
            this.infoSections[newSectionId] = infoSection.data;
          }
        } else {
          console.error(
            `Error\nCode: ${infoSection.status}\nStatus: ${infoSection.statusText}`
          );

          res.isOk = false;
          res.msg = infoSection.statusText || '';
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };
}

const infoSectionStore = new InfoSectionStore();
export default infoSectionStore;

import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  ChangeEvent
} from 'react';
import {
  ListItem,
  List,
  ListItemText,
  IconButton,
  ListItemButton,
  ListItemIcon,
  Modal,
  Button,
  Box,
  Stack,
  Checkbox,
  Typography,
  TextField
} from '@mui/material';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Close, FilterList } from '@mui/icons-material';
import GetAppIcon from '@mui/icons-material/GetApp';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import productsStore from 'store/productsStore';
import Loader from 'components/loader';
import { IProduct, IAddProduct } from 'types/types';

import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import { isPaginated } from 'utilities/functions';
import mediaStore from 'store/mediaStore';

interface IProps {
  open: boolean;
  notifyUser(
    msg: string,
    status: 'error' | 'success' | 'info' | 'warning'
  ): void;
  productsIncluded: (IProduct | IAddProduct)[];
  onClose(): void;
  onSave(newArr: IAddProduct[]): void;
}

interface IProductExtended extends IProduct {
  checked: boolean;
}

interface IFile {
  name: string;
  url: string;
  size: number;
  created_at: string;
}

const DownloadModal: FC<IProps> = ({
  open,
  productsIncluded,
  onClose,
  onSave,
  notifyUser
}) => {
  const { products, loading } = productsStore;
  const { feedLoading } = mediaStore;

  const [mainState, setMainState] = useState<number>(0);
  const [feedsList, setFeedsList] = useState<Array<IFile>>();

  const [filterQuery, setFilterQuery] = useState<string>('');
  const [lastSelectedInd, setLastSelectedInd] = useState<number | null>(null);
  const [productsList, setProductsList] = useState<Array<IProductExtended>>(
    (isPaginated<IProduct>(products) ? products.data : products).map((el) => {
      const checked =
        productsIncluded.findIndex((findEl) => findEl.id === el.id) !== -1;
      return { ...el, checked };
    })
  );
  const [staticProductList, setStaticProductList] = useState<
    Array<IProductExtended>
  >([]);

  useEffect(() => {
    productsList.forEach((elem) => {
      const foundEl = staticProductList.findIndex((el) => el.id === elem.id);
      if (foundEl === -1) {
        setStaticProductList((list) => {
          const newList = [...list];
          newList.push(elem);
          return newList;
        });
      } else {
        setStaticProductList((list) => {
          const newList = [...list];
          newList[foundEl] = elem;
          return newList;
        });
      }
    });
  }, [productsList]);

  const onCheck = useCallback(
    (ind: number) =>
      (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setProductsList((oldArr) => {
          const newArr = [...oldArr];
          newArr[ind].checked = checked;
          return newArr;
        });
      },
    [setProductsList]
  );

  const handleListItemClick =
    (index: number) =>
    (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!event?.shiftKey || lastSelectedInd === null) {
        setProductsList((list) => {
          const newListItems = [...list];
          newListItems[index].checked = !newListItems[index].checked;
          return newListItems;
        });
      } else {
        const firstIndex = Math.min(index, lastSelectedInd);
        const lastIndex = Math.max(index, lastSelectedInd);

        setProductsList((list) => {
          const newListItems = list.map((item, i) => {
            if (i >= firstIndex && i <= lastIndex && i !== lastSelectedInd) {
              return { ...item, checked: list[lastSelectedInd].checked };
            }
            return item;
          });
          return newListItems;
        });
      }
      setLastSelectedInd(index);
    };

  const filterProducts = (val: string) => {
    const newList = staticProductList.filter((el) => el.name.includes(val));
    setProductsList(newList);
  };

  const debouncedFilter = debounce(filterProducts, 500, { maxWait: 500 });

  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    debouncedFilter(e.target.value);
    setFilterQuery(e.target.value);
  };

  const renderRow = (props: ListChildComponentProps<unknown>) => {
    // eslint-disable-next-line react/prop-types
    const { index, style } = props;

    return (
      <ListItem
        style={style}
        key={productsList[index].id}
        component="div"
        disablePadding
      >
        <ListItemButton
          role={undefined}
          onClick={handleListItemClick(index)}
          dense
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={productsList[index].checked}
              onChange={onCheck(index)}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={productsList[index].name} />
        </ListItemButton>
      </ListItem>
    );
  };

  useEffect(() => {
    if (open) {
      const resp = productsStore.getAllProducts();

      resp.then((val) => {
        if (!val.isOk) {
          notifyUser(val.msg, 'error');
        }
      });
    }
  }, [open]);

  useEffect(() => {
    setProductsList(
      (isPaginated<IProduct>(products) ? products.data : products).map((el) => {
        const checked =
          productsIncluded.findIndex((findEl) => findEl.id === el.id) !== -1;
        return { ...el, checked };
      })
    );
  }, [products]);

  const onGenerateClick = useCallback(() => {
    const saveContainers = staticProductList
      .filter((el) => el.checked)
      .map((el) => ({ ...el, checked: undefined }));
    const res = mediaStore.generatePinterestFeed(saveContainers);
    res.then((val) => {
      if (!val.isOk) {
        notifyUser(val.msg, 'error');
      } else {
        notifyUser(val.msg, 'success');

        const feedsListRes = mediaStore.getAllFeeds();

        feedsListRes.then((newVal) => {
          setFeedsList(newVal.data);
        });
        setMainState(1);
      }
    });
  }, [staticProductList, products, setProductsList, productsIncluded]);

  const downloadFile = useCallback(
    (filename: string) => () => {
      const res = mediaStore.downloadFile(filename);

      res.then((val) => {
        if (!val.isOk) {
          notifyUser(val.msg, 'error');
        } else {
          const url = URL.createObjectURL(new Blob([val.data]));

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);

          link.click();

          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }
      });
    },
    []
  );

  const moveToFiles = useCallback(() => {
    const feedsListRes = mediaStore.getAllFeeds();

    feedsListRes.then((newVal) => {
      setFeedsList(newVal.data);
    });

    setMainState(1);
  }, []);

  const deleteFile = useCallback(
    (filename: string) => () => {
      const res = mediaStore.deleteFile(filename);

      res.then((val) => {
        if (!val.isOk) {
          notifyUser(val.msg, 'error');
        } else {
          notifyUser('Файл успешно удалён', 'success');
          const feedsListRes = mediaStore.getAllFeeds();

          feedsListRes.then((newVal) => {
            setFeedsList(newVal.data);
          });
        }
      });
    },
    []
  );

  const copyToClipboard = useCallback(
    (text: string) => async () => {
      try {
        await navigator.clipboard.writeText(text);
        notifyUser('Ссылка на фид успешно скопирована', 'success');
      } catch (error) {
        notifyUser(JSON.stringify(error), 'error');
      }
    },
    []
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ overflowY: 'scroll', borderRadius: '8px' }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          color: 'text.primary',
          borderRadius: '8px',
          p: 2
        }}
      >
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" sx={{ pl: 2 }}>
            {!mainState
              ? 'Выберите необходимые контейнеры для выгрузки'
              : 'Скачайте сгенерированный фид'}
          </Typography>
          <IconButton onClick={onClose}>
            <Close
              sx={(theme) => ({
                '&:hover': {
                  color: theme.palette.error.main
                }
              })}
            />
          </IconButton>
        </Stack>

        {!mainState ? (
          <>
            <Stack
              display="flex"
              flexDirection="row"
              alignItems="flex-end"
              sx={{ gap: '35px', pl: '12px', pt: 2, pb: 3 }}
            >
              <FilterList sx={{ mb: '5px' }} />
              <TextField
                fullWidth
                value={filterQuery}
                onChange={handleChange}
                label="Фильтрация по названию"
                variant="standard"
                size="small"
              />
            </Stack>

            {!loading ? (
              <FixedSizeList
                itemSize={46}
                height={500}
                width={500}
                itemCount={productsList.length}
                overscanCount={5}
                style={{ width: '100%', backgroundColor: 'background.paper' }}
              >
                {renderRow}
              </FixedSizeList>
            ) : (
              <Loader />
            )}
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              pt="20px"
              gap="10px"
            >
              <Button size="small" variant="text" onClick={moveToFiles}>
                К файлам
              </Button>
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                align-items="center"
                gap="10px"
              >
                {feedLoading ? <Loader size={20} width="min-content" /> : null}
                <Button
                  size="small"
                  variant="outlined"
                  disabled={feedLoading}
                  onClick={onGenerateClick}
                >
                  Сгенерировать
                </Button>
              </Stack>
            </Stack>
          </>
        ) : (
          <>
            {!loading ? (
              <>
                <List sx={{ height: '500px', overflow: 'auto' }}>
                  {feedsList &&
                    feedsList.map((file) => (
                      <ListItem key={file.name} sx={{ gap: '15px' }}>
                        <ListItemIcon sx={{ gap: '15px' }}>
                          <IconButton
                            edge="start"
                            onClick={downloadFile(file.name)}
                          >
                            <GetAppIcon />
                          </IconButton>
                          <IconButton
                            edge="start"
                            onClick={copyToClipboard(
                              `https://evrokovrolin.ru/files/feeds/${file.name}`
                            )}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </ListItemIcon>
                        <ListItemText
                          primary={file.name}
                          secondary={`Размер: ${
                            Math.floor(file.size / 1024) > 1000
                              ? `${Math.floor(
                                  file.size / 1024 / 1024
                                )} Мбайт, сгенерировано ${file.created_at}`
                              : `${Math.floor(
                                  file.size / 1024
                                )} Кбайт, сгенерировано ${file.created_at}`
                          }`}
                        />
                        <IconButton
                          edge="start"
                          onClick={deleteFile(file.name)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </ListItem>
                    ))}
                </List>
                <Stack
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  gap="10px"
                >
                  <Button
                    size="small"
                    variant="text"
                    onClick={() => {
                      setMainState(0);
                    }}
                  >
                    Назад
                  </Button>
                </Stack>
              </>
            ) : (
              <Loader />
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default observer(DownloadModal);

import React, { FC, useCallback, useState, ChangeEvent } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Modal,
  Stack,
  TextField,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
  isOpen: boolean;
  setIsOpen(val: boolean): void;
  onProductAdd(
    name: string,
    description: string,
    sectionTemplate: string,
    elementTemplate: string,
    slug: string
  ): void;
}

const AddInfoSection: FC<IProps> = ({ isOpen, setIsOpen, onProductAdd }) => {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [sectionTemplate, setSectionTemplate] = useState<string>('');
  const [elementTemplate, setElementTemplate] = useState<string>('');
  const [slug, setSlug] = useState<string>('');

  const handleModalClose = useCallback(() => {
    setName('');
    setDescription('');
    setSectionTemplate('');
    setElementTemplate('');
    setSlug('');
    setIsOpen(false);
  }, []);

  const handleProductAdd = useCallback(() => {
    onProductAdd(name, description, sectionTemplate, elementTemplate, slug);
    setName('');
    setDescription('');
    setSectionTemplate('');
    setElementTemplate('');
    setSlug('');
    setIsOpen(false);
  }, [name, description, sectionTemplate, elementTemplate, slug, onProductAdd]);

  const onNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setName(event.currentTarget.value);
    },
    []
  );

  const onDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setDescription(event.currentTarget.value);
    },
    []
  );

  const onSectionTemplateChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSectionTemplate(event.currentTarget.value);
    },
    []
  );

  const onElementTemplateChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setElementTemplate(event.currentTarget.value);
    },
    []
  );

  const onSlugChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSlug(event.currentTarget.value);
    },
    []
  );

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          color: 'text.primary',
          p: 1
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={handleModalClose}>
            <CloseIcon
              sx={(theme) => ({
                '&:hover': {
                  color: theme.palette.error.main
                }
              })}
            />
          </IconButton>
        </Box>
        <Box sx={{ p: 3, pt: 0 }}>
          <Typography variant="h6">Добавление инфосекции</Typography>
          <TextField
            fullWidth
            multiline
            error={!name}
            value={name}
            onChange={onNameChange}
            label="Название"
            helperText={!name && 'Введите название инфосекции'}
            variant="filled"
            sx={{ mt: '15px' }}
          />
          <TextField
            fullWidth
            multiline
            error={!description}
            value={description}
            onChange={onDescriptionChange}
            label="Описание"
            helperText={!description && 'Введите описание'}
            variant="filled"
            sx={{ mt: '15px' }}
          />
          <TextField
            fullWidth
            multiline
            error={!sectionTemplate}
            value={sectionTemplate}
            onChange={onSectionTemplateChange}
            label="Шаблон секции"
            helperText={!sectionTemplate && 'Введите шаблон секции'}
            variant="filled"
            sx={{ mt: '15px' }}
          />
          <TextField
            fullWidth
            multiline
            error={!elementTemplate}
            value={elementTemplate}
            onChange={onElementTemplateChange}
            label="Шаблон страницы"
            helperText={!elementTemplate && 'Введите шаблон страницы'}
            variant="filled"
            sx={{ mt: '15px' }}
          />
          <TextField
            fullWidth
            multiline
            error={!slug}
            value={slug}
            onChange={onSlugChange}
            label="Слаг"
            helperText={!slug && 'Введите название в ссылке'}
            variant="filled"
            sx={{ mt: '15px' }}
          />
          <Stack
            flexDirection="row"
            gap={2}
            sx={{ pt: 5, justifyContent: 'flex-end' }}
          >
            <Button
              size="small"
              variant="outlined"
              color="success"
              disabled={!name}
              onClick={handleProductAdd}
            >
              Добавить
            </Button>
            <Button size="small" variant="outlined" onClick={handleModalClose}>
              Отменить
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddInfoSection;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { observable, action, makeObservable, runInAction } from 'mobx';

// API
import api from 'api';
import axios from 'axios';
import { IInfoPage } from 'types/types';

interface IError {
  isOk: boolean;
  msg: string;
}

const voidInfoPage = () => ({
  id: 0,
  name: '',
  section_id: 0,
  description: '',
  section_template: '',
  element_template: '',
  seo_description: null,
  seo_title: null,
  slug: null,
  text_content: null
});

class InfoPagesStore {
  @observable
  loading: boolean = false;

  @observable
  infoPages: Array<IInfoPage> = [];

  @observable
  infoPage: IInfoPage = voidInfoPage();

  constructor() {
    makeObservable(this);
  }

  @action
  getAllInfoPages = async (): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const infoPages = await api.infoPages.getInfoPages();

      runInAction(() => {
        if (infoPages.status === 200) {
          this.infoPages = infoPages.data;

          res.isOk = true;
          res.msg = 'Инфостраницы получены';
        } else {
          console.error(
            `Error\nCode: ${infoPages.status}\nStatus: ${infoPages.statusText}`
          );

          res.isOk = false;
          res.msg = infoPages.statusText || '';
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  getInfoPagesBySectionId = async (id: number): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const infoPages = await api.infoPages.getInfoPages();

      runInAction(() => {
        if (infoPages.status === 200) {
          this.infoPages = infoPages.data;

          this.infoPages = this.infoPages.filter((el) => el.section_id === id);

          res.isOk = true;
          res.msg = 'Инфостраницы получены';
        } else {
          console.error(
            `Error\nCode: ${infoPages.status}\nStatus: ${infoPages.statusText}`
          );

          res.isOk = false;
          res.msg = infoPages.statusText || '';
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  getInfoPage = async (id: number): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const infoPage = await api.infoPages.getInfoPage(id);

      runInAction(() => {
        if (infoPage.status === 200) {
          this.infoPage = infoPage.data;

          res.isOk = true;
          res.msg = 'Инфостраница получены';
        } else {
          console.error(
            `Error\nCode: ${infoPage.status}\nStatus: ${infoPage.statusText}`
          );

          res.isOk = false;
          res.msg = infoPage.statusText || '';
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  addInfoPage = async (data: any): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const infoPage = await api.infoPages.addInfoPage(data);

      runInAction(() => {
        if (infoPage.status === 201) {
          res.isOk = true;
          res.msg = 'Инфостраница добавлена';
        } else {
          console.error(
            `Error\nCode: ${infoPage.status}\nStatus: ${infoPage.statusText}`
          );

          res.isOk = false;
          res.msg = infoPage.statusText || '';
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  editInfoPage = async (id: number, data: any): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const infoPage = await api.infoPages.editInfoPage(id, data);

      runInAction(() => {
        if (infoPage.status === 200) {
          res.isOk = true;
          res.msg = 'Инфостраница изменена';
        } else {
          console.error(
            `Error\nCode: ${infoPage.status}\nStatus: ${infoPage.statusText}`
          );

          res.isOk = false;
          res.msg = infoPage.statusText || '';
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  deleteInfoPage = async (id: number): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const infoPage = await api.infoPages.deleteInfoPage(id);

      runInAction(() => {
        if (infoPage.status === 200 || infoPage.status === 204) {
          res.isOk = true;
          res.msg = 'Инфостраница удалена';
        } else {
          console.error(
            `Error\nCode: ${infoPage.status}\nStatus: ${infoPage.statusText}`
          );
          res.isOk = false;
          res.msg = infoPage.statusText || '';
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };
}

const infoPagesStore = new InfoPagesStore();
export default infoPagesStore;

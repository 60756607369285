import React, {
  FC,
  useState,
  useCallback,
  useEffect,
  useRef,
  SyntheticEvent,
  MouseEvent
} from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Box,
  InputAdornment,
  Autocomplete,
  TextField
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import CachedIcon from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { useSanctum } from 'react-sanctum';
import { observer } from 'mobx-react-lite';

import globalState from 'store/globalState';
import { debounce } from 'lodash';
import productsStore from 'store/productsStore';

const TitleBar: FC = () => {
  const { user, signOut, authenticated } = useSanctum();

  const ref = useRef<HTMLDivElement>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');

  const { refreshFunction, parameters, pathnameRu } = globalState;
  const { searchResults, loading } = productsStore;

  const navigate = useNavigate();

  useEffect(() => {
    setTitle(pathnameRu);
  }, [pathnameRu]);

  const handleMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onClick = useCallback(() => {
    if (refreshFunction) {
      refreshFunction(parameters);
    }
  }, [refreshFunction, parameters]);

  const onSearch = (val: string) => {
    if (val !== '') productsStore.searchProducts(val);
  };

  const debouncedSearch = useRef(
    debounce(onSearch, 1000, { maxWait: 1000 })
  ).current;

  const handleChange = (
    _event: SyntheticEvent<Element, Event>,
    value: string
  ) => {
    debouncedSearch(value);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '64px',
        width: '100%'
      }}
    >
      <AppBar position="static" ref={ref} color="primary">
        <Toolbar sx={{ boxShadow: 5 }}>
          <Box
            sx={{ display: 'flex', alignItems: 'center', gap: 2, flexGrow: 1 }}
          >
            <Typography variant="h6">
              Евроковролин - Панель администратора {title ? `- ${title}` : null}
            </Typography>
            <IconButton onClick={onClick}>
              <CachedIcon />
            </IconButton>
          </Box>
          {authenticated && (
            <Autocomplete
              id="search"
              sx={{ width: 300 }}
              size="small"
              open={searchOpen}
              onOpen={() => {
                setSearchOpen(true);
              }}
              onClose={() => {
                setSearchOpen(false);
              }}
              onChange={(_e, val) => {
                if (val) {
                  navigate(`/products/${val.id}`);
                }
              }}
              onInputChange={handleChange}
              getOptionLabel={(option) => option.name || option.id.toString()}
              noOptionsText="Не найдено"
              loadingText="Загрузка..."
              options={searchResults}
              filterOptions={(x) => x}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  type="search"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: null,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                  placeholder="Поиск по контейнерам"
                />
              )}
            />
          )}
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          {user?.name && <Typography>{user.name || ''}</Typography>}
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {user?.id && (
              <MenuItem
                onClick={() => {
                  signOut();
                }}
              >
                Выйти
              </MenuItem>
            )}
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default observer(TitleBar);

import React, { FC, useCallback, FormEvent, useState } from 'react';
import {
  Box,
  Avatar,
  Typography,
  TextField,
  Button,
  Checkbox,
  Stack
} from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { useSanctum } from 'react-sanctum';
import Notification from 'components/notification';

const Authorization: FC = () => {
  const { signIn } = useSanctum();

  const [checked, setChecked] = useState<boolean>(false);

  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const [notificationStatus, setNotificationStatus] = useState<
    'error' | 'success' | 'info' | 'warning'
  >('error');
  const [notificationMsg, setNotificationMsg] = useState<string>('');

  const handleChange = () => {
    setChecked((val) => !val);
  };

  const notifyUser = useCallback(
    (message: string, status: 'error' | 'success' | 'info' | 'warning') => {
      setNotificationMsg(message);
      setNotificationStatus(status);
      setIsNotificationOpen(true);
    },
    [setNotificationMsg, setNotificationStatus, setIsNotificationOpen]
  );

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const login = data.get('login')?.toString();
      const password = data.get('password')?.toString();

      signIn(login || '', password || '', checked)
        .then(() => notifyUser('Вы успешно зашли в Админ-панель!', 'success'))
        .catch(() => notifyUser('Неправильный логин или пароль', 'error'));
    },
    [checked]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'secondary'
      }}
    >
      <Notification
        open={isNotificationOpen}
        message={notificationMsg}
        onClose={() => {
          setIsNotificationOpen(false);
          setNotificationMsg('');
        }}
        severity={notificationStatus}
      />
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        Вход
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="login"
          label="Логин"
          name="login"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Пароль"
          type="password"
          id="password"
        />
        <Stack display="flex" flexDirection="row" alignItems="center">
          <Checkbox checked={checked} onChange={handleChange} color="primary" />
          <Typography>Запомнить меня</Typography>
        </Stack>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Войти
        </Button>
        {/* <Grid container>
          <Grid item xs>
            <Link href="/" variant="body2">
              Забыли пароль?
            </Link>
          </Grid>
          <Grid item>
            <Link href="/" variant="body2">
              Зарегистрироваться
            </Link>
          </Grid>
        </Grid> */}
      </Box>
    </Box>
  );
};

export default Authorization;

import axios from 'api/helpers/axios';

import {
  ITag,
  ITagItem,
  IPaginationResponse,
  ListType,
  IPartialTagItem
} from 'types/types';
import { toSlug } from 'utilities/functions';

interface ITagsListResponse {
  data: IPaginationResponse<ITag> | ListType<ITag>;
  status: number;
  statusText: string;
}

interface ITagResponse {
  data: ITagItem;
  status?: number;
  statusText?: string;
  message?: string;
  name?: string;
}

interface IAddTag {
  name: string;
}

interface IEditMainCategory {
  main_category_id: number;
}

export default {
  async getTagsList(
    page?: number,
    paginate?: boolean,
    perpage?: number,
    wherein?: string
  ): Promise<ITagsListResponse> {
    const response = await axios.get('/tags', {
      params: wherein ? { wherein } : { page, paginate, perpage }
    });

    return response;
  },

  async getTag(tagId: number): Promise<ITagResponse> {
    const response = await axios.get(`/tags/${tagId}`);

    return response;
  },

  async addTag(data: IAddTag): Promise<ITagResponse> {
    const response = await axios.post('/tags', {
      ...data,
      slug: toSlug(data)
    });

    return response;
  },

  async patchTag(id: number, data: IPartialTagItem): Promise<ITagResponse> {
    const response = await axios.patch(`/tags/${id}`, {
      ...data,
      slug: data.slug || toSlug(data)
    });

    return response;
  },

  async deleteTag(id: number): Promise<ITagResponse> {
    const response = await axios.delete(`/tags/${id}`);

    return response;
  },

  async changeMainCategory(
    id: number,
    data: IEditMainCategory
  ): Promise<ITagResponse> {
    const response = await axios.post(`/tags/${id}/main-category`, {
      ...data
    });

    return response;
  }
};

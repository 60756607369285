import axios from 'api/helpers/axios';
import { IInfoSection, IInfoSectionAdd } from 'types/types';
import { toSlug } from 'utilities/functions';

interface IResponse<T> {
  data: T;
  status?: number;
  statusText?: string;
  message?: string;
  name?: string;
}

export default {
  async getInfoSections(): Promise<IResponse<Array<IInfoSection>>> {
    const response = await axios.get('/info_sections');

    return response;
  },

  async getInfoSection(id: number): Promise<IResponse<IInfoSection>> {
    const response = await axios.get(`/info_sections/${id}`);

    return response;
  },

  async deleteInfoSection(id: number): Promise<IResponse<unknown>> {
    const response = await axios.delete(`/info_sections/${id}`);

    return response;
  },

  async addInfoSection(data: IInfoSectionAdd): Promise<IResponse<unknown>> {
    const response = await axios.post(`/info_sections`, {
      ...data,
      slug: data.slug || toSlug(data)
    });

    return response;
  },

  async editInfoSection(
    id: number,
    data: IInfoSectionAdd
  ): Promise<IResponse<IInfoSection>> {
    const response = await axios.patch(`/info_sections/${id}`, {
      ...data,
      slug: data.slug || toSlug(data)
    });

    return response;
  }
};

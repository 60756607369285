import React, { FC, useCallback, memo, useState, KeyboardEvent } from 'react';
import { Menu, TextField, InputAdornment, IconButton } from '@mui/material';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

interface IProps {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl(v: HTMLButtonElement | null): void;
  onPageClick(pageNumber: number): void;
  pagesQuantity: number;
}

const MoveToPageMenu: FC<IProps> = ({
  anchorEl,
  setAnchorEl,
  onPageClick,
  pagesQuantity
}) => {
  const [valueInput, setValueInput] = useState<number>(1);
  const [error, setError] = useState<string>('');

  const handlePageClick = useCallback(() => {
    setAnchorEl(null);
    onPageClick(valueInput);
  }, [setAnchorEl, valueInput, onPageClick]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <Menu
      id="menu-edit-delete"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      elevation={1}
      transitionDuration={{ enter: 0, exit: 0 }}
    >
      <TextField
        error={!!error}
        hiddenLabel
        variant="filled"
        InputProps={{
          inputMode: 'numeric',
          style: { paddingRight: 0 },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => {
                  if (valueInput <= pagesQuantity) {
                    handlePageClick();
                  } else {
                    setError(`Номер страицы < ${pagesQuantity}`);
                  }
                }}
              >
                <KeyboardReturnIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
        type="tel"
        value={valueInput}
        onChange={(e) => {
          setError('');
          setValueInput(parseInt(e.currentTarget.value || '1', 10));
        }}
        onKeyUp={(e: KeyboardEvent<HTMLDivElement>) => {
          if (e.code === 'Enter') {
            if (valueInput <= pagesQuantity) {
              handlePageClick();
            } else {
              setError(`Номер страицы < ${pagesQuantity}`);
            }
          }
        }}
        size="small"
        sx={{ width: '200px' }}
      />
    </Menu>
  );
};

export default memo(MoveToPageMenu);

import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  ChangeEvent
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Chip,
  Typography,
  TextField,
  IconButton,
  Button,
  Tabs,
  Tab,
  Stack,
  Switch,
  Checkbox
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import Editor from 'components/editor/editor';

import Loader from 'components/loader';
import Notification from 'components/notification';
import AddCategoriesFromList from 'components/modals/addCategoriesFromList';
import AddTagsFromList from 'components/modals/addTagsFromList';
import ProductCard from 'components/productCard';
import AddVariation from 'components/modals/addVariation/AddVariation';
import DialogModal from 'components/dialogModal';
import EditVariationModal from 'components/modals/editVariation';

import { observer } from 'mobx-react-lite';

import productsStore from 'store/productsStore';
import globalState from 'store/globalState';
import characteristicStore from 'store/characteristicsStore';

import { ICategory, IProductVariation, ITag } from 'types/types';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface IProductModal {
  open: boolean;
  title: string;
  text: string;
  button1Text: string;
  button1Type?:
    | 'error'
    | 'info'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning';
  button2Text: string;
  button2Type?:
    | 'error'
    | 'info'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning';
  onClose(): void;
  on1ButtonClick(): void;
  on2ButtonClick(): void;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexDirection: 'column',
            pt: 3
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const label = {
  inputProps: {
    'aria-label': 'Будет ли у вариация пометка "Цена не актуальна"'
  }
};

const Product: FC = () => {
  const { id } = useParams();
  const { product, loading } = productsStore;

  const [tabPanel, setTabPanel] = useState<number>(0);
  const [fieldsChanged, setFieldsChanged] = useState<boolean>(false);

  const [priceInvalid, setPriceInvalid] = useState<boolean>(
    product.priceInvalid || false
  );

  const [switchChecked, setSwitchChecked] = useState<boolean>(
    !product.text_content?.includes('<!-- widget -->')
  );

  const [categoriesModal, setCategoriesModal] = useState<boolean>(false);
  const [tagsModal, setTagsModal] = useState<boolean>(false);
  const [addVariationModal, setAddVariationModal] = useState<boolean>(false);

  const [editVariationModal, setEditVariationModal] = useState<boolean>(false);
  const [unsavedEls, setUnsavedEls] = useState<Set<number>>(new Set<number>());
  const [variationInd, setVariationInd] = useState<number>(0);

  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const [notificationStatus, setNotificationStatus] = useState<
    'error' | 'success' | 'info' | 'warning'
  >('error');
  const [notificationMsg, setNotificationMsg] = useState<string>('');

  const [markup, setMarkup] = useState<string | null>('');
  const [name, setName] = useState<string>(product.name);
  const [price, setPrice] = useState<number>(product.price);
  // const [discountPrice, setDiscountPrice] = useState<number | null>(
  //   product.price
  // );
  const [categories, setCategories] = useState<Array<ICategory>>(
    product.categories
  );
  const [tags, setTags] = useState<Array<ITag>>(product.tags);
  const [variations, setVariations] = useState<Array<IProductVariation>>(
    product.variations
  );
  const [seoDescription, setSeoDescription] = useState<string | null>(
    product.seo_description || ''
  );
  const [seoTitle, setSeoTitle] = useState<string | null>(
    product.seo_title || ''
  );
  const [seoSlug, setSeoSlug] = useState<string | null>(product.slug || '');
  const [productModal, setProductModal] = useState<IProductModal>({
    open: false,
    title: '',
    text: '',
    button1Text: '',
    button1Type: undefined,
    button2Text: '',
    button2Type: undefined,
    onClose: () => {},
    on1ButtonClick: () => {},
    on2ButtonClick: () => {}
  });

  const navigate = useNavigate();

  const notifyUser = useCallback(
    (message: string, status: 'error' | 'success' | 'info' | 'warning') => {
      setNotificationMsg(message);
      setNotificationStatus(status);
      setIsNotificationOpen(true);
    },
    [setNotificationMsg, setNotificationStatus, setIsNotificationOpen]
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabPanel(newValue);
  };

  const onChipClick = useCallback(
    (elemId: number, path: string) => () => {
      navigate(`/${path}/${elemId}`);
    },
    [navigate]
  );

  useEffect(() => {
    globalState.setNewPathname('Контейнер');

    if (id) {
      const response = productsStore.getProduct(parseInt(id, 10));

      response.then((val) => {
        if (!val.isOk) {
          setNotificationMsg(val.msg);
          setIsNotificationOpen(true);
        }
      });

      globalState.setRefreshFunction(
        productsStore.getProduct,
        parseInt(id, 10)
      );
    } else {
      globalState.setRefreshFunction(productsStore.getProduct, 0);
    }
  }, [id]);

  useEffect(() => {
    setName(product.name);
    setPriceInvalid(product.priceInvalid || false);
    setPrice(product.price);
    setCategories(product.categories);
    setTags(product.tags);
    setVariations(product.variations);
    setSeoDescription(product.seo_description || '');
    setSeoTitle(product.seo_title || '');
    setUnsavedEls(new Set<number>());
    setSeoSlug(product.slug || '');
    setSwitchChecked(!product.text_content?.includes('<!-- widget -->'));
  }, [product]);

  useEffect(() => {
    if (tabPanel === 3) {
      characteristicStore.getCharacteristicTypes();
    }
  }, [tabPanel]);

  useEffect(() => {
    setMarkup(product.text_content || '');
  }, [product.text_content]);

  const handleCategoriesModalOpen = useCallback(() => {
    setCategoriesModal(true);
  }, []);

  const handleTagsModalOpen = useCallback(() => {
    setTagsModal(true);
  }, []);

  const onCategoryDelete = useCallback(
    (ind: number) => () => {
      setFieldsChanged(true);
      setCategories((oldArr) => {
        const newArr = [...oldArr];
        newArr.splice(ind, 1);
        return newArr;
      });
    },
    []
  );

  const onCategoriesChange = useCallback((list: Array<ICategory>) => {
    setFieldsChanged(true);
    setCategories(list);
  }, []);

  const onTagDelete = useCallback(
    (ind: number) => () => {
      setFieldsChanged(true);
      setTags((oldArr) => {
        const newArr = [...oldArr];
        newArr.splice(ind, 1);
        return newArr;
      });
    },
    []
  );

  const onTagsChange = useCallback((list: Array<ICategory>) => {
    setFieldsChanged(true);
    setTags(list);
  }, []);

  const onNameChange = useCallback(
    (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFieldsChanged(true);
      setName(value.currentTarget.value);
    },
    []
  );

  const onPriceChange = useCallback(
    (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFieldsChanged(true);
      setPrice(parseFloat(value.currentTarget.value || '0'));
      // setDiscountPrice(parseFloat(value.currentTarget.value || '0'));
    },
    []
  );

  const onVariationAdd = useCallback(
    (newName: string) => {
      setFieldsChanged(true);

      setVariations((oldData) => {
        const newArr = [...oldData];
        newArr.push({
          name: newName,
          price,
          sku: '',
          product_variation_container_id: product.id
        });
        return newArr;
      });
    },
    [product.id, price, unsavedEls, setUnsavedEls, setVariations]
  );

  const onVariationEditOpen = useCallback(
    (ind: number) => () => {
      setVariationInd(ind);
      setEditVariationModal(true);
    },
    []
  );

  const onVariationEditSave = useCallback(
    (newName: string) => {
      setVariations((oldVars) => {
        const newVars = oldVars;
        newVars[variationInd].name = newName;
        return newVars;
      });
    },
    [variations, variationInd]
  );

  const onVariationDelete = useCallback(
    (indToDel: number, variationName: string, variationId?: number) => () => {
      setProductModal({
        open: true,
        title: 'Удаление',
        text: `Вы уверены, что хотите удалить вариацию ${variationName}`,
        button1Text: 'Удалить',
        button1Type: 'error',
        button2Text: 'Отменить',
        button2Type: undefined,
        onClose: () => {
          setProductModal({ ...productModal, open: false });
        },
        on1ButtonClick: () => {
          setFieldsChanged(true);

          if (variationId) {
            const response = productsStore.deleteVariations(variationId);

            response.then((val) => {
              notifyUser(val.msg, val.isOk ? 'success' : 'error');
              if (val.isOk) {
                setProductModal({ ...productModal, open: false });

                setVariations((oldData) => {
                  return [
                    ...oldData.slice(0, indToDel),
                    ...oldData.slice(indToDel + 1)
                  ];
                });
              }
            });
          } else {
            setProductModal({ ...productModal, open: false });

            setVariations((oldData) => {
              return [
                ...oldData.slice(0, indToDel),
                ...oldData.slice(indToDel + 1)
              ];
            });
          }
        },
        on2ButtonClick: () => {
          setProductModal({ ...productModal, open: false });
        }
      });
    },
    [setVariations]
  );

  const onVariationCopy = useCallback(
    (variation: IProductVariation) => () => {
      setFieldsChanged(true);
      setVariations((oldArr) => {
        return [...oldArr, { ...variation, id: undefined }];
      });
    },
    []
  );

  const onProductSaveClick = () => {
    setUnsavedEls(new Set<number>());
    const response = productsStore.editProduct(product.id, {
      ...product,
      name,
      priceInvalid,
      price,
      categories,
      tags,
      variations,
      seo_title: seoTitle,
      seo_description: seoDescription,
      slug: seoSlug,
      text_content: markup
    });

    response.then((val) => {
      notifyUser(val.msg, val.isOk ? 'success' : 'error');

      const reloadResp = productsStore.getProduct(product.id);

      reloadResp.then((newVal) => {
        if (!newVal.isOk) {
          notifyUser(val.msg, 'error');
        } else {
          setFieldsChanged(false);
        }
      });
    });
  };

  const onEditorChange = useCallback(
    (val: string) => {
      setFieldsChanged(true);
      setMarkup(val);
    },
    [setFieldsChanged, setMarkup]
  );

  const onPriceInvalidChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldsChanged(true);
      setPriceInvalid(event.target.checked);
    },
    [setFieldsChanged, setPriceInvalid]
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'auto'
      }}
    >
      <Notification
        open={isNotificationOpen}
        message={notificationMsg}
        onClose={() => {
          setIsNotificationOpen(false);
          setNotificationMsg('');
        }}
        severity={notificationStatus}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Tabs
                value={tabPanel}
                onChange={handleChange}
                aria-label="Product Tabs"
              >
                <Tab
                  label="Редактирование контейнера"
                  id="tab-0"
                  aria-controls="tabpanel-0"
                />
                <Tab
                  label="Группирование"
                  id="tab-1"
                  aria-controls="tabpanel-1"
                />
                <Tab label="Вариации" id="tab-2" aria-controls="tabpanel-2" />
                {/* <Tab
                  label="SEO Шаблоны"
                  id="tab-3"
                  aria-controls="tabpanel-3"
                /> */}
                {/* <Tab label="SEO" id="tab-4" aria-controls="tabpanel-4" /> */}
              </Tabs>
              {fieldsChanged && (
                <Button
                  size="small"
                  variant="outlined"
                  color="success"
                  sx={{ alignSelf: 'center', mr: 2 }}
                  onClick={onProductSaveClick}
                >
                  Сохранить изменения
                </Button>
              )}
            </Box>
            <TabPanel value={tabPanel} index={0}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 3
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ width: '25%' }}
                  align="right"
                >
                  Название:
                </Typography>
                <TextField
                  value={name}
                  onChange={onNameChange}
                  InputProps={{
                    sx: { fontSize: '16px', marginBottom: '4px' }
                  }}
                  sx={{ width: '55%' }}
                  margin="dense"
                  size="small"
                />
              </Box>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 3
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ width: '25%' }}
                  align="right"
                >
                  Цена по умолчанию:
                </Typography>
                <TextField
                  value={price}
                  onChange={onPriceChange}
                  InputProps={{
                    sx: { fontSize: '16px' }
                  }}
                  type="tel"
                  margin="dense"
                  size="small"
                  sx={{ marginBottom: '4px', width: '55%' }}
                />
              </Box>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 3
                }}
              >
                <Box
                  sx={{
                    width: '25%',
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Checkbox
                    {...label}
                    checked={priceInvalid}
                    onChange={onPriceInvalidChange}
                  />
                </Box>
                <Typography
                  variant="subtitle1"
                  sx={{ marginBottom: '4px', width: '55%' }}
                  align="left"
                >
                  Цена не актуальна
                </Typography>
              </Box>

              {/* <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 3
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ width: '25%' }}
                  align="right"
                >
                  Цена со скидкой:
                </Typography>
                <TextField
                  value={discountPrice}
                  onChange={onPriceChange}
                  InputProps={{
                    sx: { fontSize: '16px', marginBottom: '4px' }
                  }}
                  type="tel"
                  margin="dense"
                  size="small"
                  sx={{ width: '55%' }}
                />
              </Box> */}
              <Box
                sx={{
                  width: '100%',
                  paddingTop: '60px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Stack
                  display="flex"
                  flexDirection="row"
                  gap="10px"
                  alignItems="center"
                  justifyContent="flex-start"
                  width="85%"
                >
                  <Typography sx={{ pr: 5 }}>Описание товара:</Typography>
                  <Switch
                    checked={switchChecked}
                    onChange={() => setSwitchChecked((val) => !val)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Typography>
                    {!switchChecked ? 'Режим кода' : 'Визуальный режим'}
                  </Typography>
                </Stack>
              </Box>

              {switchChecked ? (
                <Editor
                  id="id"
                  value={!markup ? undefined : markup}
                  notifyUser={notifyUser}
                  onEditorChange={onEditorChange}
                />
              ) : (
                <TextField
                  fullWidth
                  multiline
                  minRows={10}
                  value={markup || ''}
                  onChange={(val) => {
                    setMarkup(val.target.value);
                    setFieldsChanged(true);
                  }}
                />
              )}
            </TabPanel>
            <TabPanel value={tabPanel} index={1}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ width: '25%' }}
                  align="right"
                >
                  Категории:
                </Typography>
                <Box
                  sx={{
                    marginLeft: 3,
                    marginRight: 1,
                    border: 1,
                    borderRadius: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'start',
                    alignItems: 'center',
                    gap: '6px',
                    padding: '6px',
                    minWidth: '247px',
                    width: '55%',
                    minHeight: '50px'
                  }}
                >
                  {categories.map((category, ind) => (
                    <Chip
                      key={category.id}
                      label={category.name}
                      onClick={onChipClick(category.id, 'categories')}
                      color="default"
                      onDelete={onCategoryDelete(ind)}
                    />
                  ))}
                </Box>
                <IconButton onClick={handleCategoriesModalOpen}>
                  <AddIcon />
                </IconButton>
              </Box>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ width: '25%' }}
                  align="right"
                >
                  Теги:
                </Typography>
                <Box
                  sx={{
                    marginLeft: 3,
                    marginRight: 1,
                    border: 1,
                    borderRadius: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'start',
                    alignItems: 'center',
                    gap: '6px',
                    padding: '6px',
                    minWidth: '247px',
                    width: '55%',
                    minHeight: '50px'
                  }}
                >
                  {tags.map((tag, ind) => (
                    <Chip
                      key={tag.id}
                      label={tag.name}
                      color="default"
                      onClick={onChipClick(tag.id, 'tags')}
                      onDelete={onTagDelete(ind)}
                    />
                  ))}
                </Box>
                <IconButton onClick={handleTagsModalOpen}>
                  <AddIcon />
                </IconButton>
              </Box>
            </TabPanel>
            <TabPanel value={tabPanel} index={2}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  width: '100%',
                  gap: '10px',
                  padding: '20px',
                  justifyContent: 'center'
                }}
              >
                <Box sx={{ justifySelf: 'center', alignSelf: 'center' }}>
                  <IconButton onClick={() => setAddVariationModal(true)}>
                    <AddIcon />
                  </IconButton>
                </Box>
                {variations.map((variation, ind) => (
                  <ProductCard
                    key={`${variation.id}_${variation.name}`}
                    product={variation}
                    isBase={variation.is_base}
                    onDelete={onVariationDelete(
                      ind,
                      variation.name,
                      variation.id
                    )}
                    isDraft={
                      variation.id === undefined || unsavedEls.has(variation.id)
                    }
                    onCopy={onVariationCopy(variation)}
                    onEdit={onVariationEditOpen(ind)}
                  />
                ))}
              </Box>
            </TabPanel>
            {/* <TabPanel value={tabPanel} index={3}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  userSelect: 'none',
                  gap: 3
                }}
              >
                <ProductMedia
                  id={parseInt(id || '0', 10)}
                  notifyUser={notifyUser}
                />
              </Box>
            </TabPanel> */}
            {/* <TabPanel value={tabPanel} index={3}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 3
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ width: '25%' }}
                  align="right"
                >
                  Шаблон title:
                </Typography>
                <TextField
                  value={seoTitle || ''}
                  onChange={onSeoTitleChange}
                  InputProps={{
                    sx: { fontSize: '16px', marginBottom: '4px' }
                  }}
                  multiline
                  sx={{ width: '55%' }}
                  margin="dense"
                  size="small"
                />
              </Box>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 3
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ width: '25%' }}
                  align="right"
                >
                  Шаблон meta Description:
                </Typography>
                <TextField
                  value={seoDescription || ''}
                  onChange={onSeoDescriptionChange}
                  InputProps={{
                    sx: { fontSize: '16px', marginBottom: '4px' }
                  }}
                  multiline
                  sx={{ width: '55%' }}
                  margin="dense"
                  size="small"
                />
              </Box>

              <Box
                sx={{
                  width: 'calc(80% + 20px)',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end'
                }}
              >
                <Typography align="right" color="text.disabled">
                  Свойства товара, которые могут быть использованы в шаблоне:
                </Typography>
                <Typography align="right" color="text.disabled">
                  {'{id} - id'}
                </Typography>
                <Typography align="right" color="text.disabled">
                  {'{price} - цена'}
                </Typography>
                <Typography align="right" color="text.disabled">
                  {'{discount_price} - перечёркнутая цена'}
                </Typography>
                <Typography align="right" color="text.disabled">
                  {'{name} - название'}
                </Typography>
                <Typography align="right" color="text.disabled">
                  {'{sku} - артикул'}
                </Typography>
                {characteristicTypes.map((elem) => (
                  <Typography
                    align="right"
                    color="text.disabled"
                    key={`{${elem.name}} - ${elem.name}`}
                  >
                    {`{${elem.name}} - ${elem.name}`}
                  </Typography>
                ))}
              </Box>
            </TabPanel> */}
          </Box>
          <AddCategoriesFromList
            isOpen={categoriesModal}
            setIsOpen={setCategoriesModal}
            list={categories}
            setList={onCategoriesChange}
            notifyUser={notifyUser}
          />
          <AddTagsFromList
            isOpen={tagsModal}
            setIsOpen={setTagsModal}
            list={tags}
            setList={onTagsChange}
            notifyUser={notifyUser}
          />
          <AddVariation
            isOpen={addVariationModal}
            setIsOpen={setAddVariationModal}
            onVariationAdd={onVariationAdd}
          />
          <DialogModal {...productModal} />
          <EditVariationModal
            isOpen={editVariationModal}
            setIsOpen={setEditVariationModal}
            variations={variations}
            variationInd={variationInd}
            notifyUser={notifyUser}
            onSave={onVariationEditSave}
          />
        </>
      )}
    </Box>
  );
};

export default observer(Product);

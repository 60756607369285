import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  SyntheticEvent,
  ChangeEvent
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Autocomplete,
  Checkbox,
  IconButton,
  Tab,
  Tabs,
  Button,
  Stack,
  Switch
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SmoothList from 'react-smooth-list';

import Loader from 'components/loader';
import Notification from 'components/notification';
import ProductCard from 'components/productCard';
import DialogModal from 'components/dialogModal';
import AddProductFromList from 'components/modals/addProductFromList';
import Editor from 'components/editor/editor';

import { observer } from 'mobx-react-lite';

import categoriesStore from 'store/categoriesStore';
import globalState from 'store/globalState';
import characteristicStore from 'store/characteristicsStore';
import tagsStore from 'store/tagsStore';

import { ITag, ICharacteristicTypeShort, IAddProduct } from 'types/types';

import { isPaginated } from 'utilities/functions';

import mediaStore from 'store/mediaStore';
import CategoryMedia from 'components/categoryMedia';
import styles from './category.module.scss';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface IProductModal {
  open: boolean;
  title: string;
  text: string;
  button1Text: string;
  button1Type?:
    | 'error'
    | 'info'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning';
  button2Text: string;
  button2Type?:
    | 'error'
    | 'info'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning';
  onClose(): void;
  on1ButtonClick(): void;
  on2ButtonClick(): void;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexDirection: 'column',
            pt: 3
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const Category: FC = () => {
  const { id } = useParams();
  const { category, loading } = categoriesStore;
  const navigate = useNavigate();

  const [seoDescriptionTemplate, setSeoDescriptionTemplate] = useState<
    string | null
  >(category.seo_description_template || '');
  const [seoTitleTemplate, setSeoTitleTemplate] = useState<string | null>(
    category.seo_title_template || ''
  );

  const [tabPanel, setTabPanel] = useState<number>(0);
  const { characteristicTypes, characteristicsLoading } = characteristicStore;
  const { tags } = tagsStore;
  const [switchChecked, setSwitchChecked] = useState<boolean>(
    !category.text_content?.includes('<!-- widget -->')
  );
  const [switchTemplateChecked, setSwitchTemplateChecked] = useState<boolean>(
    !category.description_template?.includes('<!-- widget -->')
  );
  const [addProductModalOpen, setAddProductModalOpen] =
    useState<boolean>(false);

  const [applicableCharacteristicTypes, setApplicableCharacteristicTypes] =
    useState<Array<ICharacteristicTypeShort>>(
      category.applicable_characteristic_types
    );
  const [
    applicableCharacteristicTypesForList,
    setApplicableCharacteristicTypesForList
  ] = useState<Array<ICharacteristicTypeShort>>(
    category.applicable_characteristic_types_for_list
  );
  const [
    applicableCharacteristicTypesForFilter,
    setApplicableCharacteristicTypesForFilter
  ] = useState<Array<ICharacteristicTypeShort>>(
    category.applicable_characteristic_types_for_filter
  );
  const [applicableTags, setApplicableTags] = useState<Array<Partial<ITag>>>(
    category.applicable_tags
  );
  const [
    applicableCharacteristicTypesList,
    setApplicableCharacteristicTypesList
  ] = useState<Array<ICharacteristicTypeShort>>(characteristicTypes);
  const [applicableTagsList, setApplicableTagsList] = useState<Array<ITag>>(
    isPaginated<ITag>(tags) ? tags.data : tags
  );

  const [productModal, setProductModal] = useState<IProductModal>({
    open: false,
    title: '',
    text: '',
    button1Text: '',
    button1Type: undefined,
    button2Text: '',
    button2Type: undefined,
    onClose: () => {},
    on1ButtonClick: () => {},
    on2ButtonClick: () => {}
  });

  const [fieldsChanged, setFieldsChanged] = useState<boolean>(false);
  const [containersChanged, setContainersChanged] = useState<boolean>(false);

  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const [notificationStatus, setNotificationStatus] = useState<
    'error' | 'success' | 'info' | 'warning'
  >('error');
  const [notificationMsg, setNotificationMsg] = useState<string>('');

  const [name, setName] = useState<string>(category.name);
  const [alternativeName, setAlternativeName] = useState<string | null>(
    category.alternative_name
  );
  const [sort, setSort] = useState<number>(category.sort);
  const [parentCategory, setParentCategory] = useState<null | number>(
    category.parent_id
  );
  // const [children, setChildren] = useState<Array<ICategory> | null>(null);
  const [containers, setContainers] = useState<Array<IAddProduct>>(
    category.containers
  );

  const [seoTitle, setSeoTitle] = useState<string>(category.seo_title || '');
  const [seoDescription, setSeoDescription] = useState<string>(
    category.seo_description || ''
  );
  const [seoSlug, setSeoSlug] = useState<string>(category.slug || '');

  const [markup, setMarkup] = useState<string | null>(category.text_content);
  const [descriptionMarkup, setDescriptionMarkup] = useState<string | null>(
    category.description_template
  );

  const notifyUser = useCallback(
    (message: string, status: 'error' | 'success' | 'info' | 'warning') => {
      setNotificationMsg(message);
      setNotificationStatus(status);
      setIsNotificationOpen(true);
    },
    [setNotificationMsg, setNotificationStatus, setIsNotificationOpen]
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabPanel(newValue);
  };

  useEffect(() => {
    globalState.setNewPathname('Страница Категории');

    if (id) {
      const response = categoriesStore.getCategory(parseInt(id, 10));

      response.then((val) => {
        if (!val.isOk) {
          notifyUser(val.msg, 'error');
        }
      });

      globalState.setRefreshFunction(
        categoriesStore.getCategory,
        parseInt(id, 10)
      );

      const typesResponse = characteristicStore.getCharacteristicTypes();
      typesResponse.then((val) => {
        if (!val.isOk) {
          notifyUser(val.msg, 'error');
        }
      });

      const tagsResponse = tagsStore.getAllTags();
      tagsResponse.then((val) => {
        if (!val.isOk) {
          notifyUser(val.msg, 'error');
        }
      });
    } else {
      globalState.setRefreshFunction(categoriesStore.getCategory, 0);
    }
  }, [id]);

  useEffect(() => {
    setName(category.name);
    setAlternativeName(category.alternative_name);
    setSeoTitleTemplate(category.seo_title_template);
    setSeoDescriptionTemplate(category.seo_description_template);
    setDescriptionMarkup(category.description_template);
    setSort(category.sort);
    setParentCategory(category.parent_id);
    setApplicableCharacteristicTypes(category.applicable_characteristic_types);
    setApplicableCharacteristicTypesForList(
      category.applicable_characteristic_types_for_list
    );
    setApplicableCharacteristicTypesForFilter(
      category.applicable_characteristic_types_for_filter
    );
    setApplicableTags(category.applicable_tags);
    // setChildren(category.children);
    setSwitchChecked(!category.text_content?.includes('<!-- widget -->'));
    setSeoTitle(category.seo_title || '');
    setSeoDescription(category.seo_description || '');
    setSeoSlug(category.slug || '');
    setMarkup(category.text_content);
    setContainers(category.containers);
  }, [category]);

  useEffect(() => {
    setApplicableCharacteristicTypesList(characteristicTypes);
  }, [characteristicTypes]);

  useEffect(() => {
    setApplicableTagsList(isPaginated<ITag>(tags) ? tags.data : tags);
  }, [tags]);

  useEffect(() => {
    if (id && tabPanel === 2) {
      const mediaResp = mediaStore.getMedia('categories', category.id);

      mediaResp.then((val) => {
        if (!val.isOk) {
          notifyUser(val.msg, 'error');
        }
      });
    }
  }, [tabPanel, category.id]);

  const onEditorChange = useCallback(
    (val: string) => {
      setFieldsChanged(true);
      setMarkup(val);
    },
    [setFieldsChanged, setMarkup]
  );

  const onEditorTemplateChange = useCallback(
    (val: string) => {
      setFieldsChanged(true);
      setDescriptionMarkup(val);
    },
    [setFieldsChanged, setDescriptionMarkup]
  );

  const onContainerRemove = useCallback(
    (ind: number) => {
      setFieldsChanged(true);
      setContainersChanged(true);
      setContainers((prev) => [...prev.filter((i) => i.id !== ind)]);
    },
    [setContainers]
  );

  const onProudctModalClose = useCallback(() => {
    setProductModal((oldValue) => ({
      ...oldValue,
      open: false
    }));
  }, [setProductModal]);

  const onProductSaveClick = useCallback(() => {
    const response = categoriesStore.updateCategory(
      parseInt(id || '-1', 10),
      containersChanged
        ? {
            ...category,
            name,
            alternative_name: alternativeName,
            seo_title_template: seoTitleTemplate,
            seo_description_template: seoDescriptionTemplate,
            description_template: descriptionMarkup,
            sort,
            parent_id: parentCategory,
            applicable_characteristic_types: applicableCharacteristicTypes,
            applicable_characteristic_types_for_list:
              applicableCharacteristicTypesForList,
            applicable_characteristic_types_for_filter:
              applicableCharacteristicTypesForFilter,
            applicable_tags: applicableTags || [],
            containers,
            text_content: markup,
            seo_title: seoTitle,
            seo_description: seoDescription,
            slug: seoSlug
          }
        : {
            ...category,
            name,
            alternative_name: alternativeName,
            seo_title_template: seoTitleTemplate,
            seo_description_template: seoDescriptionTemplate,
            description_template: descriptionMarkup,
            sort,
            parent_id: parentCategory,
            applicable_characteristic_types: applicableCharacteristicTypes,
            applicable_characteristic_types_for_list:
              applicableCharacteristicTypesForList,
            applicable_characteristic_types_for_filter:
              applicableCharacteristicTypesForFilter,
            applicable_tags: applicableTags || [],
            text_content: markup,
            containers: undefined,
            seo_title: seoTitle,
            seo_description: seoDescription,
            slug: seoSlug
          }
    );

    response.then((val) => {
      if (!val.isOk) {
        notifyUser(val.msg, 'error');
      } else {
        notifyUser('Изменения сохранения', 'success');
        setFieldsChanged(false);
        setContainersChanged(false);

        const reloadResponse = categoriesStore.getCategory(
          parseInt(id || '-1', 10)
        );

        reloadResponse.then((newVal) => {
          if (!newVal.isOk) {
            notifyUser(val.msg, 'error');
          }
        });
      }
    });
  }, [
    categoriesStore,
    containersChanged,
    name,
    seoTitleTemplate,
    seoDescriptionTemplate,
    descriptionMarkup,
    alternativeName,
    sort,
    parentCategory,
    applicableCharacteristicTypes,
    applicableCharacteristicTypesForList,
    applicableCharacteristicTypesForFilter,
    applicableTags,
    markup,
    seoTitle,
    seoDescription,
    seoSlug,
    containers
  ]);

  const onCharacteristicTypesChange = useCallback(
    (
      _event: SyntheticEvent<Element, Event>,
      newValue: Array<string | ICharacteristicTypeShort>
    ) => {
      setFieldsChanged(true);
      setApplicableCharacteristicTypes([]);
      newValue.forEach((value) => {
        if (typeof value === 'string') {
          setApplicableCharacteristicTypes((oldArr) => {
            return [...oldArr, { name: value }];
          });
        } else {
          setApplicableCharacteristicTypes((oldArr) => {
            return [...oldArr, value];
          });
        }
      });
    },
    [setApplicableCharacteristicTypes]
  );

  const onCharacteristicTypesChangeForList = useCallback(
    (
      _event: SyntheticEvent<Element, Event>,
      newValue: Array<string | ICharacteristicTypeShort>
    ) => {
      setFieldsChanged(true);
      setApplicableCharacteristicTypesForList([]);
      newValue.forEach((value) => {
        if (typeof value === 'string') {
          setApplicableCharacteristicTypesForList((oldArr) => {
            return [...oldArr, { name: value }];
          });
        } else {
          setApplicableCharacteristicTypesForList((oldArr) => {
            return [...oldArr, value];
          });
        }
      });
    },
    [setApplicableCharacteristicTypesForList]
  );

  const onCharacteristicTypesChangeForFilter = useCallback(
    (
      _event: SyntheticEvent<Element, Event>,
      newValue: Array<string | ICharacteristicTypeShort>
    ) => {
      setFieldsChanged(true);
      setApplicableCharacteristicTypesForFilter([]);
      newValue.forEach((value) => {
        if (typeof value === 'string') {
          setApplicableCharacteristicTypesForFilter((oldArr) => {
            return [...oldArr, { name: value }];
          });
        } else {
          setApplicableCharacteristicTypesForFilter((oldArr) => {
            return [...oldArr, value];
          });
        }
      });
    },
    [setApplicableCharacteristicTypesForFilter]
  );

  const onTagsChange = useCallback(
    (
      _event: SyntheticEvent<Element, Event>,
      newValue: (string | Partial<ITag>)[]
    ) => {
      setFieldsChanged(true);
      setApplicableTags([]);
      newValue.forEach((value) => {
        if (typeof value === 'string') {
          setApplicableTags((oldArr) => {
            return [...oldArr, { name: value }];
          });
        } else {
          setApplicableTags((oldArr) => {
            return [...oldArr, value];
          });
        }
      });
    },
    [setApplicableTags]
  );

  const onSeoTitleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFieldsChanged(true);
      setSeoTitle(e.target.value);
    },
    [setSeoTitle]
  );

  const onSeoDescriptionChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFieldsChanged(true);
      setSeoDescription(e.target.value);
    },
    [setSeoDescription]
  );

  const onSeoSlugChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFieldsChanged(true);
      setSeoSlug(e.target.value);
    },
    [setSeoSlug]
  );

  const onProductModalSave = useCallback(
    (newContainers: IAddProduct[]) => {
      setContainers(newContainers);
      setFieldsChanged(true);
      setContainersChanged(true);
    },
    [setContainers, setFieldsChanged]
  );

  const onProductCardClick = useCallback(
    (container: IAddProduct) => () => {
      navigate(`/products/${container.id}`);
    },
    [navigate]
  );

  const onSeoTitleTemplateChange = useCallback(
    (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFieldsChanged(true);
      setSeoTitleTemplate(value.currentTarget.value);
    },
    [setSeoTitleTemplate]
  );

  const onSeoDescriptionTemplateChange = useCallback(
    (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFieldsChanged(true);
      setSeoDescriptionTemplate(value.currentTarget.value);
    },
    [setSeoDescriptionTemplate]
  );

  const onProductCardDeleteClick = useCallback(
    (container: IAddProduct) => () => {
      setProductModal((old) => ({
        ...old,
        open: true,
        title: `Удаление ${container.name}`,
        text: 'Это удаление из списка. Вы всегда сможете вернуть этот товар, нажав на +',
        button1Text: 'Удалить',
        button1Type: 'error',
        button2Text: 'Отменить',
        button2Type: undefined,
        onClose: onProudctModalClose,
        on1ButtonClick: () => {
          onContainerRemove(container.id || -1);
          setProductModal((oldValue) => ({
            ...oldValue,
            open: false
          }));
        },
        on2ButtonClick: onProudctModalClose
      }));
    },
    [onContainerRemove, setProductModal, onProudctModalClose]
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'auto'
      }}
    >
      <Notification
        open={isNotificationOpen}
        message={notificationMsg}
        onClose={() => {
          setIsNotificationOpen(false);
          setNotificationMsg('');
        }}
        severity={notificationStatus}
      />
      {/* <AddProductModal
        isOpen={addProductModalOpen}
        setIsOpen={() => setAddProductModalOpen(false)}
        onProductAdd={onProductAdd}
      /> */}
      <AddProductFromList
        open={addProductModalOpen}
        productsIncluded={containers}
        notifyUser={notifyUser}
        onClose={() => setAddProductModalOpen(false)}
        onSave={onProductModalSave}
      />
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Tabs
              value={tabPanel}
              onChange={handleChange}
              aria-label="Product Tabs"
            >
              <Tab
                label="Редактирование"
                id="tab-0"
                aria-controls="tabpanel-0"
              />
              <Tab label="Контейнеры" id="tab-1" aria-controls="tabpanel-1" />
              <Tab label="СЕО" id="tab-2" aria-controls="tabpanel-2" />
              <Tab
                label="Шаблоны для товаров"
                id="tab-3"
                aria-controls="tabpanel-3"
              />
              <Tab label="Изображение" id="tab-4" aria-controls="tabpanel-3" />
            </Tabs>
            {fieldsChanged && (
              <Button
                size="small"
                variant="outlined"
                color="success"
                sx={{ alignSelf: 'center', mr: 2 }}
                onClick={onProductSaveClick}
              >
                Сохранить изменения
              </Button>
            )}
          </Box>
          <TabPanel value={tabPanel} index={0}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Название:
              </Typography>
              <TextField
                value={name}
                onChange={(e) => {
                  setFieldsChanged(true);
                  setName(e.target.value);
                }}
                InputProps={{
                  sx: { fontSize: '16px' }
                }}
                margin="dense"
                size="small"
                sx={{ marginBottom: '4px', width: '55%' }}
              />
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Альтернативное название:
              </Typography>
              <TextField
                value={alternativeName || ''}
                onChange={(e) => {
                  setFieldsChanged(true);
                  setAlternativeName(e.target.value);
                }}
                InputProps={{
                  sx: { fontSize: '16px' }
                }}
                margin="dense"
                size="small"
                sx={{ marginBottom: '4px', width: '55%' }}
              />
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Sort:
              </Typography>
              <TextField
                value={sort}
                onChange={(e) => {
                  setFieldsChanged(true);
                  setSort(
                    parseInt(e.target.value || (sort || 0).toString(), 10)
                  );
                }}
                InputProps={{
                  sx: { fontSize: '16px' }
                }}
                margin="dense"
                size="small"
                sx={{ marginBottom: '4px', width: '55%' }}
              />
            </Box>
            {parentCategory && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 3
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ width: '25%' }}
                  align="right"
                >
                  Находится в категории:
                </Typography>
                <TextField
                  value={parentCategory}
                  InputProps={{
                    readOnly: true,
                    sx: { fontSize: '16px' }
                  }}
                  type="number"
                  margin="dense"
                  size="small"
                  sx={{ marginBottom: '4px', width: '55%' }}
                />
              </Box>
            )}
            {/* {children && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ width: '50%' }}
                  align="right"
                >
                  Дочерние категории:
                </Typography>
                <Box
                  sx={{
                    marginLeft: 3,
                    marginRight: 1,
                    border: 1,
                    borderRadius: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'start',
                    alignItems: 'center',
                    gap: '6px',
                    padding: '6px',
                    minWidth: '247px',
                    maxWidth: '300px',
                    minHeight: '50px'
                  }}
                >
                  {children.map((child) => (
                    <Chip
                      key={child.id}
                      label={child.name}
                      color="default"
                      onDelete={() => {}}
                    />
                  ))}
                </Box>
                <IconButton onClick={handleModalOpen}>
                  <AddIcon />
                </IconButton>
              </Box>
            )} */}
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Отображаемые характеристики:
              </Typography>
              <Autocomplete
                multiple
                id="checkboxes-tags1"
                loading={characteristicsLoading}
                options={applicableCharacteristicTypesList}
                value={applicableCharacteristicTypes}
                onChange={onCharacteristicTypesChange}
                disableCloseOnSelect
                size="small"
                autoComplete
                freeSolo
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  return option.name;
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                sx={{ marginBottom: '4px', width: '55%' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    multiline
                    label="Характеристики на странице товара"
                    margin="dense"
                    size="small"
                    sx={{ marginBottom: '4px', width: '100%' }}
                  />
                )}
              />
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                ml: 'calc(25% + 24px)',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Autocomplete
                multiple
                id="checkboxes-tags1"
                loading={characteristicsLoading}
                options={applicableCharacteristicTypesList}
                value={applicableCharacteristicTypesForList}
                onChange={onCharacteristicTypesChangeForList}
                disableCloseOnSelect
                size="small"
                autoComplete
                freeSolo
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  return option.name;
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                sx={{ marginBottom: '4px', width: '55%' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    multiline
                    label="Характеристики на странице категории"
                    margin="dense"
                    size="small"
                    sx={{ marginBottom: '4px', width: '100%' }}
                  />
                )}
              />
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                ml: 'calc(25% + 24px)',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Autocomplete
                multiple
                id="checkboxes-tags1"
                loading={characteristicsLoading}
                options={applicableCharacteristicTypesList}
                value={applicableCharacteristicTypesForFilter}
                onChange={onCharacteristicTypesChangeForFilter}
                disableCloseOnSelect
                size="small"
                autoComplete
                freeSolo
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  return option.name;
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                sx={{ marginBottom: '4px', width: '55%' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    multiline
                    label="Характеристики в фильтре"
                    margin="dense"
                    size="small"
                    sx={{ marginBottom: '4px', width: '100%' }}
                  />
                )}
              />
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Отображаемые теги:
              </Typography>
              <Autocomplete
                multiple
                id="checkboxes-tags2"
                loading={tagsStore.loading}
                options={applicableTagsList}
                value={applicableTags}
                onChange={onTagsChange}
                disableCloseOnSelect
                size="small"
                autoComplete
                freeSolo
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  return option.name || '';
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                sx={{ marginBottom: '4px', width: '55%' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    multiline
                    label="Теги"
                    margin="dense"
                    size="small"
                    sx={{ marginBottom: '4px', width: '100%' }}
                  />
                )}
              />
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Stack
                display="flex"
                flexDirection="row"
                gap="10px"
                alignItems="center"
                sx={{ paddingTop: '50px', width: '80%' }}
              >
                <Switch
                  checked={switchChecked}
                  onChange={() => setSwitchChecked((val) => !val)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography>
                  {!switchChecked ? 'Режим кода' : 'Визуальный режим'}
                </Typography>
              </Stack>
            </Box>

            {switchChecked ? (
              <Editor
                id="id_123"
                value={!markup ? undefined : markup}
                notifyUser={notifyUser}
                onEditorChange={onEditorChange}
              />
            ) : (
              <TextField
                fullWidth
                multiline
                minRows={10}
                value={markup || ''}
                onChange={(val) => {
                  setMarkup(val.target.value);
                  setFieldsChanged(true);
                }}
              />
            )}
          </TabPanel>
          <TabPanel value={tabPanel} index={1}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <IconButton onClick={() => setAddProductModalOpen(true)}>
                <AddIcon />
              </IconButton>
              <SmoothList className={styles.smoothList}>
                {containers.map((container) => (
                  <ProductCard
                    key={container.id}
                    product={container}
                    onClick={onProductCardClick(container)}
                    onDelete={onProductCardDeleteClick(container)}
                  />
                ))}
              </SmoothList>
            </Box>
          </TabPanel>
          <TabPanel value={tabPanel} index={2}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Мета-тег Заголовок:
              </Typography>
              <TextField
                value={seoTitle || ''}
                onChange={onSeoTitleChange}
                InputProps={{
                  sx: { fontSize: '16px', marginBottom: '4px' }
                }}
                multiline
                sx={{ width: '55%' }}
                margin="dense"
                size="small"
              />
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Мета-тег Описание:
              </Typography>
              <TextField
                value={seoDescription || ''}
                onChange={onSeoDescriptionChange}
                InputProps={{
                  sx: { fontSize: '16px', marginBottom: '4px' }
                }}
                multiline
                sx={{ width: '55%' }}
                margin="dense"
                size="small"
              />
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Название в ссылке:
              </Typography>
              <TextField
                value={seoSlug || ''}
                onChange={onSeoSlugChange}
                InputProps={{
                  sx: { fontSize: '16px', marginBottom: '4px' }
                }}
                multiline
                sx={{ width: '55%' }}
                margin="dense"
                size="small"
              />
            </Box>
          </TabPanel>
          <TabPanel value={tabPanel} index={3}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Шаблон title:
              </Typography>
              <TextField
                value={seoTitleTemplate || ''}
                onChange={onSeoTitleTemplateChange}
                InputProps={{
                  sx: { fontSize: '16px', marginBottom: '4px' }
                }}
                multiline
                sx={{ width: '55%' }}
                margin="dense"
                size="small"
              />
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Шаблон meta Description:
              </Typography>
              <TextField
                value={seoDescriptionTemplate || ''}
                onChange={onSeoDescriptionTemplateChange}
                InputProps={{
                  sx: { fontSize: '16px', marginBottom: '4px' }
                }}
                multiline
                sx={{ width: '55%' }}
                margin="dense"
                size="small"
              />
            </Box>

            <Box
              sx={{
                width: 'calc(80% + 20px)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'flex-end'
              }}
            >
              <Typography align="right" color="text.disabled">
                Свойства товара, которые могут быть использованы в шаблоне:
              </Typography>
              <Typography align="right" color="text.disabled">
                {'{id} - id'}
              </Typography>
              <Typography align="right" color="text.disabled">
                {'{price} - цена'}
              </Typography>
              <Typography align="right" color="text.disabled">
                {'{discount_price} - перечёркнутая цена'}
              </Typography>
              <Typography align="right" color="text.disabled">
                {'{name} - название'}
              </Typography>
              <Typography align="right" color="text.disabled">
                {'{sku} - артикул'}
              </Typography>
              {characteristicTypes.map((elem) => (
                <Typography
                  align="right"
                  color="text.disabled"
                  key={`{${elem.name}} - ${elem.name}`}
                >
                  {`{${elem.name}} - ${elem.name}`}
                </Typography>
              ))}

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Stack
                  display="flex"
                  flexDirection="row"
                  gap="10px"
                  alignItems="center"
                  sx={{ paddingTop: '50px', width: '80%' }}
                >
                  <Switch
                    checked={switchTemplateChecked}
                    onChange={() => setSwitchTemplateChecked((val) => !val)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Typography>
                    {!switchTemplateChecked ? 'Режим кода' : 'Визуальный режим'}
                  </Typography>
                </Stack>
              </Box>

              {switchTemplateChecked ? (
                <Editor
                  id="id_234"
                  value={!descriptionMarkup ? undefined : descriptionMarkup}
                  notifyUser={notifyUser}
                  onEditorChange={onEditorTemplateChange}
                />
              ) : (
                <TextField
                  fullWidth
                  multiline
                  minRows={10}
                  value={descriptionMarkup || ''}
                  onChange={(val) => {
                    setDescriptionMarkup(val.target.value);
                    setFieldsChanged(true);
                  }}
                />
              )}
            </Box>
          </TabPanel>
          <TabPanel value={tabPanel} index={4}>
            <CategoryMedia id={category.id} notifyUser={notifyUser} />
          </TabPanel>
          <DialogModal {...productModal} />
        </Box>
      )}
    </Box>
  );
};

export default observer(Category);

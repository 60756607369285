import React, { FC, SyntheticEvent } from 'react';

import { Alert, Snackbar } from '@mui/material';

interface IProps {
  severity: 'error' | 'warning' | 'success' | 'info';
  open: boolean;
  onClose(): void;
  message: string;
}

const notification: FC<IProps> = ({ severity, open, onClose, message }) => {
  const handleClose = (_event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={severity === 'success' ? 2500 : null}
      onClose={handleClose}
    >
      <Alert severity={severity} onClose={handleClose} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default notification;

import axios from 'api/helpers/axios';
import { IInfoPage, IInfoPageAdd } from 'types/types';
import { toSlug } from 'utilities/functions';

interface IResponse<T> {
  data: T;
  status?: number;
  statusText?: string;
  message?: string;
  name?: string;
}

export default {
  async getInfoPages(): Promise<IResponse<Array<IInfoPage>>> {
    const response = await axios.get('/info_pages');

    return response;
  },

  async getInfoPage(id: number): Promise<IResponse<IInfoPage>> {
    const response = await axios.get(`/info_pages/${id}`);

    return response;
  },

  async deleteInfoPage(id: number): Promise<IResponse<unknown>> {
    const response = await axios.delete(`/info_pages/${id}`);

    return response;
  },

  async addInfoPage(data: any): Promise<IResponse<unknown>> {
    const response = await axios.post(`/info_pages`, {
      ...data,
      slug: data.slug || toSlug(data)
    });

    return response;
  },

  async editInfoPage(
    id: number,
    data: IInfoPageAdd
  ): Promise<IResponse<unknown>> {
    const response = await axios.patch(`/info_pages/${id}`, {
      ...data,
      slug: data.slug || toSlug(data)
    });

    return response;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { observable, action, makeObservable, runInAction } from 'mobx';

// API
import api from 'api';
import axios from 'axios';

import { IAddProduct, IMedia } from 'types/types';

interface IError {
  isOk: boolean;
  msg: string;
}

interface IFile {
  name: string;
  url: string;
  size: number;
  created_at: string;
}

interface IUploadData {
  isOk: boolean;
  msg: string;
  data: Array<IMedia>;
}

interface IFilesError {
  isOk: boolean;
  msg: string;
  data: Array<IFile>;
}

interface IFileError {
  isOk: boolean;
  msg: string;
  data: Blob;
}

class MediaStore {
  @observable
  main: IMedia = {
    path: '',
    id: 0,
    sort: null
  };

  @observable
  gallery: Array<IMedia> = [];

  @observable
  galleryToAssign: Array<IMedia> = [];

  @observable
  mainLoading: boolean = true;

  @observable
  galleryLoading: boolean = true;

  @observable
  loading: boolean = true;

  @observable
  feedLoading: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action
  assignGallery = async (
    resource:
      | 'categories'
      | 'products'
      | 'variations'
      | 'tags'
      | 'info_sections'
      | 'info_pages',
    resourceId: number // id товара, тега или категории,
  ): Promise<IError> => {
    this.galleryLoading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const mediaFromAPI = await api.media.assignMedia(
        resource,
        resourceId,
        'gallery',
        this.galleryToAssign.map((el) => el.id)
      );

      runInAction(() => {
        if (mediaFromAPI.status === 200) {
          res.isOk = true;
          res.msg = 'Успешно';

          this.galleryToAssign = [];
        } else {
          console.error(
            `Error\nCode: ${mediaFromAPI.status}\nStatus: ${mediaFromAPI.statusText}`
          );

          res.isOk = false;
          res.msg = mediaFromAPI.name
            ? `Ошибка ${mediaFromAPI.name}: ${mediaFromAPI.message}`
            : `Ошибка ${mediaFromAPI.status}: ${mediaFromAPI.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      this.galleryLoading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.galleryLoading = false;
      });
    }

    return res;
  };

  @action
  uploadCover = async (data: FormData): Promise<IError> => {
    this.mainLoading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const mediaFromAPI = await api.media.postMedia(data);

      runInAction(async () => {
        if (mediaFromAPI.status === 200) {
          if (!mediaFromAPI.data[0]) {
            throw new Error(
              'Что-то пошло не так. Не получена информация о загруженных файлах'
            );
          }

          this.main.path = mediaFromAPI.data[0].path;
          this.main.id = mediaFromAPI.data[0].id;

          res.isOk = true;
          res.msg = 'Обложка загружена';
        } else {
          console.error(
            `Error\nCode: ${mediaFromAPI.status}\nStatus: ${mediaFromAPI.statusText}`
          );

          res.isOk = false;
          res.msg = mediaFromAPI.name
            ? `Ошибка ${mediaFromAPI.name}: ${mediaFromAPI.message}`
            : `Ошибка ${mediaFromAPI.status}: ${mediaFromAPI.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      } else {
        res.msg = JSON.stringify(error);
      }

      this.mainLoading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.mainLoading = false;
      });
    }

    return res;
  };

  @action
  uploadToGallery = async (data: FormData): Promise<IError> => {
    this.galleryLoading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const mediaFromAPI = await api.media.postMedia(data);

      runInAction(async () => {
        if (mediaFromAPI.status === 200) {
          if (!mediaFromAPI.data[0]) {
            throw new Error(
              'Что-то пошло не так. Не получена информация о загруженных файлах'
            );
          }

          this.galleryToAssign = [];
          mediaFromAPI.data.forEach((el) => {
            this.galleryToAssign.push({
              id: el.id,
              path: el.path
            });
            this.gallery.push({
              id: el.id,
              path: el.path
            });
          });

          res.isOk = true;
          res.msg = 'Изображения загружены';
        } else {
          console.error(
            `Error\nCode: ${mediaFromAPI.status}\nStatus: ${mediaFromAPI.statusText}`
          );

          res.isOk = false;
          res.msg = mediaFromAPI.name
            ? `Ошибка ${mediaFromAPI.name}: ${mediaFromAPI.message}`
            : `Ошибка ${mediaFromAPI.status}: ${mediaFromAPI.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.statusText ||
          error.response?.data.message ||
          error.name
        }`;
      } else {
        res.msg = JSON.stringify(error);
      }

      this.galleryLoading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.galleryLoading = false;
      });
    }

    return res;
  };

  @action
  uploadToMedia = async (data: FormData): Promise<IUploadData> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: '',
      data: [] as Array<IMedia>
    };

    try {
      const mediaFromAPI = await api.media.postMedia(data);

      runInAction(async () => {
        if (mediaFromAPI.status === 200) {
          if (!mediaFromAPI.data[0]) {
            throw new Error(
              'Что-то пошло не так. Не получена информация о загруженных файлах'
            );
          }

          res.isOk = true;
          res.msg = 'Изображения загружены';
          res.data = mediaFromAPI.data;
        } else {
          console.error(
            `Error\nCode: ${mediaFromAPI.status}\nStatus: ${mediaFromAPI.statusText}`
          );

          res.isOk = false;
          res.msg = mediaFromAPI.name
            ? `Ошибка ${mediaFromAPI.name}: ${mediaFromAPI.message}`
            : `Ошибка ${mediaFromAPI.status}: ${mediaFromAPI.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.statusText ||
          error.response?.data.message ||
          error.name
        }`;
      } else {
        res.msg = JSON.stringify(error);
      }
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  changeSort = async (id: number, sort: number): Promise<IError> => {
    this.galleryLoading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const mediaFromAPI = await api.media.sortMedia(id, sort);

      runInAction(() => {
        if (mediaFromAPI.status === 200) {
          res.isOk = true;
          res.msg = 'Порядок элемента изменён';
        } else {
          console.error(
            `Error\nCode: ${mediaFromAPI.status}\nStatus: ${mediaFromAPI.statusText}`
          );

          res.isOk = false;
          res.msg = mediaFromAPI.name
            ? `Ошибка ${mediaFromAPI.name}: ${mediaFromAPI.message}`
            : `Ошибка ${mediaFromAPI.status}: ${mediaFromAPI.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      this.galleryLoading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.galleryLoading = false;
      });
    }

    return res;
  };

  @action
  getMedia = async (
    resource:
      | 'categories'
      | 'products'
      | 'variations'
      | 'tags'
      | 'info_sections'
      | 'info_pages',
    resourceId: number // id товара, тега или категории
  ): Promise<IError> => {
    this.mainLoading = true;
    this.galleryLoading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const mediaFromAPI = await api.media.getMedia(resource, resourceId);

      runInAction(() => {
        if (mediaFromAPI.status === 200) {
          res.isOk = true;
          res.msg = 'Обложка загружена';

          if (mediaFromAPI.data.main) {
            this.main = { ...mediaFromAPI.data.main[0] };
          } else {
            this.main = {
              path: '',
              id: 0,
              sort: null
            };
          }
          if (mediaFromAPI.data.gallery) {
            this.gallery = [];
            mediaFromAPI.data.gallery.forEach((el) => {
              this.gallery.push({
                id: el.id,
                path: el.path,
                sort: el.sort
              });
            });
          } else {
            this.gallery = [];
          }
        } else {
          console.error(
            `Error\nCode: ${mediaFromAPI.status}\nStatus: ${mediaFromAPI.statusText}`
          );

          res.isOk = false;
          res.msg = mediaFromAPI.name
            ? `Ошибка ${mediaFromAPI.name}: ${mediaFromAPI.message}`
            : `Ошибка ${mediaFromAPI.status}: ${mediaFromAPI.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      this.mainLoading = false;
      this.galleryLoading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.mainLoading = false;
        this.galleryLoading = false;
      });
    }

    return res;
  };

  @action
  deleteImage = async (id: number, isFromMain: boolean) => {
    if (isFromMain) {
      this.mainLoading = true;
    } else {
      this.galleryLoading = true;
    }

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const response = await api.media.deleteMedia(id);

      runInAction(() => {
        if (response.status === 200) {
          res.isOk = true;
          res.msg = 'Изображение удалено';

          if (isFromMain) {
            this.main = {
              id: 0,
              path: ''
            };
          } else {
            this.gallery = this.gallery.filter((el) => el.id !== id);
          }
        } else {
          console.error(
            `Error\nCode: ${response.status}\nStatus: ${response.statusText}`
          );

          res.isOk = false;
          res.msg = response.name
            ? `Ошибка ${response.name}: ${response.message}`
            : `Ошибка ${response.status}: ${response.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      if (isFromMain) {
        this.mainLoading = false;
      } else {
        this.galleryLoading = false;
      }

      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        if (isFromMain) {
          this.mainLoading = false;
        } else {
          this.galleryLoading = false;
        }
      });
    }

    return res;
  };

  @action
  replaceCover = async (
    resource:
      | 'categories'
      | 'products'
      | 'variations'
      | 'tags'
      | 'info_sections'
      | 'info_pages',
    resourceId: number, // id товара, тега или категории
    slot: 'main',
    newId: number
  ): Promise<IError> => {
    this.mainLoading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const mediaFromAPI = await api.media.replaceMedia(
        resource,
        resourceId,
        slot,
        newId
      );

      runInAction(() => {
        if (mediaFromAPI.status === 200) {
          res.isOk = true;
          res.msg = 'Медиа загружены';
        } else {
          console.error(
            `Error\nCode: ${mediaFromAPI.status}\nStatus: ${mediaFromAPI.statusText}`
          );

          res.isOk = false;
          res.msg = mediaFromAPI.name
            ? `Ошибка ${mediaFromAPI.name}: ${mediaFromAPI.message}`
            : `Ошибка ${mediaFromAPI.status}: ${mediaFromAPI.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.data.message || error.name
        }`;
      }

      this.mainLoading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.mainLoading = false;
      });
    }

    return res;
  };

  @action
  generatePinterestFeed = async (data: IAddProduct[]): Promise<IError> => {
    this.feedLoading = true;

    const res = {
      isOk: false,
      msg: '',
      data: ''
    };

    try {
      const downloadResults = await api.downloads.generatePinterestFeed(data);

      runInAction(async () => {
        if (downloadResults.status === 200) {
          res.isOk = true;
          res.msg = 'Фид Pinterest сгенерирован';
          res.data = downloadResults.data;
        } else {
          console.error(
            `Error\nCode: ${downloadResults.status}\nStatus: ${downloadResults.statusText}`
          );

          res.isOk = false;
          res.msg = downloadResults.name
            ? `Ошибка ${downloadResults.name}: ${downloadResults.message}`
            : `Ошибка ${downloadResults.status}: ${downloadResults.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.statusText ||
          error.response?.data.message ||
          error.name
        }`;
      } else {
        res.msg = JSON.stringify(error);
      }
      console.error(error);
    } finally {
      runInAction(() => {
        this.feedLoading = false;
      });
    }

    return res;
  };

  @action
  getAllFeeds = async (): Promise<IFilesError> => {
    this.feedLoading = true;

    const res: IFilesError = {
      isOk: false,
      msg: '',
      data: []
    };

    try {
      const downloadResults = await api.downloads.getFeedsList();

      runInAction(async () => {
        if (downloadResults.status === 200) {
          res.isOk = true;
          res.msg = 'Все фиды получены';
          res.data = downloadResults.data;
        } else {
          console.error(
            `Error\nCode: ${downloadResults.status}\nStatus: ${downloadResults.statusText}`
          );

          res.isOk = false;
          res.msg = downloadResults.name
            ? `Ошибка ${downloadResults.name}: ${downloadResults.message}`
            : `Ошибка ${downloadResults.status}: ${downloadResults.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.statusText ||
          error.response?.data.message ||
          error.name
        }`;
      } else {
        res.msg = JSON.stringify(error);
      }
      console.error(error);
    } finally {
      runInAction(() => {
        this.feedLoading = false;
      });
    }

    return res;
  };

  @action
  downloadFile = async (name: string): Promise<IFileError> => {
    this.feedLoading = true;

    const res: IFileError = {
      isOk: false,
      msg: '',
      data: new Blob()
    };

    try {
      const downloadResults = await api.downloads.downloadFile(name);

      runInAction(async () => {
        if (downloadResults.status === 200) {
          res.isOk = true;
          res.msg = 'Файл скачан';
          res.data = downloadResults.data;
        } else {
          console.error(
            `Error\nCode: ${downloadResults.status}\nStatus: ${downloadResults.statusText}`
          );

          res.isOk = false;
          res.msg = downloadResults.name
            ? `Ошибка ${downloadResults.name}: ${downloadResults.message}`
            : `Ошибка ${downloadResults.status}: ${downloadResults.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.statusText ||
          error.response?.data.message ||
          error.name
        }`;
      } else {
        res.msg = JSON.stringify(error);
      }
      console.error(error);
    } finally {
      runInAction(() => {
        this.feedLoading = false;
      });
    }

    return res;
  };

  @action
  deleteFile = async (name: string): Promise<IError> => {
    this.feedLoading = true;

    const res: IError = {
      isOk: false,
      msg: ''
    };

    try {
      const downloadResults = await api.downloads.deleteFile(name);

      runInAction(async () => {
        if (downloadResults.status === 200) {
          res.isOk = true;
          res.msg = 'Файл удалён';
        } else {
          console.error(
            `Error\nCode: ${downloadResults.status}\nStatus: ${downloadResults.statusText}`
          );

          res.isOk = false;
          res.msg = downloadResults.name
            ? `Ошибка ${downloadResults.name}: ${downloadResults.message}`
            : `Ошибка ${downloadResults.status}: ${downloadResults.statusText}`;
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${
          error.response?.statusText ||
          error.response?.data.message ||
          error.name
        }`;
      } else {
        res.msg = JSON.stringify(error);
      }
      console.error(error);
    } finally {
      runInAction(() => {
        this.feedLoading = false;
      });
    }

    return res;
  };
}

const mediaStore = new MediaStore();
export default mediaStore;

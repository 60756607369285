import React, { FC, useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemButton,
  ListItemIcon,
  Modal,
  Button,
  Box,
  Stack,
  Checkbox,
  Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';

import tagsStore from 'store/tagsStore';
import { ITag } from 'types/types';
import { isPaginated } from 'utilities/functions';

interface IProps {
  list: Array<ITag>;
  setList(newList: Array<ITag>): void;
  isOpen: boolean;
  setIsOpen(val: boolean): void;
  notifyUser(message: string, status: 'error' | 'success'): void;
}

const AddTagsFromList: FC<IProps> = ({
  list,
  setList,
  isOpen,
  setIsOpen,
  notifyUser
}) => {
  const { tags, loading } = tagsStore;
  const [checked, setChecked] = useState<Array<number>>([]);
  const outputList = isPaginated<ITag>(tags) ? tags.data : tags;

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSave = () => {
    const newList = [] as Array<ITag>;
    checked.forEach((id) => {
      const foundEl = outputList.find((tag) => tag.id === id);
      if (foundEl) newList.push(foundEl);
    });
    setList(newList);
    setIsOpen(false);
  };

  useEffect(() => {
    const response = tagsStore.getTags({
      page: 1,
      paginate: undefined,
      perpage: 10000
    });

    response.then((val) => {
      if (!val.isOk) {
        notifyUser(val.msg, 'error');
      } else {
        const checkedIndexes = list.map((element) => element.id);
        setChecked(checkedIndexes);
      }
    });
  }, [list]);

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      sx={{ overflowY: 'scroll' }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          color: 'text.primary',
          p: 1
        }}
      >
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" sx={{ pl: 2 }}>
            Список тегов товара
          </Typography>
          <IconButton onClick={() => setIsOpen(false)}>
            <Close
              sx={(theme) => ({
                '&:hover': {
                  color: theme.palette.error.main
                }
              })}
            />
          </IconButton>
        </Stack>

        {!loading && (
          <List
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              height: '90vh',
              overflow: 'scroll'
            }}
          >
            {outputList.map((listElement) => {
              const labelId = `checkbox-list-label-${listElement.id}`;

              return (
                <ListItem key={listElement.id} disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={handleToggle(listElement.id)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(listElement.id) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={listElement.name} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        )}

        <Stack display="flex" flexDirection="row" justifyContent="flex-end">
          <Button size="small" variant="text" onClick={handleSave}>
            Применить
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddTagsFromList;

import { slugify } from 'transliteration';
import { IPaginationResponse, ListType } from 'types/types';

const rusToEng: { [key: string]: string } = {
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  е: 'e',
  ё: 'e',
  ж: 'zh',
  з: 'z',
  и: 'i',
  й: 'y',
  к: 'k',
  л: 'l',
  м: 'm',
  н: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  у: 'u',
  ф: 'f',
  х: 'h',
  ц: 'c',
  ч: 'ch',
  ш: 'sh',
  щ: 'sch',
  ъ: '',
  ы: 'y',
  ь: '',
  э: 'e',
  ю: 'yu',
  я: 'ya',

  А: 'A',
  Б: 'B',
  В: 'V',
  Г: 'G',
  Д: 'D',
  Е: 'E',
  Ё: 'E',
  Ж: 'Zh',
  З: 'Z',
  И: 'I',
  Й: 'Y',
  К: 'K',
  Л: 'L',
  М: 'M',
  Н: 'N',
  О: 'O',
  П: 'P',
  Р: 'R',
  С: 'S',
  Т: 'T',
  У: 'U',
  Ф: 'F',
  Х: 'H',
  Ц: 'C',
  Ч: 'Ch',
  Ш: 'Sh',
  Щ: 'Sch',
  Ъ: '',
  Ы: 'Y',
  Ь: '',
  Э: 'E',
  Ю: 'Yu',
  Я: 'Ya'
};

export const isPaginated = <T>(
  list: IPaginationResponse<T> | ListType<T>
): list is IPaginationResponse<T> => {
  return (list as IPaginationResponse<T>).data !== undefined;
};

export function truncateString(str: string): string {
  if (str.length <= 20) {
    // Если длина строки меньше или равна 20 символам, то возвращаем исходную строку без изменений
    return str;
  }
  // Если длина строки больше 20 символов, то обрезаем строку и добавляем "..." в конце
  return `${str.slice(0, 20)}...`;
}

export function toSlug(data: any): string {
  return slugify(data.name || data.title || data.value || data.id);
}

import React, { FC } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';

interface IProps {
  open: boolean;
  title: string;
  text: string;
  button1Text: string;
  button1Type?:
    | 'error'
    | 'info'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning';
  button2Text: string;
  button2Type?:
    | 'error'
    | 'info'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning';
  onClose(): void;
  on1ButtonClick(): void;
  on2ButtonClick(): void;
}

const DialogModal: FC<IProps> = ({
  open,
  title,
  text,
  button1Text,
  button1Type,
  button2Text,
  button2Type,
  onClose,
  on1ButtonClick,
  on2ButtonClick
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={on1ButtonClick} color={button1Type || 'primary'}>
          {button1Text}
        </Button>
        <Button
          onClick={on2ButtonClick}
          autoFocus
          color={button2Type || 'primary'}
        >
          {button2Text}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogModal;

import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  ChangeEvent
} from 'react';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Tabs,
  Tab,
  Button,
  Stack,
  Switch,
  FormControl,
  InputLabel,
  MenuItem
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SmoothList from 'react-smooth-list';

import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Editor from 'components/editor/editor';
import Loader from 'components/loader';
import Notification from 'components/notification';
import ProductCard from 'components/productCard';
import DialogModal from 'components/dialogModal';
import AddProductFromList from 'components/modals/addProductFromList';

import tagsStore from 'store/tagsStore';
import globalState from 'store/globalState';

import { IAddProduct, ICategory } from 'types/types';

import styles from './tag.module.scss';

interface IProductModal {
  open: boolean;
  title: string;
  text: string;
  button1Text: string;
  button1Type?:
    | 'error'
    | 'info'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning';
  button2Text: string;
  button2Type?:
    | 'error'
    | 'info'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning';
  onClose(): void;
  on1ButtonClick(): void;
  on2ButtonClick(): void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexDirection: 'column',
            pt: 3
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const Tag: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { tag, loading } = tagsStore;
  const [switchChecked, setSwitchChecked] = useState<boolean>(
    !tag.text_content?.includes('<!-- widget -->')
  );
  const [categories, setCategories] = useState<Array<ICategory>>(
    tag.categories || []
  );
  const [mainCategory, setMainCategory] = useState<number | null | undefined>(
    tag.main_category
  );
  const [tabPanel, setTabPanel] = useState<number>(0);
  const [fieldsChanged, setFieldsChanged] = useState<boolean>(false);
  const [productModal, setProductModal] = useState<IProductModal>({
    open: false,
    title: '',
    text: '',
    button1Text: '',
    button1Type: undefined,
    button2Text: '',
    button2Type: undefined,
    onClose: () => {},
    on1ButtonClick: () => {},
    on2ButtonClick: () => {}
  });

  const [addProductModalOpen, setAddProductModalOpen] =
    useState<boolean>(false);

  const [markup, setMarkup] = useState<string>(tag.text_content || '');

  const [seoTitle, setSeoTitle] = useState<string>(tag.seo_title || '');
  const [seoDescription, setSeoDescription] = useState<string>(
    tag.seo_description || ''
  );
  const [seoSlug, setSeoSlug] = useState<string>(tag.slug || '');

  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const [notificationMsg, setNotificationMsg] = useState<string>('');
  const [notificationStatus, setNotificationStatus] = useState<
    'error' | 'success' | 'info' | 'warning'
  >('error');

  const [name, setName] = useState<string>(tag.name);
  const [containers, setContainers] = useState<Array<IAddProduct>>(
    tag.containers
  );

  const notifyUser = useCallback(
    (message: string, status: 'error' | 'success' | 'info' | 'warning') => {
      setNotificationMsg(message);
      setNotificationStatus(status);
      setIsNotificationOpen(true);
    },
    [setNotificationMsg, setNotificationStatus, setIsNotificationOpen]
  );

  useEffect(() => {
    globalState.setNewPathname('Страница тега');

    if (id) {
      const response = tagsStore.getTag(parseInt(id, 10));

      response.then((val) => {
        if (!val.isOk) {
          notifyUser(val.msg, 'error');
        }
      });

      globalState.setRefreshFunction(tagsStore.getTag, parseInt(id, 10));
    } else {
      globalState.setRefreshFunction(tagsStore.getTag, 0);
    }
  }, [id]);

  useEffect(() => {
    setName(tag.name);
    setSwitchChecked(!tag.text_content?.includes('<!-- widget -->'));
    setMainCategory(tag.main_category);
    setCategories(tag.categories || []);
    setMarkup(tag.text_content || '');
    setContainers(tag.containers);
    setSeoTitle(tag.seo_title || '');
    setSeoDescription(tag.seo_description || '');
    setSeoSlug(tag.slug || '');
  }, [tag]);

  const onContainerRemove = useCallback(
    (ind: number) => {
      setContainers((prev) => [...prev.filter((i) => i.id !== ind)]);
    },
    [setContainers]
  );

  const onNameChange = useCallback(
    (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFieldsChanged(true);
      setName(value.currentTarget.value);
    },
    [setFieldsChanged, setName]
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabPanel(newValue);
  };

  const onEditorChange = useCallback(
    (val: string) => {
      setFieldsChanged(true);
      setMarkup(val);
    },
    [setMarkup, setFieldsChanged]
  );

  const onSeoTitleChange = useCallback(
    (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFieldsChanged(true);
      setSeoTitle(value.currentTarget.value);
    },
    [setFieldsChanged, setSeoTitle]
  );

  const onSeoDescriptionChange = useCallback(
    (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFieldsChanged(true);
      setSeoDescription(value.currentTarget.value);
    },
    [setFieldsChanged, setSeoDescription]
  );

  const onSeoSlugChange = useCallback(
    (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFieldsChanged(true);
      setSeoSlug(value.currentTarget.value);
    },
    [setFieldsChanged, setSeoSlug]
  );

  const onProudctModalClose = useCallback(() => {
    setProductModal((oldValue) => ({
      ...oldValue,
      open: false
    }));
  }, [setProductModal]);

  const onProductModalSave = useCallback(
    (newContainers: IAddProduct[]) => {
      setContainers(newContainers);
      setFieldsChanged(true);
    },
    [setContainers, setFieldsChanged]
  );

  const handleMainCategoryChange = useCallback(
    (event: SelectChangeEvent) => {
      setMainCategory(parseInt(event.target.value, 10));
      setFieldsChanged(true);
    },
    [setFieldsChanged]
  );

  const handleMainCategoryClear = useCallback(() => {
    setMainCategory(undefined);
    setFieldsChanged(true);
  }, [setFieldsChanged]);

  const onProductSaveClick = () => {
    const response = tagsStore.editTag(tag.id, {
      ...tag,
      name,
      main_category: mainCategory || null,
      containers,
      seo_title: seoTitle,
      seo_description: seoDescription,
      slug: seoSlug,
      text_content: markup
    });

    const resp2Result = {
      isOk: true,
      msg: ''
    };

    if (mainCategory) {
      const resp2 = tagsStore.changeMainCategory(tag.id, mainCategory);

      resp2.then((val) => {
        resp2Result.isOk = val.isOk;
        resp2Result.msg = val.msg;
      });
    }

    response.then((val) => {
      if (val.isOk && resp2Result.isOk) {
        const reloadResp = tagsStore.getTag(parseInt(id || '-1', 10));

        reloadResp.then((newVal) => {
          if (!newVal.isOk) {
            notifyUser(newVal.msg, 'error');
          } else {
            notifyUser('Изменения сохранены', 'success');
            setFieldsChanged(false);
          }
        });
      } else {
        notifyUser('Не удалось сохранить изменения', 'error');
      }
    });
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'auto'
      }}
    >
      <Notification
        open={isNotificationOpen}
        message={notificationMsg}
        onClose={() => {
          setIsNotificationOpen(false);
          setNotificationMsg('');
        }}
        severity={notificationStatus}
      />
      <AddProductFromList
        open={addProductModalOpen}
        productsIncluded={containers}
        notifyUser={notifyUser}
        onClose={() => setAddProductModalOpen(false)}
        onSave={onProductModalSave}
      />
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Tabs
              value={tabPanel}
              onChange={handleChange}
              aria-label="Product Tabs"
            >
              <Tab
                label="Редактирование"
                id="tab-0"
                aria-controls="tabpanel-0"
              />
              <Tab label="Контейнеры" id="tab-1" aria-controls="tabpanel-1" />
              <Tab label="СЕО контент" id="tab-1" aria-controls="tabpanel-2" />
            </Tabs>

            {fieldsChanged && (
              <Button
                size="small"
                variant="outlined"
                color="success"
                sx={{ alignSelf: 'center', mr: 2 }}
                onClick={onProductSaveClick}
              >
                Сохранить изменения
              </Button>
            )}
          </Box>

          <TabPanel value={tabPanel} index={0}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Название:
              </Typography>
              <TextField
                value={name}
                onChange={onNameChange}
                InputProps={{
                  sx: { fontSize: '16px', marginBottom: '4px' }
                }}
                sx={{ width: '55%' }}
                margin="dense"
                size="small"
              />
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Принадлежность:
              </Typography>
              <FormControl size="small" sx={{ width: '55%' }}>
                <InputLabel id="simple-select-category-label">
                  Основная категория
                </InputLabel>
                <Select
                  labelId="simple-select-category-label"
                  id="simple-select-helper"
                  value={mainCategory ? mainCategory.toString() : ''}
                  label="Основная категория"
                  onChange={handleMainCategoryChange}
                >
                  {categories.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                      {el.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {mainCategory ? (
                <IconButton size="small" onClick={handleMainCategoryClear}>
                  <ClearIcon />
                </IconButton>
              ) : null}
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Stack
                display="flex"
                flexDirection="row"
                gap="10px"
                alignItems="center"
                sx={{ paddingTop: '50px', width: '80%' }}
              >
                <Switch
                  checked={switchChecked}
                  onChange={() => setSwitchChecked((val) => !val)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography>
                  {!switchChecked ? 'Режим кода' : 'Визуальный режим'}
                </Typography>
              </Stack>
            </Box>

            {switchChecked ? (
              <Editor
                id="id"
                value={!markup ? undefined : markup}
                notifyUser={notifyUser}
                onEditorChange={onEditorChange}
              />
            ) : (
              <TextField
                fullWidth
                multiline
                minRows={10}
                value={markup || ''}
                onChange={(val) => {
                  setMarkup(val.target.value);
                  setFieldsChanged(true);
                }}
              />
            )}
          </TabPanel>
          <TabPanel value={tabPanel} index={1}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <IconButton onClick={() => setAddProductModalOpen(true)}>
                <AddIcon />
              </IconButton>
              <SmoothList className={styles.smoothList}>
                {containers.map((container) => (
                  <ProductCard
                    key={container.id}
                    product={container}
                    onClick={() => navigate(`/products/${container.id}`)}
                    onDelete={() =>
                      setProductModal((old) => ({
                        ...old,
                        open: true,
                        title: `Удаление ${container.name}`,
                        text: 'Это удаление из списка. Вы всегда сможете вернуть этот товар, нажав на +',
                        button1Text: 'Удалить',
                        button1Type: 'error',
                        button2Text: 'Отменить',
                        button2Type: undefined,
                        onClose: onProudctModalClose,
                        on1ButtonClick: () => {
                          onContainerRemove(container.id || -1);
                          setProductModal((oldValue) => ({
                            ...oldValue,
                            open: false
                          }));
                        },
                        on2ButtonClick: onProudctModalClose
                      }))
                    }
                  />
                ))}
              </SmoothList>
            </Box>
          </TabPanel>
          <TabPanel value={tabPanel} index={2}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Мета-тег Заголовок:
              </Typography>
              <TextField
                value={seoTitle || ''}
                onChange={onSeoTitleChange}
                InputProps={{
                  sx: { fontSize: '16px', marginBottom: '4px' }
                }}
                multiline
                sx={{ width: '55%' }}
                margin="dense"
                size="small"
              />
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Мета-тег Описание:
              </Typography>
              <TextField
                value={seoDescription || ''}
                onChange={onSeoDescriptionChange}
                InputProps={{
                  sx: { fontSize: '16px', marginBottom: '4px' }
                }}
                multiline
                sx={{ width: '55%' }}
                margin="dense"
                size="small"
              />
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ width: '25%' }}
                align="right"
              >
                Название в ссылке:
              </Typography>
              <TextField
                value={seoSlug || ''}
                onChange={onSeoSlugChange}
                InputProps={{
                  sx: { fontSize: '16px', marginBottom: '4px' }
                }}
                multiline
                sx={{ width: '55%' }}
                margin="dense"
                size="small"
              />
            </Box>
          </TabPanel>
          <DialogModal {...productModal} />
        </Box>
      )}
    </Box>
  );
};

export default observer(Tag);

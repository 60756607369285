import React, { FC, useEffect, useState, useCallback } from 'react';
import { Box, Tabs, Tab, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import Notification from 'components/notification';
import DownloadModal from 'components/modals/downloadsModal';
import AttributesModal from 'components/modals/attributesModal';
import globalState from 'store/globalState';

import { IAddProduct } from 'types/types';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexDirection: 'column',
            pt: 3
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const Downloads: FC = () => {
  const [containers, setContainers] = useState<Array<IAddProduct>>([]);
  const [tabPanel, setTabPanel] = useState<number>(0);
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const [notificationMsg, setNotificationMsg] = useState<string>('');
  const [notificationStatus, setNotificationStatus] = useState<
    'error' | 'success' | 'info' | 'warning'
  >('error');
  const [addProductModalOpen, setAddProductModalOpen] =
    useState<boolean>(false);

  const [attributesModalOpen, setAttributesModalOpen] =
    useState<boolean>(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabPanel(newValue);
  };

  const notifyUser = useCallback(
    (message: string, status: 'error' | 'success' | 'info' | 'warning') => {
      setNotificationMsg(message);
      setNotificationStatus(status);
      setIsNotificationOpen(true);
    },
    [setNotificationMsg, setNotificationStatus, setIsNotificationOpen]
  );

  const onProductModalSave = useCallback(
    (newContainers: IAddProduct[]) => {
      setContainers(newContainers);
    },
    [setContainers]
  );

  useEffect(() => {
    globalState.setNewPathname('Страница инструментов');

    //  if (id) {
    //    const response = DownloadsStore.getDownloads(parseInt(id, 10));

    //    response.then((val) => {
    //      if (!val.isOk) {
    //        notifyUser(val.msg, 'error');
    //      }
    //    });

    //    globalState.setRefreshFunction(DownloadsStore.getDownloads, parseInt(id, 10));
    //  } else {
    //    globalState.setRefreshFunction(DownloadsStore.getDownloads, 0);
    //  }
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'auto'
      }}
    >
      <Notification
        open={isNotificationOpen}
        message={notificationMsg}
        onClose={() => {
          setIsNotificationOpen(false);
          setNotificationMsg('');
        }}
        severity={notificationStatus}
      />
      <DownloadModal
        open={addProductModalOpen}
        productsIncluded={containers}
        notifyUser={notifyUser}
        onClose={() => setAddProductModalOpen(false)}
        onSave={onProductModalSave}
      />
      <AttributesModal
        modalOpen={attributesModalOpen}
        onModalClose={() => setAttributesModalOpen(false)}
      />
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Tabs
            value={tabPanel}
            onChange={handleTabChange}
            aria-label="Product Tabs"
          >
            <Tab label="Выгрузка" id="tab-0" aria-controls="tabpanel-0" />
            <Tab label="Атрибуты" id="tab-1" aria-controls="tabpanel-1" />
          </Tabs>
        </Box>

        <TabPanel value={tabPanel} index={0}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center'
            }}
          >
            <Button
              onClick={() => setAddProductModalOpen(true)}
              type="button"
              variant="contained"
              sx={{ ml: '30px' }}
            >
              Сгенерировать фид для Pinterest
            </Button>
          </Box>
        </TabPanel>

        <TabPanel value={tabPanel} index={1}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center'
            }}
          >
            <Button
              onClick={() => setAttributesModalOpen(true)}
              type="button"
              variant="contained"
              sx={{ ml: '30px' }}
            >
              Изменить атрибут на странице &quot;весь ковролин&quot;
            </Button>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default observer(Downloads);
